import User from '@/models/User'
import api from '@/api'
import Fuse from 'fuse.js'
import store from '@/store'
import router from '@/router'

const state = {
  contactIds: []
}

// const findEarliestLastFetch = (userIdList) => {
//   if (!userIdList) return null
//   const userList = User.query().whereIdIn(userIdList).get()
//   if (userList.length < userIdList.length) return null
//   const lastFetchList = userList.map(user => user.lastFetch)
//   if (lastFetchList.indexOf(null) > -1) return null
//   return lastFetchList.sort()[0]
// }

const getters = {
  list: () => User.all(),
  contactIds: state => state.contactIds,
  contacts: (state, getters, rootState, rootGetters) => {
    return state.contactIds
      .filter(id => id !== rootGetters['auth/user'].id)
      .map(id => User.find(id))
  },
  search: (state, getters) => query => {
    const fuse = new Fuse(getters.list, {
      keys: ['firstName', 'lastName', 'phone']
    })
    return fuse.search(query)
  },
  searchContacts: (state, getters) => query => {
    const fuse = new Fuse(getters.contacts, {
      keys: ['firstName', 'lastName', 'phone']
    })
    return fuse.search(query)
  }
}

const actions = {
  updateAuthUser ({ rootGetters, commit }, user) {
    const authUser = rootGetters['auth/user']
    const updatedUser = {
      ...authUser,
      ...user,
      id: user.userId
    }
    delete (updatedUser.userId)
    commit('auth/SET_USER', updatedUser, { root: true })
  },
  async getProfiles ({ rootGetters, commit, dispatch }, idList = []) {
    if (!idList.length) return
    let params = {}
    // const lastFetch = findEarliestLastFetch(idList)
    // if (lastFetch) {
    //   params.datetimeFrom = new Date(lastFetch).toISOString().slice(0, -1)
    // }
    const authUser = rootGetters['auth/user']
    // const now = Date.now()
    try {
      const { data } = await api.user.getProfiles(idList, params)
      const myUser = data.find(obj => obj.userId === authUser.id)
      User.$create({ data })
      // User.$create({
      //   data: data.map(user => {
      //     return {
      //       ...user,
      //       lastFetch: now
      //     }
      //   })
      // })
      if (myUser) {
        dispatch('updateAuthUser', myUser)
      }
    } catch (e) {
      const { status } = e.response
      if (status === 404) return
      return e
    }
  },
  async getMyProfile ({ dispatch }) {
    const { data } = await api.user.getMyProfile()
    User.$create({ data })
    // User.$create({ data: {
    //   ...data,
    //   lastFetch: Date.now()
    // } })
    dispatch('updateAuthUser', data)
  },
  async saveProfile ({ rootGetters, commit, dispatch }, params) {
    const { data } = await api.user.saveProfile(params)
    dispatch('updateAuthUser', data)
    User.$update({ data })
  },
  async fetchContacts ({ dispatch }) {
    const { data } = await api.user.getContacts()
    User.commit(state => {
      state.contactIds = data
    })
    await dispatch('getProfiles', data)
  },
  async fetchContactsUsers (_, { page, limit }) {
    const { data } = await api.user.getContactsProfile({ page, limit })
    User.$create({ data })
    return data
  },
  async fetchContactsUpdates ({ getters }) {
    const contactIds = getters['contactIds']
    if (!contactIds.length) return
    let params = {}
    // const lastFetch = findEarliestLastFetch(contactIds)
    // if (lastFetch) {
    //   params.datetimeFrom = new Date(lastFetch).toISOString().slice(0, -1)
    // }
    const { data } = await api.user.getContactsUpdates(params)
    User.$create({ data })
  },
  async addContact (_, { userId, phone }) {
    await api.user.addContacts([phone])
    User.commit(state => {
      state.contactIds.push(userId)
    })
  },
  async removeContact (_, { userId }) {
    await api.user.removeContacts([userId])
    User.commit(state => {
      const index = state.contactIds.indexOf(userId)
      state.contactIds.splice(index, 1)
    })
  },
  async deletePhone ({ dispatch }, data) {
    await api.user.removePhone(data)
    store.dispatch('auth/cleanUp')
    router.push({ name: 'sign-in' })
  },
  async checkUsername (_, nickname) {
    // eslint-disable-next-line no-useless-catch
    try {
      return await api.user.checkUsername(nickname)
    } catch (e) {
      throw e
    }
  }
}

export default {
  state,
  getters,
  actions
}
