<template>
  <ChatModal
    :name="$options.name"
    :title="modalTitle"
    @before-open="beforeOpen"
  >
    <div :class="$style.list">
      <div :class="$style.createBtn" @click="$modal.show('create-status')">
        <BaseIcon name="plus-circle" :class="$style.createIcon" />
        <div>{{ $t('userStatus.tabCreateStatus') }}</div>
      </div>
      <div
        v-for="status in statusList" :key="status.id"
        :class="[$style.item, { [$style.active]: isStatusActive(status.id) } ]"
        :title="status.statusText"
        @click="onSet(status.id)"
      >
        <div :class="$style.mediaWrap">
          <img
            v-if="status.mediaType === 'IMAGE'"
            :src="status.mediaLink !== 'DEFAULT' ? status.mediaLink : user.avatarLink"
            :class="$style.image"
          />
        </div>
        <div :class="$style.info">{{ status.title || status.statusText }}</div>
        <div v-if="isStatusActive(status.id)" :class="[$style.iconWrap, $style.check]">
           <BaseIcon name="check" />
        </div>
        <div @click.stop>
          <v-popover placement="bottom-end" popoverClass="no-arrow">
            <div :class="[$style.iconWrap, $style.action, 'tooltip-target']">
              <BaseIcon custom name="ellipsisY" />
            </div>
            <MenuBase
              slot="popover" v-close-popover
              :options="getStatusMenu(status.id)"
            />
          </v-popover>
        </div>
      </div>
    </div>
    <CreateStatus />
    <EditStatus />
  </ChatModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChatModal from '../ChatModal'
import CreateStatus from './CreateStatus'
import EditStatus from './EditStatus'
import MenuBase from '@/components/MenuBase'

export default {
  name: 'set-status',
  components: {
    ChatModal,
    CreateStatus,
    EditStatus,
    MenuBase
  },
  data () {
    return {
      isCurrentStatusExpired: true,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      statusList: 'entities/userStatuses/list',
      currentStatus: 'entities/userStatuses/myCurrentStatus'
    }),
    modalTitle () {
      return this.$t('userStatus.tabSetStatus')
    }
  },
  methods: {
    beforeOpen () {
      this.fetchStatuses()
      this.setIsExpired()
    },
    setIsExpired () {
      if (!this.currentStatus || !this.user) {
        this.isCurrentStatusExpired = true
        return
      }
      this.isCurrentStatusExpired = +new Date(this.user.statusExpire + 'Z') > Date.now()
    },
    ...mapActions({
      fetchStatuses: 'entities/userStatuses/fetchStatuses',
      setStatus: 'entities/userStatuses/setStatus',
      deleteStatus: 'entities/userStatuses/deleteStatus'
    }),
    isStatusActive (id) {
      return this.currentStatus && (id === this.currentStatus.id) && !this.isCurrentStatusExpired
    },
    getStatusMenu (id) {
      return [
        {
          name: this.$t('buttonEdit'),
          icon: 'pencil-alt',
          handler: () => {
            this.$modal.show('edit-status', { id })
          }
        },
        {
          name: this.$t('buttonDelete'),
          icon: 'trash-alt',
          handler: () => {
            this.$modal.show('confirm', {
              onSubmit: () => {
                this.deleteStatus(id)
              }
            })
          }
        }
      ]
    },
    async onSet (id) {
      await this.setStatus(id)
      this.setIsExpired()
      // this.$modal.hide(this.$options.name)
    }
  }
}
</script>

<style module lang="scss">
.list {
  height: 360px;
  overflow-y: scroll;
}
.createBtn {
  background-color: $main-bg-darkest;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  color: $text-lightest;
  cursor: pointer;
  .createIcon {
    font-size: 20px;
    margin-right: 12px;
  }
}
.item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
  border-bottom: 1px solid $border-color;
  &.active {
    background-color: rgba($main-bg-colored, 0.3);
  }
}
.mediaWrap {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  overflow: hidden;
  flex-shrink: 0;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.info {
  margin-left: 16px;
  color: $text-darkest;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
}
.iconWrap {
  font-size: 16px;
  width: 20px;
  height: 20px;
  color: $text-dark;
}
.check {
  color: $text-colored;
}
.action {
  cursor: pointer;
  margin-left: 12px;
}
</style>
