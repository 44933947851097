import PostCategory from '@/models/PostCategory'
import api from '@/api'

const getters = {
  list () {
    return PostCategory.all()
  }
}

const actions = {
  async fetch () {
    const { results } = await api.blogs.getPostCategories({ limit: 2000 })
    PostCategory.insert({ data: results })
  }
}

export default {
  getters,
  actions
}
