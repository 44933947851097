<template>
  <ChatModal :name="$options.name" :title="$t('connectTelegram')" @before-open="beforeOpen">
    <template v-if="step === 1">
      <template slot="footer">
        <BaseButton oval :loading="loading" @click="activate">{{ $t('buttonConnect') }}</BaseButton>
      </template>
    </template>
    <template v-if="step === 2">
      <div style="text-align: left">
        <h2 style="text-align: center; margin-top: 30px">
          {{ $t('confirmPhoneNumber') }}
        </h2>
        <div style="text-align: center; min-height: 100px; padding: 0 20px 10px; margin-top: 20px">
          <PhoneInput style="margin-bottom: 0" @on-update="phone = $event" @valid="isValidPhone = $event"> </PhoneInput>
          <p
            v-show="!!responseText && !responseText.includes($options.searchQueryForRemoveNoticeMessage)"
            style="font-size: 16px; text-align: center; margin-top: 10px; margin-bottom: 10px"
          >
            {{ responseText }}
          </p>
        </div>
      </div>
      <template slot="footer">
        <BaseButton oval :loading="loading" :disabled="!isValidPhone" style="margin-right: 10px" @click="confirmPhone"
          >{{ $t('buttonConfirm') }}
        </BaseButton>
        <BaseButton oval @click="closeDialog">{{ $t('buttonClose') }}</BaseButton>
      </template>
    </template>
    <template v-if="step === 3">
      <div style="text-align: left">
        <h2 style="text-align: center; margin-top: 30px">
          {{ $t('enterCodeFromTelegram') }}
        </h2>
        <div style="text-align: center; min-height: 100px; padding: 0 20px 10px">
          <BaseInput
            style="padding-bottom: 0"
            v-if="!successLogin"
            v-model="code"
            label=""
            :placeholder="$t('enterCode')"
            :invalid="$v.code.$error"
            :error="$t('errorEmptyField')"
          />
          <p
            v-show="
              !!responseText &&
              !responseText.includes($options.searchQueryForRemoveNoticeMessage) &&
              !responseText.includes($options.searchQueryForRemoveNoticeMessage2)
            "
            style="font-size: 16px; text-align: center; margin-top: 10px; margin-bottom: 10px"
          >
            {{ responseText }}
          </p>
        </div>
      </div>
      <template slot="footer">
        <BaseButton oval :loading="loading" style="margin-right: 10px" @click="confirmCode">
          {{ $t('buttonConfirm') }}
        </BaseButton>
        <BaseButton v-show="successLogin" oval @click="closeDialog">{{ $t('buttonClose') }}</BaseButton>
      </template>
    </template>
    <template v-if="step === 4">
      <div style="text-align: left">
        <h2 style="text-align: center; margin-top: 30px">
          {{ $t('enterPasswordFromTelegram') }}
        </h2>
        <div style="text-align: center; min-height: 100px; padding: 0 20px 10px">
          <BaseInput
            style="padding-bottom: 0"
            v-if="!successLogin"
            v-model="password"
            label=""
            :placeholder="$t('enterPassword')"
            :invalid="$v.password.$error"
            :error="$t('errorEmptyField')"
          />
          <p
            v-show="
              !!responseText &&
              !responseText.includes($options.searchQueryForRemoveNoticeMessage) &&
              !responseText.includes($options.searchQueryForRemoveNoticeMessage2)
            "
            style="font-size: 16px; text-align: center; margin-top: 10px; margin-bottom: 10px"
          >
            {{ responseText }}
          </p>
        </div>
      </div>
      <template slot="footer">
        <BaseButton v-show="!successLogin" :loading="loading" style="margin-right: 10px" @click="confirmPassword">
          {{ $t('confirm') }}
        </BaseButton>
        <BaseButton v-show="successLogin" oval @click="closeDialog">{{ $t('buttonClose') }}</BaseButton>
      </template>
    </template>
  </ChatModal>
</template>

<script>
import ChatModal from '@/components/modals/ChatModal'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { dataForCreateBridgeTGRoom } from '@/helpers'
import { msgTypes, eventTypes, chatNamesForBridge, selfMembershipTypes } from '@/helpers/variables'
import PhoneInput from '@/components/PhoneInput'

const searchQueryForRemoveNoticeMessage = '**N.B. Logging in grants the bridge full access to your Telegram account.**'
const searchQueryForRemoveNoticeMessage2 = "Hello, I'm Telegram bridge bot."
const enterTwoFactorAuthPassword = 'Your account has two-factor authentication. Please send your password here.'

export default {
  searchQueryForRemoveNoticeMessage,
  searchQueryForRemoveNoticeMessage2,
  name: 'bridge-telegram',
  components: {
    ChatModal,
    PhoneInput
  },
  data () {
    return {
      step: 1,
      loading: false,
      isValidPhone: false,
      roomId: null,
      responseText: '',
      successLogin: false,
      TGConnectingRoom: null,
      code: '',
      phone: '',
      password: ''
    }
  },
  validations: {
    code: {
      required
    },
    phone: {
      required
    },
    password: {
      required
    }
  },
  computed: {
    ...mapGetters({
      matrixSetting: 'matrix/matrixCredentials',
      user: 'auth/user'
    })
  },
  methods: {
    ...mapActions({
      updateMatrixCredentials: 'matrix/updateMatrixCredentials',
      getMatrixCredentials: 'matrix/getMatrixCredentials'
    }),
    beforeOpen () {
      this.step = 1
      this.loading = false
      this.roomId = null
      this.TGConnectingRoom = null
      this.responseText = ''
      this.phone = ''
      this.successLogin = false
    },
    async createRoom () {
      const result = await window.matrixSDK.createRoom(dataForCreateBridgeTGRoom)
      this.roomId = result.room_id
      return result
    },
    async sendMessage (msgbody) {
      const content = {
        body: msgbody,
        msgtype: msgTypes.text
      }
      await window.matrixSDK.sendEvent(this.roomId, eventTypes.message, content, '')
    },
    async activate () {
      try {
        this.loading = true
        const rooms = window.matrixSDK.getRooms()
        const TGRoom = rooms.find(
          e => e.name === chatNamesForBridge.telegram && e.selfMembership === selfMembershipTypes.join
        )
        if (TGRoom) {
          this.TGConnectingRoom = TGRoom
          this.roomId = TGRoom.roomId
        } else {
          await this.createRoom()
          this.TGConnectingRoom = window.matrixSDK.getRoom(this.roomId)
        }
        await this.sendMessage('login')
      } finally {
        this.loading = false
      }

      this.step = 2
      this.loading = false

      this.TGConnectingRoom.on('Room.timeline', async event => {
        // console.log('Room.timeline', event)
        this.successLogin = event.event.content.body.includes('logged in')
        const { userId } = window.matrixSDK.credentials
        if (event.event.sender !== userId) {
          this.responseText = event.event.content.body
        }

        if (this.responseText.includes(enterTwoFactorAuthPassword)) {
          this.step = 4
        }

        if (this.successLogin) {
          await this.updateMatrixCredentials({
            is_bridge_telegram: true
          })
          await this.getMatrixCredentials()
        }
      })
    },
    async confirmPhone () {
      this.$v.$touch()
      if (this.loading || this.$v.phone.$invalid) return
      try {
        this.loading = true
        await this.sendMessage(this.phone)
        this.step = 3
      } finally {
        this.loading = false
      }
    },
    // +50257595574
    async confirmCode () {
      this.$v.$touch()
      if (this.loading || this.$v.code.$invalid) return
      this.loading = true
      try {
        await this.sendMessage(this.code)
      } finally {
        this.loading = false
      }
    },
    async confirmPassword () {
      this.$v.$touch()
      if (this.loading || this.$v.password.$invalid) return
      this.loading = true
      try {
        await this.sendMessage(this.password)
      } finally {
        this.loading = false
      }
    },
    closeDialog () {
      this.$modal.hide(this.$options.name)
    }
  }
}
</script>
