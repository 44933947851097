<template>
  <FontAwesomeIcon v-if="!custom" v-bind="$attrs" v-on="$listeners" :icon="name" />
  <InlineSvg
    v-else-if="customIconComponent"
    :src="customIconComponent"
    v-on="$listeners"
    v-bind="$attrs"
    class="custom-icon"
  />
</template>

<script>
import '@/assets/icons/fa.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as customIcons from '@/assets/icons/custom'

export default {
  customIcons,
  close,
  components: {
    InlineSvg: () => import('vue-inline-svg'),
    FontAwesomeIcon
  },
  inheritAttrs: false,
  props: {
    name: {
      type: [String, Array]
      // required: true
    },
    custom: Boolean
  },
  computed: {
    customIconComponent () {
      const component = customIcons[this.name]
      if (!component && this.custom) {
        throw new Error(`Unknown custom icon: ${this.name}`)
      }
      return component
    }
  }
}
</script>
