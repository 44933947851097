<template>
  <label :class="['switch', { active: value }, { disabled: disabled }]">
    <input
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      class="switch-input"
      @change.stop="toggle"
    >
    <div class="switch-label">
      <slot />
    </div>
    <div class="switch-wrap">
      <div class="switch-button"></div>
      <div class="switch-track"></div>
    </div>
  </label>
</template>

<script>
export default {
  name: 'ui-switch',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle (event) {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style lang="scss">
.switch {
  display: flex;
  align-items: center;

  &-label {
    text-align: left;
    flex-grow: 1;
    padding-right: 8px;
    color: $text-mid;
  }
  &-input {
    opacity: 0;
    width: 1px;
    height: 1px;
  }
  &-wrap {
    position: relative;
    flex-shrink: 0;
    width: 32px;
    height: 18px;
    cursor: pointer;
  }
  &-track {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    position: absolute;
    top: 4px;
    background-color: rgba($main-bg-darkest, 0.2);
    transition: all 0.15s ease-out;
  }
  &-button {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    position: absolute;
    // top: -4px;
    background-color: #fff;
    z-index: 1;
    box-shadow: 0 1px 3px rgba(0,0,0,.4);
    transition: all 0.15s ease-out;
  }

  &.active {
    .switch-track {
      background-color: rgba($main-bg-colored, 0.2);
    }
    .switch-button {
      background-color: $main-bg-colored;
      transform: translateX(14px);
    }
    .switch-label {
      color: $text-darkest;
    }
  }

  &.disabled {
    .switch-label {
      color: $text-mid-dark;
    }
    .switch-wrap {
      cursor: default;
    }
    &.active {
      //
    }
  }
}
</style>
