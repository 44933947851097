<template>
  <modal
    v-bind="$attrs"
    v-on="$listeners"
    :class="$style.wrapper"
    name="info"
    width="450"
    height="auto"
    adaptive
    scrollable
    transition="modal-fade"
    @before-open="setProps"
    @before-close="reset"
  >
    <h1 :class="$style.title">{{ $t('infoTitle') }}</h1>
    <BaseIcon custom name="close" @click="$modal.hide('info')" :class="$style.close" />
    <div :class="$style.body">
      {{ text }}
    </div>
  </modal>
</template>

<script>
export default {
  name: 'info-modal',
  data () {
    return {
      text: ''
    }
  },
  methods: {
    setProps ({ params = {} }) {
      const { text } = params
      this.text = text
    },
    reset () {
      this.text = ''
    }
  }
}
</script>

<style lang="scss" module>
.wrapper :global(.v--modal) {
  background: $main-bg-light;
  box-shadow: 0 3px 11px 1px rgba(0, 0, 0, 0.15);
  // border: 1px solid $border-color;
  font-weight: 400;
  border-radius: 0;
  padding: 36px 32px;
}
.title {
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  text-align: center;
}
.close {
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 24px;
  right: 20px;
}
.body {
  padding: 40px;
  text-align: center;
  font-size: 16px;
}
</style>
