<template>
  <BaseModal
    :name="$options.name"
    icon="medal"
    :title="$t('popupPremium.title')"
  >
    <div :class="$style.content">
      {{ $t('popupPremium.text') }}
      <br>
      <router-link
        :to="{ name: 'packages-table' }"
        @click.native="$modal.hide($options.name)"
      >{{ $t('learnMore') }}</router-link>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'

export default {
  name: 'premium-feature',
  components: {
    BaseModal
  }
}
</script>

<style module lang="scss">
.content {
  font-size: 16px;
}
</style>
