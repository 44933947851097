import Blog from '@/models/Blog'
import Post from '@/models/Post'
import api from '@/api'
import { uniq } from 'lodash'

const getters = {
  getPost: _ => uuid => {
    return Post.query().whereId(uuid).with('category').with('blog').first()
  },
  postsByBlog: _ => blogUuid => {
    return Post.query()
      .where('blogUuid', blogUuid)
      .with(['category', 'blog'])
      .orderBy('createdAt', 'desc')
      .get()
  },
  bookmarks: () => Post.query()
    .where('isMarked', true)
    .with(['category', 'blog'])
    .orderBy('createdAt', 'desc')
    .get()
}

const actions = {
  async fetchBlogPosts ({ getters }, { blogUuid, limit, offset }) {
    const { results } = await api.blogs.getBlogPosts({ blogUuid, limit, offset })
    // const data = results.map(post => ({ ...post, blogId: blogUuid }))
    Post.insert({ data: results })
    return results
  },
  async fetchPost (_, { blogUuid, postUuid }) {
    const post = await api.blogs.getPost({ blogUuid, postUuid })
    const data = { ...post, blogId: blogUuid }
    Post.insert({ data })
    return post
  },
  async createPost (_, { blogUuid, data }) {
    const post = await api.blogs.createPost(blogUuid, data)
    Post.insert({ data: post })
    return post
  },
  async editPost (_, { blogUuid, postUuid, data }) {
    const post = await api.blogs.updatePost({ blogUuid, postUuid }, data)
    Post.insert({ data: post })
    return post
  },
  async deletePost (_, post) {
    if (!post) return
    await api.blogs.deletePost({
      blogUuid: post.blogUuid,
      postUuid: post.uuid
    })
    post.$delete()
  },
  async fetchBookmarks ({ dispatch }, { limit, offset }) {
    const { results } = await api.blogs.getBookmarks({ limit, offset })
    const blogUuids = uniq(results.map(post => post.blogUuid))
      .filter(blogUuid => !Blog.query().where('uuid', blogUuid).first())
    await Promise.all(
      blogUuids.map(uuid => dispatch('entities/blogs/fetchByUuid', uuid, { root: true }))
    )
    Post.insert({ data: results })
    return results
  },
  async mark (_, postUuid) {
    await api.blogs.setBookmark(postUuid)
    Post.update({
      where: postUuid,
      data: {
        isMarked: true
      }
    })
  },
  async unMark (_, postUuid) {
    await api.blogs.deleteBookmark(postUuid)
    Post.update({
      where: postUuid,
      data: {
        isMarked: false
      }
    })
  }
}

export default {
  getters,
  actions
}
