import apiReq from './request'

const BASE_URL = process.env.VUE_APP_CHAT_URL

export default {
  getToken () {
    return apiReq.post(`${BASE_URL}/ws_token`)
  },
  advertising () {
    return apiReq.get(`${BASE_URL}/advertising`)
  },
  deleteAdvertising (messageId) {
    return apiReq.delete(`${BASE_URL}/advertising`, {
      params: { messageId }
    })
  },
  media (params) {
    return apiReq.get(`${BASE_URL}/media`, {
      params
    })
  },
  mediaData (chatId, params) {
    return apiReq.get(`${BASE_URL}/media/${chatId}`, {
      params
    })
  },
  mediaAttachments (chatId, params) {
    return apiReq.get(`${BASE_URL}/media/${chatId}/attachment`, {
      params
    })
  },
  mediaLinks (chatId, params) {
    return apiReq.get(`${BASE_URL}/media/${chatId}/link`, {
      params
    })
  },
  mediaSubjects (chatId, params) {
    return apiReq.get(`${BASE_URL}/media/${chatId}/subject`, {
      params
    })
  },
  getAllChatSettings () {
    return apiReq.get(`${BASE_URL}/chat_settings`)
  },
  getChatSettings (chatId) {
    return apiReq.get(`${BASE_URL}/chat_settings/${chatId}`)
  },
  updateChatSettings (chatId, data) {
    return apiReq.put(`${BASE_URL}/chat_settings/${chatId}`, data)
  },
  getMood (params) {
    return apiReq.get(`${BASE_URL}/mood`, { params })
  },
  getFilters () {
    return apiReq.get(`${BASE_URL}/filter/list`)
  },
  createFilter (payload) {
    return apiReq.post(`${BASE_URL}/filter`, payload)
  },
  updateFilter (payload) {
    return apiReq.patch(`${BASE_URL}/filter`, payload)
  },
  deleteFilter (payload) {
    return apiReq.delete(`${BASE_URL}/filter`, { data: payload })
  },
  getFilterChats () {
    return apiReq.get(`${BASE_URL}/chat/filter/list`)
  },
  getFilterChatsById ({ id }) {
    return apiReq.get(`${BASE_URL}/chat/filter/list?chat_filter=${id}`)
  },
  addChatToFilter (payload) {
    return apiReq.post(`${BASE_URL}/chat/filter`, payload)
  },
  deleteChatFromFilter (payload) {
    return apiReq.delete(`${BASE_URL}/chat/filter`, { data: payload })
  }
}
