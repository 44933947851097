<template>
  <SecondaryModal
    :name="$options.name"
    :clickToClose="false"
    class="registration-popup">
    <div slot="default"
         class="registration-wrap">
      <template>
        <div class="registration">
          <div class="registration-title">
            {{ $t('signInInfo') }}
          </div>
          <form @submit.prevent="onSubmit">
            <PhoneInput
              style="margin-top: 20px;"
              :errors="serverErrors"
              @on-update="handlePhoneInput($event)"
              @valid="isValidPhone = $event">
            </PhoneInput>
            <BaseButton :loading="loading" :disabled="!isValidPhone">{{ $t('buttonEnter') }}</BaseButton>
          </form>
        </div>
      </template>
    </div>
  </SecondaryModal>
</template>

<script>
import SecondaryModal from './SecondaryModal'
import PhoneInput from '@/components/PhoneInput'
import { mapActions } from 'vuex'

export default {
  name: 'registration',
  components: {
    SecondaryModal,
    PhoneInput
  },
  props: {
    redirect: [Object, String]
  },
  data () {
    return {
      phoneInput: '',
      isValidPhone: false,
      loading: false,
      serverErrors: null
    }
  },
  methods: {
    ...mapActions({
      getOtp: 'auth/getOtp'
    }),
    handlePhoneInput (phone) {
      this.phoneInput = phone
      this.serverErrors = null
    },
    async onSubmit () {
      if (this.loading || !this.isValidPhone) return
      this.loading = true
      try {
        const { data: { isPinProtected } } = await this.getOtp(this.phoneInput)
        const params = { phoneNumber: this.phoneInput }
        if (isPinProtected) {
          params.isPinProtected = true
        }
        // if (this.$route.name === 'sign-in-blog' && this.$route.query.id) {
        //   const redirectRoute = {
        //     name: 'app-blogs',
        //     params: { blogId: this.$route.query.id }
        //   }
        //   if (this.$route.query.postId) {
        //     redirectRoute.name = 'app-blogs-post'
        //     redirectRoute.params.postId = this.$route.query.postId
        //   }
        //   params.redirect = redirectRoute
        // }
        if (this.redirect) {
          params.redirect = this.redirect
        }
        this.$modal.hide(this.$options.name)
        setTimeout(() => {
          this.$router.push({ name: 'verify-otp', params })
        })
      } catch (error) {
        if (error.response.data.data.phone) {
          this.serverErrors = [this.$t('errorPhoneInvalid')]
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.registration {
  &-wrap {
    padding-bottom: 30px;
  }
  &-title{
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.2;
    @include respondTo(600px){
      font-size: 14px;
    }
  }
  form button {
    margin-top: 70px;
  }
  .phone-input .vti__dropdown-list {
    max-height: 160px;
  }
}
</style>
