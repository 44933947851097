import TranslatedMessage from '@/models/TranslatedMessage'
import Message from '@/models/Message'
import api from '@/api'
// import apiTranslate from '@/api/translate'

const state = {
  language: null
}

const actions = {
  // async translate ({ state }, messageIds) {
  //   if (!state.language) return
  //   const messages = Message.query().whereIdIn(messageIds).with('translatedMessage').get()
  //     .filter(message => message.typeName === 'text' && !message.translatedMessage)
  //     .map(message => ({
  //       messageId: message.id,
  //       sourceText: message.payload
  //     }))
  //   for (const item of messages) {
  //     const sourceLangReq = await apiTranslate.detect({ q: item.sourceText })
  //     const sourceLang = sourceLangReq.data[0].language
  //
  //     const { data } = await api.translate.translate({
  //       q: item.sourceText,
  //       target: state.language,
  //       source: sourceLang
  //     })
  //     TranslatedMessage.$create({
  //       data: [{
  //         messageId: item.messageId,
  //         sourceLang: sourceLang,
  //         sourceText: item.sourceText,
  //         targetLang: state.language,
  //         targetText: data.translatedText
  //       }]
  //     })
  //   }
  // }
  async translate ({ state }, messageIds) {
    if (!state.language) return
    const messages = Message.query()
      .whereIdIn(messageIds)
      .with('translatedMessage')
      .get()
      .filter(message => message.typeName === 'text' && !message.translatedMessage)
      .map(message => ({
        messageId: message.id,
        sourceText: message.payload
      }))
    const { data } = await api.translate.translate({
      targetLang: state.language,
      messages
    })
    TranslatedMessage.$create({ data })
  }
}

export default {
  state,
  actions
}
