import api from '@/api/agents'
import { createToken } from 'vue-stripe-elements-plus'

const state = {
  offersList: [],
  agentOffers: [],
  BDTLOfferUserList: []
}

const getters = {
  offersList: state => state.offersList,
  agentOffers: state => state.agentOffers,
  BDTLOfferUserList: state => state.BDTLOfferUserList
}

const actions = {
  async fetchOffersList ({ commit }) {
    try {
      const { data } = await api.fetchOffersList()
      commit('setOffersList', data)
    } catch (err) {
      throw err.response
    }
  },
  async buyBDTLOffers ({ commit }, payload) {
    let stripeResp
    const { packageOfferId, packageOfferAmount, packageId, request } = payload
    if (payload.name) {
      stripeResp = await createToken(payload.name)
    }
    const params = {
      packageOfferId,
      packageOfferAmount,
      packageId,
      request
    }
    if (stripeResp) {
      const { token: { id } } = stripeResp
      params.chargeToken = id
    } else {
      params.chargeToken = 'free'
    }
    const response = await api.buyOffers(params)
    return response
  },
  async fetchAgentOffers ({ commit }) {
    try {
      const { data } = await api.fetchAgentOffers()
      commit('setAgentOffers', data)
    } catch (err) {
      throw err.response
    }
  },
  async setBDTLOffer ({ commit }, payload) {
    try {
      return await api.setBDTLOffer(payload)
    } catch (err) {
      throw err.response
    }
  },
  async setFreeBDTLOffers ({ commit }, payload) {
    try {
      const { packageOfferId, packageOfferAmount, packageId, request } = payload
      const params = {
        packageOfferId,
        packageOfferAmount,
        packageId,
        request,
        chargeToken: 'free'
      }
      return await api.setFreeBDTLOffers(params)
    } catch (err) {
      throw err.response
    }
  },
  async getBDTLOfferUserList ({ commit }, payload) {
    try {
      let params = new URLSearchParams()
      payload.forEach(item => {
        params.append('users_id', item)
      })
      const { data } = await api.getBDTLOfferUserList(params)
      commit('setBDTLOfferUserList', data)
    } catch (err) {
      throw err.response
    }
  }
}

const mutations = {
  setOffersList (state, data) {
    state.offersList = data
  },
  setAgentOffers (state, data) {
    state.agentOffers = data
  },
  setBDTLOfferUserList (state, data) {
    state.BDTLOfferUserList = data
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true
}
