import apiReq from './request'

const BASE_URL = process.env.VUE_APP_MEDIA_URL

export default {
  async upload (file, onUploadProgress) {
    const formData = new FormData()
    formData.append('file', file)
    const { data: { imgLink } } = await apiReq.post(`${BASE_URL}/upload`, formData, {
      onUploadProgress
    })
    return imgLink
  }
}
