import Participant from '@/models/Participant'
import Chat from '@/models/Chat'
import User from '@/models/User'
import Message, { TYPE_CODE, REASON_CODE } from '@/models/Message'
import Ws from '@/api/ws'
import store from '@/store'

Ws.on('participant.new', data => {
  data.participants.forEach(participant => {
    Participant.insert({
      data: {
        chatId: data.chatId,
        ...participant
      }
    })
  })
  User.dispatch('getProfiles', data.participants.map(participant => participant.userId))
})
Ws.on('participant.update', data => {
  Participant.insertOrUpdate({ data })
})
Ws.on('participant.delete', data => {
  const myUser = store.getters['auth/user']
  // const { chatId, userId } = data
  // Participant.delete([chatId, userId])
  // if (userId === myUser.id) {
  //   Chat.delete(chatId)
  // }
  data.participants.forEach(({ userId }) => {
    Participant.delete([data.chatId, userId])
    if (userId === myUser.id) {
      Chat.delete(data.chatId)
    }
  })
})
Ws.on('participant.change_online_status', ({ userId, isOnline }) => {
  User.$update({
    where: userId,
    data: {
      isOnline
    }
  })
})

export const createServiceMessage = (chatId, userId, reason) => {
  const date = new Date().toISOString().slice(0, -1)
  return {
    id: Date.now(),
    type: TYPE_CODE.service,
    senderId: store.getters['auth/user'].id,
    sender: null,
    payload: { reason, subjectId: userId },
    chatId,
    chat: null,
    properties: {
      isRead: true,
      isEdited: false,
      lastUpdate: date
    },
    created: date,
    attachment: null,
    isPending: false,
    translatedMessage: null
  }
}

const actions = {
  async add (_, { chatId, participants }) {
    const { data } = await Ws.req({
      version: '2.0',
      method: 'participant.add',
      params: {
        chat_id: chatId,
        participants
      }
    })
    const insertData = data.participants.map(participant => ({
      ...participant,
      chatId
    }))
    Participant.insert({ data: insertData })
    data.participants.forEach((participant) => {
      const message = createServiceMessage(chatId, participant.userId, REASON_CODE.addNewMember)
      Message.insert({ data: message })
    })
  },
  async remove ({ rootGetters }, { chatId, userId }) {
    await Ws.req({
      version: '2.0',
      method: 'participant.remove',
      params: {
        chat_id: chatId,
        participants: [userId]
      }
    })
    Participant.delete([chatId, userId])
    const message = createServiceMessage(chatId, userId, REASON_CODE.removeMember)
    Message.insert({ data: message })
  },
  async leave (_, { chatId }) {
    await Ws.req({
      version: '2.0',
      method: 'participant.leave',
      params: { chat_id: chatId }
    })
    Chat.delete(chatId)
    Participant.delete((participant) => {
      return participant.chatId === chatId
    })
  },
  async changeModerator (_, { chatId, userId }) {
    const participant = Participant.find([chatId, userId])
    await Ws.req({
      version: '2.0',
      method: 'participant.change_moderator',
      params: {
        chat_id: chatId,
        user_id: userId,
        isModerator: !participant.isModerator
      }
    })
    Participant.update({
      data: {
        chatId,
        userId,
        isModerator: !participant.isModerator
      }
    })
  }
}

export default {
  actions
}
