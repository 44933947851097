var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g(_vm._b({class:[
    'form--button',
    {
      'button--loading': _vm.loading,
      'button--disabled': _vm.disabled,
      'button--oval': _vm.oval,
      'button--secondary': _vm.secondary,
      'button--red': _vm.red,
      'button--dark': _vm.dark
    }
  ],attrs:{"type":"submit"}},'button',_vm.$attrs,false),_vm.$listeners),[_c('span',[_vm._t("default")],2),_vm._t("icon")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }