<template>
  <BaseModal
    name="success"
    icon="check"
    :title="title"
    @before-open="setProps"
    @before-close="reset"
  >
    <p v-if="text">{{ text }}</p>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'

export default {
  name: 'success-modal',
  components: {
    BaseModal
  },
  data () {
    return {
      title: this.$t('successTitle'),
      text: ''
    }
  },
  methods: {
    setProps ({ params = {} }) {
      const { title, text } = params
      if (title) {
        this.title = title
      }
      if (text) {
        this.text = text
      }
    },
    reset () {
      this.title = this.$t('successTitle')
      this.text = ''
    }
  }
}
</script>

<style lang="scss">
</style>
