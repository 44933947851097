import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import vClickOutside from 'v-click-outside'
import VueMeta from 'vue-meta'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import Vuelidate from 'vuelidate'
import VModal from 'vue-js-modal'
import '@/components/_globals'
import VueClipboard from 'vue-clipboard2'
import VTooltip from 'v-tooltip'
import VueScrollTo from 'vue-scrollto'
import VueChatScroll from 'vue-chat-scroll'
import Croppa from 'vue-croppa'
import VueLazyload from 'vue-lazyload'
import '@/utils/vueFilters'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import VueMask from 'v-mask'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://bba5d4d1fbf94787abc76ea49e521b12@sentry.io/4254545',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    environment: process.env.VUE_APP_ENV
  })
}

if (localStorage.getItem('otp')) {
  store.state.auth.otp = localStorage.getItem('otp')
}

Vue.use(VueMask)
Vue.use(VueTelInput)
Vue.use(vClickOutside)
Vue.use(VueMeta)
Vue.use(vueAwesomeCountdown, 'vac')
Vue.use(Vuelidate)
Vue.use(VModal)
Vue.use(VueClipboard)
Vue.use(VTooltip)
Vue.use(VueScrollTo)
Vue.use(VueChatScroll)
Vue.use(Croppa)
Vue.use(VueGoodTablePlugin)
Vue.use(VueLazyload, {
  lazyComponent: true
})
Vue.use(Viewer, {
  defaultOptions: {
    title: false,
    rotatable: false,
    navbar: false
  }
})
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

const handleStatus = () => {
  store.commit('SET_ONLINE', navigator.onLine)
}
window.addEventListener('online', handleStatus)
window.addEventListener('offline', handleStatus)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
