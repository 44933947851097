<template>
  <modal name="resend-otp"
         class="resend-otp"
         transition="modal-fade">
    <div class="resend-otp__wrap">
      <div class="resend-otp__close" @click.prevent="closePopup">
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times-circle" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
             class="svg-inline--fa fa-times-circle fa-w-16 fa-2x">
          <path fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z"
                class=""></path>
        </svg>
      </div>
      <div class="step-1" v-if="step === 1">
        <form action="" class="enter-phone" @submit.prevent="onSubmit" v-if="user">
          <h3>
            {{ $t('resendOTPMessage') }}
          </h3>
          <PhoneInput
            :errors="serverErrors"
            :value="user.phone"
            :disabled="true">
          </PhoneInput>
          <BaseButton :loading="loading">
            {{ $t('sendOTP') }}
          </BaseButton>
        </form>
      </div>
      <div class="step-2" v-if="step === 2">
        <form action=""
              class="submit-otp"
              @submit.prevent="onSendOtp">
          <div class="form-title">
            {{ $t('enterOTPMessage') }}
          </div>
          <div class="form-content">
            <BaseInput
              class="container--m"
              v-model="code"
              ref="codeInput"
              autofocus
              @input="autoSubmit"
              :invalid="$v.code.$error"
              :error="otpError"
            />
            <p v-if="timerEndTime">
            <span>
              {{ $t('confirmPhoneTimer') }}
             </span>
              <countdown
                :end-time="timerEndTime"
                @finish="onTimerEnds"
                ref="countdown">
                <template v-slot:process="{ timeObj }">
                  {{ timeObj.m }} : {{ timeObj.s }}
                </template>
                <template v-slot:finish>00:00</template>
              </countdown>
            </p>
            <BaseButton :loading="loading" :disabled="loading || !code">
              {{ $t('buttonConfirm') }}
            </BaseButton>
          </div>
        </form>
        <div v-if="link === 'button'" class="resend-otp__info">
          <p class="txt">{{ $t('confirmPhoneCode') }}</p>
          <p @click="onResend">{{ $t('confirmPhoneLink') }}</p>
        </div>
        <p v-if="link === 'resent'" class="resend-message">
          <BaseIcon name="check"/>
          {{ $t('confirmPhoneResent') }}
        </p>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import PhoneInput from '@/components/PhoneInput'
import { maxLength, minLength } from 'vuelidate/lib/validators'

const TIMEOUT = 30 * 1000 // 30sec
const CODE_LENGTH = 6

export default {
  name: 'resend-otp',
  components: {
    PhoneInput
  },
  data () {
    return {
      serverErrors: null,
      isValidPhone: false,
      phoneInput: '',
      error: '',
      loading: false,
      errors: {},
      step: 1,
      timerEndTime: null,
      link: 'start',
      code: ''
    }
  },
  computed: {
    ...mapState('auth', ['deviceId']),
    ...mapGetters({
      user: 'auth/user',
      otp: 'auth/otp'
    }),
    otpError () {
      if (!this.$v.code.minLength) {
        return `${this.$t('errorMinLength_begin')} ${CODE_LENGTH} ${this.$t('errorLength_end')}`
      }
      if (!this.$v.code.maxLength) {
        return `${this.$t('errorMaxLength_begin')} ${CODE_LENGTH} ${this.$t('errorLength_end')}`
      }
      if (!this.$v.code.server) {
        return this.$t(this.error)
      }
      return this.$t('errorEmptyField')
    }
  },
  validations: {
    code: {
      minLength: minLength(CODE_LENGTH),
      maxLength: maxLength(CODE_LENGTH),
      server () {
        return !this.error
      }
    }
  },
  created () {
    this.setEndTime()
  },
  methods: {
    ...mapActions({
      getOtp: 'auth/getOtp',
      deletePhone: 'entities/users/deletePhone'
    }),
    ...mapMutations({
      setOTP: 'auth/SET_OTP'
    }),
    async onSubmit () {
      this.errors = {}
      this.loading = true
      try {
        await this.getOtp(this.user.phone)
      } catch (error) {
        if (error.response.data.data.phone) {
          this.serverErrors.push(this.$t('errorPhoneInvalid'))
        }
      } finally {
        this.loading = false
        this.step = 2
      }
    },
    onSendOtp () {
      this.$v.$touch()
      this.errors = {}
      if (this.loading || this.$v.$invalid) return
      this.loading = true
      this.setOTP(this.code)
      this.deleteRequest()
    },
    async onResend () {
      await this.getOtp(this.user.phone)
      this.link = 'resent'
      this.error = ''
      this.code = ''
      this.setEndTime()
    },
    setEndTime () {
      this.timerEndTime = new Date().getTime() + TIMEOUT
    },
    onTimerEnds () {
      this.timerEndTime = null
      this.link = 'button'
    },
    autoSubmit () {
      if (this.code.length < CODE_LENGTH) return
      this.onSendOtp()
    },
    closePopup () {
      this.$modal.hide('resend-otp')
    },
    async deleteRequest () {
      let obj = {
        device: [
          {
            id: this.deviceId,
            type: 'web'
          }
        ],
        phone: this.user.phone
      }
      if (this.otp) {
        obj.otp = this.otp
      }
      this.loading = true
      try {
        await this.deletePhone(obj)
      } catch (e) {
        if (e.response.data.data.type === 'otp_unconfirmed') {
          this.$modal.show('error', {
            error: this.$t('auth_otp_unconfirmed')
          })
        } else if (e.response.data.data.type === 'pin_unconfirmed') {
          this.$modal.show('error', {
            error: this.$t('auth_pin_unconfirmed')
          })
        } else {
          this.$modal.show('error', {
            error: e.response.data
          })
        }
      } finally {
        this.loading = false
        this.$modal.hide('resend-otp')
        this.step = 1
        this.code = ''
      }
    }
  },
  beforeDestroy () {
    if (this.$refs.countdown) {
      this.$refs.countdown.finishCountdown()
    }
  },
  destroyed () {
    this.code = ''
  }
}
</script>

<style lang="scss" scoped>
.resend-otp {
  .v--modal-box{
    @include respondTo(650px) {
      width: 95%;
      max-width: 95%;
    }
  }
  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    svg {
      width: 100%;
    }
  }
  &__wrap {
    padding: 60px 40px;
    position: relative;
    @include respondTo(600px) {
      padding: 35px;
    }
    .step-1 form {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      max-width: 350px;
      margin: 0 auto;
    }
    input {
      width: 100%;
      height: 40px;
    }
    button {
      margin-top: 20px;
      width: 100%;
    }
  }
  &__title {
    color: #3b3b3b;
    font-size: 16px;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 30px;
    @include respondTo(600px) {
      font-size: 14px;
    }
  }
  &__info {
    p:first-child{
      margin-top: 10px;
    }
    p:last-child {
      cursor: pointer;
      font-weight: bold;
      margin-top: 15px;
      text-align: center;
    }
  }
  .resend-message {
    margin-top: 10px;
    text-align: center;
  }
}
.enter-phone{
  h3{
    text-align: center;
    margin-bottom: 30px;
  }
}
.submit-otp{
  text-align: center;
  .form-container{
    margin: 0 auto;
  }
  button{
    max-width: 300px;
    margin: 30px auto 0;
  }
}
</style>
