import apiReq from './request'

const USER_URL = `${process.env.VUE_APP_USER_URL}`

export default {
  getDespaxoCountriesList (data) {
    return apiReq.get(`${USER_URL}/user/info/countries`, data)
  },
  getContactInfo (data) {
    return apiReq.get(`${USER_URL}/user/user/contact-info`, data)
  },
  createContactInfo (data) {
    return apiReq.post(`${USER_URL}/user/user/contact-info`, data)
  },
  updateContactInfo (data) {
    return apiReq.patch(`${USER_URL}/user/user/contact-info`, data)
  },
  setDespaxoUserType (data) {
    return apiReq.post(`${USER_URL}/auth/user/account-type`, data)
  }
}
