import { SocialProfile } from './SocialProfileHierarchy'

export default class SocialProfileLookingFor extends SocialProfile {
  static entity = 'socialProfilesLookingFor'
  static baseEntity = 'socialProfiles'

  static fields () {
    return {
      ...super.fields(),
      subscribeStatus: this.number(0).nullable(),
      type: this.attr('LOOKING_FOR')
    }
  }
}
