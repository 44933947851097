/* eslint-disable */

import Chat, { TYPE_CODE } from '@/models/Chat'
import Ws from '@/api/ws'
import api from '@/api'
import { uniq, isEqual, cloneDeep } from 'lodash' // debounce
import User from '@/models/User'
import Fuse from 'fuse.js'
import Participant from '@/models/Participant'
import ChatSettings from '@/models/ChatSettings'
import debounce from 'lodash.debounce'

const fetchUsers = userIds => User.dispatch('getProfiles', userIds)

Ws.on('chat.new', data => {
  Chat.insert({ data })
  fetchUsers(data.participants.map(participant => participant.userId))
})
Ws.on('chat.update', data => {
  Chat.insertOrUpdate({ data })
  fetchUsers(data.participants.map(participant => participant.userId))
})
Ws.on('chat.delete', data => {
  Chat.delete(data.id)
})

const deactivateChatTyping = debounce(chatId => {
  Chat.update({
    where: chatId,
    data: {
      isTyping: false
    }
  })
}, 2000)
const activateChatTyping = chatId => {
  Chat.update({
    where: chatId,
    data: {
      isTyping: true
    }
  })
  deactivateChatTyping(chatId)
}

Ws.on('message.typing', data => {
  const { chatId } = data
  activateChatTyping(chatId)
})

export default {
  state: {
    activeChatId: null,
    isInited: false
  },

  getters: {
    isInited: state => state.isInited,
    getById: _ => id => Chat.query().whereId(id).with('participants.user').first(), // .with('participantsUsers')
    myChats: (state, getters, rootState, rootGetters) => {
      return (
        Chat.query()
          .with('participants.user')
          // .with('participantsUsers')
          .with('messages', query => {
            query.orderBy('createdTs', 'desc')
          })
          .with('messages.sender')
          .get()
          .filter(chat => {
            if (chat.typeName !== 'one-to-one') return true
            return chat.messages[0] || chat.message
          })
      )
      // .sort((a, b) => {
      //   const aLastMsg = a.messages[0] || a.message
      //   const bLastMsg = b.messages[0] || b.message
      //   if (!bLastMsg && !aLastMsg) return 0
      //   if (!bLastMsg) return -1
      //   if (!aLastMsg) return 1
      //   return +new Date(bLastMsg.created + 'Z') - +new Date(aLastMsg.created + 'Z')
      // })
    },
    activeChat: (state, getters) => getters.getById(state.activeChatId),
    search: (state, getters) => query => {
      const fuse = new Fuse(getters.myChats, {
        keys: ['name']
      })
      return fuse.search(query)
    },
    getChatWithUser: (state, getters, rootState, rootGetters) => userId => {
      const oneToOneChatList = Chat.query().where('typeName', 'one-to-one').get()
      return oneToOneChatList.find(chat => chat.typeName === 'one-to-one' && Participant.find([chat.id, userId]))
    }
  },

  mutations: {
    SET_ACTIVE(state, id) {
      state.activeChatId = id
    },
    SET_INITED(state, flag) {
      state.isInited = flag
    }
  },

  actions: {
    async init({ getters, rootGetters, dispatch, commit }) {
      if (!rootGetters['ws/isOpened']) {
        await Ws.connect()
      }
      if (!getters.isInited) {
        await dispatch('fetch')
        ChatSettings.dispatch('$fetch')
        ChatSettings.dispatch('fetchAll')
        commit('SET_INITED', true)
      }
    },
    async fetch({ dispatch, rootState }) {
      const request = { version: '2.0', method: 'chat.my_chats' }
      const response = await Ws.req(request)
      let { data } = response
      try {
        data = data.map(chat => {
          return {
            ...chat,
            participants: chat.participants.map(participant => ({
              ...participant,
              chatId: chat.id,
              userId: participant.participant.userId
            }))
          }
        })
      } catch (error) {
        console.log('========================================')
        const user = rootState.auth.user
        console.log('🚀 ~ user ~ ', cloneDeep(user))
        console.log('🚀 ~ request ~ ', cloneDeep(request))
        console.log('🚀 ~ response ~ ', cloneDeep(response))
        console.log('========================================')
        console.error('🚀 ~ file: chats.js:133 ~ fetch ~ error', error)
        console.log('========================================')
      }
      let allParticipants = data.reduce((res, chat) => {
        return res.concat(chat.participants.map(participant => participant.userId))
      }, [])
      allParticipants = uniq(allParticipants)
      Chat.insert({ data })
      try {
        await fetchUsers(allParticipants)
      } catch (e) {}
    },
    async create({ rootGetters, dispatch, getters }, { photo, ...params }) {
      await dispatch('init')
      if (params.type === TYPE_CODE['one-to-one']) {
        const existedOneToOneChat = getters.getChatWithUser(params.participants[0])
        if (existedOneToOneChat) {
          return existedOneToOneChat
        }
      }
      if (photo) {
        const imgLink = await api.media.upload(photo)
        params = { ...params, imgLink }
      }
      try {
        const { data } = await Ws.req({
          version: '2.0',
          method: 'chat.create',
          params
        })
        const modifiedData = {
          ...data,
          participants: data.participants.map(participant => ({ ...participant, chatId: data.id }))
        }
        Chat.insert({ data: modifiedData })
        return data
      } catch (e) {
        if (e.type === 'existence') {
          let { participants } = params
          participants = [...participants, rootGetters['auth/user'].id]
          participants = participants.sort()
          const existedChat = Chat.query()
            .where(chat => {
              const chatParticipants = [...chat.participants]
              return chat.typeName === 'one-to-one' && isEqual(participants, chatParticipants.sort())
            })
            .first()
          if (existedChat) return existedChat
        }
        return e
      }
    },
    async edit(_, params) {
      const { data } = await Ws.req({
        version: '2.0',
        method: 'chat.update',
        params
      })
      Chat.update({ data })
    },
    async editPhoto(_, { id, photo }) {
      const imgLink = await api.media.upload(photo)
      const { data } = await Ws.req({
        version: '2.0',
        method: 'chat.update',
        params: {
          id,
          imgLink
        }
      })
      Chat.update({ data })
    },
    async remove({ dispatch, rootGetters }, { id }) {
      const chat = Chat.find(id)
      if (chat.owner !== rootGetters['auth/user'].id) {
        await Participant.dispatch('leave', { chatId: id })
        return
      }
      await Ws.req({
        version: '2.0',
        method: 'chat.remove',
        params: { id }
      })
      Chat.delete(id)
    },
    async getMedia(_, { chatId, lastUpdate, userId }) {
      const { data } = await api.chats.media({
        chatId,
        lastUpdate,
        target: userId
      })
      User.$update({
        where: userId,
        data: {
          mediaInfo: data
        }
      })
    }
  }
}
