import { Model } from '@vuex-orm/core'
import { getUserDisplayName, getColorById } from '@/utils/helpers'
import UserStatus from './UserStatus'
export default class User extends Model {
  static entity = 'users'
  static primaryKey = 'userId'

  static fields () {
    return {
      accountTypes: this.string(''),
      userId: this.number(null),
      phone: this.string(''),
      firstName: this.string(null).nullable(),
      lastName: this.string(null).nullable(),
      nickname: this.string(null).nullable(),
      avatarLink: this.string(null).nullable(),
      birthDate: this.string(null).nullable(),
      mediaInfo: this.attr({}),
      isOnline: this.boolean(false),
      lastFetch: this.attr(() => Date.now()), // this.number(null).nullable()
      statusId: this.number(null).nullable(),
      status: this.belongsTo(UserStatus, 'statusId'),
      statusExpires: this.string(null).nullable()
    }
  }

  get displayName () {
    return getUserDisplayName(this)
  }
  get color () {
    return getColorById(this.userId)
  }
  get statusExpiresTs () {
    return this.statusExpires ? +new Date(this.statusExpires + 'Z') : null
  }

  // static beforeUpdate (model) {
  //   model.lastFetch = Date.now()
  // }
}
