<template>
  <SecondaryModal
    :name="$options.name"
    :title="$t('logout.title')"
    @before-open="reset"
  >
    <div>{{ $t('logout.confirmation') }}</div>
    <div :class="$style.switchContainer">
      <BaseSwitch v-model="isAllSessions">{{ $t('logout.allSessions') }}</BaseSwitch>
    </div>

    <template v-slot:footer>
      <BaseButton oval secondary @click="$modal.hide($options.name)">{{ $t('buttonCancel') }}</BaseButton>
      <BaseButton oval :loading="loading" @click="logoutRequest">{{ $t('logout.title') }}</BaseButton>
    </template>
  </SecondaryModal>
</template>

<script>
import SecondaryModal from './SecondaryModal'
import { mapActions } from 'vuex'

export default {
  name: 'logout',
  components: {
    SecondaryModal
  },
  data () {
    return {
      loading: false,
      isAllSessions: false
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    reset () {
      this.loading = false
      this.isAllSessions = false
    },
    async logoutRequest () {
      this.loading = true
      await this.logout(this.isAllSessions)
      this.loading = false
      this.$modal.hide(this.$options.name)
    }
  }
}
</script>

<style module lang="scss">
.switchContainer {
  margin-top: 8px;
  display: inline-block;
}
</style>
