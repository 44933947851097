import { Model } from '@vuex-orm/core'
import User from './User'
import { SocialProfile } from './SocialProfileHierarchy'

export default class SocialProfileSubscription extends Model {
  static entity = 'socialProfileSubscriptions'

  static fields () {
    return {
      id: this.number(0),
      profileNickname: this.string(''),
      profile: this.belongsTo(SocialProfile, 'profileNickname'),
      user: this.number(0),
      userObj: this.belongsTo(User, 'user'),
      createdAt: this.string(''),
      status: this.number(1)
    }
  }
}
