import Vue from 'vue'

import BaseButton from './_BaseButton'
import BaseInput from './_BaseInput'
import BaseIcon from './_BaseIcon'
import BaseSwitch from './_BaseSwitch'
import BaseSelect from './_BaseSelect'

Vue.component('BaseButton', BaseButton)
Vue.component('BaseInput', BaseInput)
Vue.component('BaseIcon', BaseIcon)
Vue.component('BaseSwitch', BaseSwitch)
Vue.component('BaseSelect', BaseSelect)
