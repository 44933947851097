import apiReq from './request'

const BASE_URL = `${process.env.VUE_APP_APIGW_URL}/callout`

export default {
  getProfiles (uuidList) {
    return apiReq.post(`${BASE_URL}/getprofile`, {
      uuid: uuidList
    })
  },
  changeSubscriptionStatus (uuid) {
    return apiReq.post(`${BASE_URL}/changeunsubscribestatus`, {
      uuid
    })
  }
}
