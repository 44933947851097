import { SocialProfile } from './SocialProfileHierarchy'

export default class SocialProfileMy extends SocialProfile {
  static entity = 'socialProfilesMy'
  static baseEntity = 'socialProfiles'

  static fields () {
    return {
      ...super.fields(),
      pending: this.number(0).nullable(),
      type: this.attr('MY')
    }
  }
}
