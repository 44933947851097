<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import CreateStatus from './CreateStatus'

export default {
  name: 'edit-status',
  extends: CreateStatus,
  data () {
    return {
      id: null
    }
  },
  computed: {
    ...mapGetters({
      getStatus: 'entities/userStatuses/find'
    }),
    modalTitle () {
      return this.$t('userStatus.editStatusTitle')
    },
    submitBtnTxt () {
      return this.$t('buttonEdit')
    },
    status () {
      return this.getStatus(this.id)
    }
  },
  validations: {
    text: { required },
    mediaSrc: {}
  },
  methods: {
    beforeOpen ({ params: { id } }) {
      this.id = id
      this.resetForm()
    },
    resetForm () {
      this.$v.$reset()
      this.loading = false
      if (!this.status) return
      this.title = this.status.title
      this.text = this.status.statusText
      this.mediaSrc = this.status.mediaLink
      this.mediaFile = null
    },
    ...mapActions({
      editStatus: 'entities/userStatuses/editStatus'
    }),
    async onSubmit () {
      this.$v.$touch()
      if (this.loading || this.$v.$invalid) return
      const data = {
        id: this.id,
        mediaType: 'IMAGE',
        title: this.title,
        statusText: this.text
      }
      if (this.mediaFile) {
        data.mediaFile = this.mediaFile
      }
      try {
        this.loading = true
        await this.editStatus(data)
        this.$modal.hide(this.$options.name)
      } catch (e) {
        const { data } = e.response.data
        this.$modal.show('error', { error: data })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style module lang="scss">
.list {
  height: 360px;
  overflow-y: scroll;
}
.form {
  padding: 14px 30px;
}
.item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
  border-bottom: 1px solid $border-color;
  &.active {
    background-color: rgba($main-bg-colored, 0.3);
  }
}
.mediaWrap {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  overflow: hidden;
  flex-shrink: 0;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.info {
  margin-left: 16px;
  color: $text-darkest;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.file {
  margin-bottom: 14px;
}
.fileIcon {
  font-size: 18px;
  color: $text-colored;
  margin-right: 4px;
}
</style>
