import api from '@/api'
import CalloutProfile from '@/models/CalloutProfile'

const actions = {
  async fetch (_, uuidList) {
    const { data } = await api.callouts.getProfiles(uuidList)
    CalloutProfile.insert({ data })
  },
  async changeSubscriptionStatus (_, uuid) {
    const { data } = await api.callouts.changeSubscriptionStatus(uuid)
    const { subscribeAfter } = data
    CalloutProfile.update({
      where: uuid,
      data: {
        isSubscribed: !subscribeAfter
      }
    })
  }
}

export default {
  actions
}
