import apiReq from './request'

const BASE_URL = process.env.VUE_APP_APIGW_URL
const TRANSLATE_API = process.env.VUE_APP_TRANSLATE_API

export default {
  detect (data) {
    return apiReq.post(`${BASE_URL}/detect`, data)
  },
  languages () {
    return apiReq.get(`${BASE_URL}/languages`)
  },
  translate (data) {
    return apiReq.post(`${BASE_URL}/translate`, data)
  },
  translateFile (data) {
    return apiReq.post(`${BASE_URL}/translate_file`, data)
  },

  detectMatrix (data) {
    return apiReq.post(`${TRANSLATE_API}/detect`, data)
  },
  languagesMatrix () {
    return apiReq.get(`${TRANSLATE_API}/languages`)
  },
  translateMatrix (data) {
    return apiReq.post(`${TRANSLATE_API}/translate`, data)
  },
  translateFileMatrix (data) {
    return apiReq.post(`${TRANSLATE_API}/translate_file`, data)
  }
}
