import { Model } from '@vuex-orm/core'

export default class Package extends Model {
  static entity = 'packages'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(''),
      // features: this.string('').nullable(),
      description: this.string('').nullable(),
      price: this.string('').nullable(),
      language: this.string(''),
      hasSocial: this.boolean(false),
      calloutsCount: this.number(0),
      translateCount: this.number(0),
      lifetime: this.number(0).nullable()
    }
  }

  get isPremium () {
    return this.name !== 'Basic'
  }
}
