import { Model } from '@vuex-orm/core'
import Message from './Message'
import User from './User'
import Participant from './Participant'
import ChatSettings from './ChatSettings'
import { invert } from 'lodash'

export const TYPE_CODE = {
  'group': 1,
  'one-to-one': 2,
  'saved-messages': 3
}
const TYPE_NAME = invert(TYPE_CODE)

export const NAME_MAX_LENGTH = 60
export const DESCRIPTION_MIN_LENGTH = 5
export const DESCRIPTION_MAX_LENGTH = 500

export default class Chat extends Model {
  static entity = 'chats'

  static fields () {
    return {
      id: this.attr(null), // number
      type: this.number(null),
      name: this.string(''),
      imgLink: this.string(null).nullable(),
      description: this.string(null).nullable(),
      properties: this.attr({}),
      messages: this.hasMany(Message, 'chatId'),
      participants: this.hasMany(Participant, 'chatId'), // this.belongsToMany(Participant, User, 'chatId', 'userId'),
      owner: this.number(0),
      ownerUser: this.belongsTo(User, 'owner'),
      message: this.attr(null).nullable(), // this.hasOne(Message, 'id')
      isTyping: this.boolean(false),
      chatSettings: this.belongsTo(ChatSettings, 'id')
    }
  }

  get typeName () {
    return TYPE_NAME[this.type]
  }
  get participantsUsers () {
    return this.participants ? this.participants.map(participant => participant.user) : []
  }
  static beforeCreate (model) {
    ChatSettings.insert({
      data: {
        chatId: model.id
      }
    })
  }
}
