import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getUserLocale, getUserLocales } from 'get-user-locale'
import { intersection } from 'lodash'

const pluralizationRules = {
  ru: function (choice, choicesLength) {
    if (choice === 0) {
      return 0
    }
    const teen = choice > 10 && choice < 20
    const endsWithOne = choice % 10 === 1
    if (!teen && endsWithOne) {
      return 1
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
      return 2
    }
    return choicesLength < 4 ? 2 : 3
  }
}

Vue.use(VueI18n)

const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

function loadLocaleMessages () {
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
const messages = loadLocaleMessages()
const appLocaleList = Object.keys(messages)

let currentLocale = localStorage.getItem('locale') // store.state.locale
if (!currentLocale) {
  const userLocale = getUserLocale().slice(0, 2)
  const userLocaleList = getUserLocales().map(l => l.slice(0, 2))
  const commonLocales = intersection(appLocaleList, userLocaleList)
  currentLocale =
    appLocaleList.find(l => l === userLocale) || commonLocales[0] || process.env.VUE_APP_I18N_LOCALE || 'en'
  localStorage.setItem('locale', currentLocale) // store.commit('SET_LOCALE', currentLocale)
}

export default new VueI18n({
  locale: currentLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  pluralizationRules,
  messages
})
