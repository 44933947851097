import apiReq from './request'

const BASE_URL = `${process.env.VUE_APP_APIGW_URL}/social/blog`

export default {
  getOpenBlog (shortId) {
    return apiReq.get(`${BASE_URL}/open/${shortId}/`)
  },
  getBlogs ({ shortId, limit, offset } = {}) {
    return apiReq.get(`${BASE_URL}/`, {
      params: { shortId, limit, offset }
    })
  },
  getBlog (uuid) {
    return apiReq.get(`${BASE_URL}/${uuid}`)
  },
  getMyBlogs ({ limit, offset } = {}) {
    return apiReq.get(`${BASE_URL}/my/`, {
      params: { limit, offset }
    })
  },
  createBlog (data) {
    return apiReq.post(`${BASE_URL}/my/`, data)
  },
  createBlogInfo (blogUuid, data) {
    return apiReq.post(`${BASE_URL}/my/${blogUuid}/blog-info/`, data)
  },
  updateBlogInfo ({ blogUuid, blogInfoId, data }) {
    return apiReq.patch(`${BASE_URL}/my/${blogUuid}/blog-info/${blogInfoId}/`, data)
  },
  getMyBlogByID (id) {
    return apiReq.get(`${BASE_URL}/my/${id}/`)
  },
  updateBlog (id, data) {
    return apiReq.patch(`${BASE_URL}/my/${id}/`, data)
  },
  deleteBlog (id) {
    return apiReq.delete(`${BASE_URL}/my/${id}/`)
  },
  getBlogCategories ({ limit, offset } = {}) {
    return apiReq.get(`${BASE_URL}/categories/`, {
      params: { limit, offset }
    })
  },
  getPostCategories ({ limit, offset } = {}) {
    return apiReq.get(`${BASE_URL}/post/categories/`, {
      params: { limit, offset }
    })
  },
  getBlogPosts ({ blogUuid, limit, offset }) {
    return apiReq.get(`${BASE_URL}/${blogUuid}/post/`, {
      params: { limit, offset }
    })
  },

  getPost ({ blogUuid, postUuid }) {
    return apiReq.get(`${BASE_URL}/${blogUuid}/post/${postUuid}/`)
  },

  createPost (blogUuid, data) {
    return apiReq.post(`${BASE_URL}/${blogUuid}/post/`, data)
  },

  updatePost ({ blogUuid, postUuid }, data) {
    return apiReq.patch(`${BASE_URL}/${blogUuid}/post/${postUuid}/`, data)
  },

  deletePost ({ blogUuid, postUuid }) {
    return apiReq.delete(`${BASE_URL}/${blogUuid}/post/${postUuid}/`)
  },
  savePostImage (postUuid, data) {
    const formData = new FormData()
    for (let key in data) {
      if (data[key] instanceof File) {
        formData.append(key, data[key])
      } else {
        formData.set(key, data[key])
      }
    }
    return apiReq.post(`${BASE_URL}/${postUuid}/image/`, formData)
  },
  editPostImage ({ postUuid, imgId }, data) {
    const formData = new FormData()
    for (let key in data) {
      if (data[key] instanceof File) {
        formData.append(key, data[key])
      } else {
        formData.set(key, data[key])
      }
    }
    return apiReq.patch(`${BASE_URL}/${postUuid}/image/${imgId}/`, formData)
  },
  deletePostImage ({ postUuid, imgId }) {
    return apiReq.delete(`${BASE_URL}/${postUuid}/image/${imgId}/`)
  },
  sendPostView ({ postUuid }) {
    return apiReq.post(`${BASE_URL}/${postUuid}/view/`, {})
  },
  subscribe (blogId) {
    return apiReq.post(`${BASE_URL}/${blogId}/subscribe/`, {})
  },
  unsubscribe (blogId) {
    return apiReq.delete(`${BASE_URL}/${blogId}/subscribe/`)
  },
  getBookmarks ({ limit, offset }) {
    return apiReq.get(`${BASE_URL}/bookmark/`, {
      params: { limit, offset }
    })
  },
  setBookmark (postUuid) {
    return apiReq.post(`${BASE_URL}/bookmark/`, { postUuid })
  },
  deleteBookmark (postUuid) {
    return apiReq.delete(`${BASE_URL}/bookmark/${postUuid}/`)
  },
  savePostVideo (postUuid, data) {
    const formData = new FormData()
    for (let key in data) {
      if (data[key] instanceof File) {
        formData.append(key, data[key])
      } else {
        formData.set(key, data[key])
      }
    }
    return apiReq.post(`${BASE_URL}/${postUuid}/video/`, formData)
  },
  editPostVideo ({ postUuid, videoId }, data) {
    const formData = new FormData()
    for (let key in data) {
      if (data[key] instanceof File) {
        formData.append(key, data[key])
      } else {
        formData.set(key, data[key])
      }
    }
    return apiReq.patch(`${BASE_URL}/${postUuid}/video/${videoId}/`, formData)
  },
  deletePostVideo ({ postUuid, videoId }) {
    return apiReq.delete(`${BASE_URL}/${postUuid}/video/${videoId}/`)
  }
}
