import { cloneDeep, findLast } from 'lodash'
import { chatNamesForBridge, messengerProviders, eventTypes, selfMembershipTypes } from './variables'

export const createLinkImage = (link, w = 50, h = 50, method = 'crop') =>
  link
    ? `${process.env.VUE_APP_MATRIX_URL}/_matrix/media/r0/thumbnail/${link.replace(
      'mxc://',
      ''
    )}?width=${w}&height=${h}&method=${method}`
    : ''
export const createLinkImageFullPath = link =>
  link ? `${process.env.VUE_APP_MATRIX_URL}/_matrix/media/r0/download/${link.replace('mxc://', '')}` : ''

export function checkAndSetWAProvider (state) {
  if (Object.keys(state.members).join(',').includes('@whatsapp')) return messengerProviders.whatsapp
}
export function checkAndSetTGProvider (state) {
  if (Object.keys(state.members).join(',').includes('@telegram')) return messengerProviders.telegram
}

export const getUserFullName = user => {
  const { firstName = '', lastName = '' } = user || {}
  return `${firstName} ${lastName}`.trim()
}

export const getCountUnreadMessages = (chat, user) => {
  if (chat.notificationCounts?.total) return chat.notificationCounts.total
  if (!user) return false
  const message = chat.messages?.[0] ?? chat.message
  if (!message) return false

  const senderId =
    message.senderId ?? message.sender?.userId ?? (typeof message.sender === 'string' ? message.sender : null)
  const isUnread = message.properties?.isRead === false
  return isUnread && senderId && senderId !== user.id
}

export const parseRouterQueryParam = (param, decode = false) => {
  if (!param) return
  try {
    const str = decode ? window.atob(param) : param
    const num = Number(str)
    return Number.isNaN(num) ? str : num
  } catch {}
}

const searchTagA = (el, container) => {
  if (!el || el === container || el.tagName === 'BODY') return null
  if (el.tagName === 'A') return el
  return searchTagA(el.parentElement)
}

export const createListenerForClickOnTagA = container => event => {
  const el = searchTagA(event.target, container)
  if (!el) return
  const url = el.getAttribute('href')
  event.preventDefault()
  window.open(url)
}

export const formattedUserName = name =>
  name.replaceAll('(Telegram)', '').replaceAll('(WA)', '').replaceAll('Empty room (was ', '').replaceAll(' )', '')

export const formattedRoomItem = (room, selfMembership = 'join') => {
  const matrixEventChangeAvatar = room?.currentState?.events.get(eventTypes.avatar)
  let imageKey = matrixEventChangeAvatar?.get('').event.content.url ?? ''
  const imageFullPath = imageKey =>
    imageKey
      ? `${process.env.VUE_APP_MATRIX_URL}/_matrix/media/r0/thumbnail/${imageKey.replace(
        'mxc://',
        ''
      )}?width=60&height=60&method=crop`
      : ''
  const lastTimeline = findLast(
    room.timeline,
    t =>
      t.event.type === eventTypes.message ||
      t.event.type === eventTypes.member ||
      t.event.type === eventTypes.avatar ||
      t.event.type === eventTypes.name ||
      t.event.type === eventTypes.redaction
  )

  const subProvider = checkAndSetWAProvider(room.currentState) || checkAndSetTGProvider(room.currentState)
  const maySendMessage = room.currentState.maySendMessage(room.myUserId)

  if (!imageKey && maySendMessage) {
    Object.values(room.currentState.members).forEach(e => {
      if (e.userId !== room.myUserId) {
        const avatarMxc = e.events.member.event.content.avatar_url
        imageKey = avatarMxc || ''
      }
    })
  }

  let type = room.getDMInviter() ? 'direct' : 'group'
  const allMembers = room.currentState.getMembers()
  if (type === 'group' && allMembers.length <= 2) {
    if (allMembers.some(m => m.getDMInviter())) type = 'direct'
  }

  const formatedRoom = {
    provider: 'matrix',
    subProvider: subProvider || 'default',
    lastMessages:
      selfMembership === selfMembershipTypes.join
        ? lastTimeline && lastTimeline.event
          ? [lastTimeline.event]
          : []
        : [],
    name: room.name,
    id: room.roomId,
    selfMembership: room.selfMembership,
    notificationCounts: room.notificationCounts,
    messages: [],
    isTyping: false,
    receiptCacheByEventId: room.receiptCacheByEventId,
    receipts: room.receipts,
    typingUsers: [],
    participantsUsers: [],
    typeName: 'group',
    typeRoom: type,
    imgLink: imageKey ? imageFullPath(imageKey) : '',
    mxcUrl: imageKey,
    topic: '',
    maySendMessage: maySendMessage,
    memberCount: Object.keys(room.currentState.members).length
  }
  return cloneDeep(formatedRoom)
}

export const dataForCreateBridgeTGRoom = {
  initial_state: [
    {
      type: 'm.room.guest_access',
      state_key: '',
      content: {
        guest_access: 'can_join'
      }
    }
  ],
  name: chatNamesForBridge.telegram,
  type: 'm.room.guest_access',
  invite: [process.env.VUE_APP_TELEGRAM_BRIDGE_BOT],
  is_direct: true,
  preset: 'trusted_private_chat',
  visibility: 'private'
}

export const dataForCreateBridgeWARoom = {
  initial_state: [
    {
      type: 'm.room.guest_access',
      state_key: '',
      content: {
        guest_access: 'can_join'
      }
    }
  ],
  name: chatNamesForBridge.whatsapp,
  type: 'm.room.guest_access',
  invite: [process.env.VUE_APP_WHATSAPP_BRIDGE_BOT],
  is_direct: true,
  preset: 'trusted_private_chat',
  visibility: 'private'
}
