<template>
  <modal
    v-bind="$attrs"
    v-on="$listeners"
    :class="$style.wrapper"
    name="despaxo"
    class="despaxo"
    width="450"
    height="auto"
    adaptive
    scrollable
    transition="modal-fade"
    @before-open="setProps"
    @before-close="reset"
  >
    <h1 :class="$style.title">{{ $t('pleaseAddInfo') }}</h1>
    <BaseIcon custom name="close" @click="$modal.hide('despaxo')" :class="$style.close" />

    <div :class="$style.body">
      <form @submit.prevent="onRegister" :class="$style.form">
        <div class="form-row">
          <span>{{ $t('enterPhone') }}</span>
          <PhoneInput
            ref="telInput"
            style="margin-top: 20px"
            :value="initPhone"
            @on-update="handlePhoneInput($event)"
            @valid="isValidPhone = $event"
          >
          </PhoneInput>
        </div>
        <div class="form-row country-row">
          <span>{{ $t('selectCountryMessage') }}</span>
          <BaseSelect
            :internalSearch="false"
            v-model="payload.country"
            :placeholder="$t('selectCountry')"
            :options="countryList"
            trackBy="id"
            optionLabel="name"
          />
        </div>
        <div class="form-action">
          <BaseButton :loading="loading" :disabled="!payload.phone || !payload.country || !isValidPhone || loading">{{
            $t('buttonConfirm')
          }}</BaseButton>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import PhoneInput from '@/components/PhoneInput'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'despaxo',
  data () {
    return {
      isValidPhone: false,
      actionType: 'create',
      initPhone: null,
      loading: false,
      payload: {
        id: null,
        phone: null,
        country: null
      }
    }
  },
  components: {
    PhoneInput
  },
  computed: {
    ...mapGetters({
      countryList: 'despaxo/countryList',
      contactInfo: 'despaxo/contactInfo'
    })
  },
  created () {
    if (!this.countryList.length) {
      this.fetchCountries()
    }
  },
  methods: {
    ...mapActions({
      fetchCountries: 'despaxo/getDespaxoCountriesList',
      createContactInfo: 'despaxo/createContactInfo',
      updateContactInfo: 'despaxo/updateContactInfo',
      updateUserType: 'despaxo/setDespaxoUserType',
      refreshToken: 'auth/refresh'
    }),
    async handleUpdateUserType (type) {
      try {
        this.loading = true
        await this.updateUserType({ accountTypes: [type] })
        await this.refreshToken()
      } finally {
        this.loading = false
      }
    },
    setProps () {
      this.setData()
    },
    reset () {},
    handlePhoneInput (value) {
      this.payload.phone = value
    },
    setData () {
      if (this.contactInfo) {
        this.actionType = 'edit'
        this.payload.id = this.contactInfo.id
        this.payload.country = this.countryList.find(item => item.id === this.contactInfo.country)
        this.initPhone = parsePhoneNumberFromString(this.contactInfo.phone).nationalNumber
        this.loading = false
        setTimeout(() => {
          this.$refs.telInput.$children[0].choose(
            this.$refs.telInput.$children[0].findCountry(parsePhoneNumberFromString(this.contactInfo.phone).country)
          )
        }, 300)
      }
    },
    resetForm () {
      this.payload.phone = null
      this.payload.country = null
      this.payload.id = null
      this.loading = false
    },
    async onRegister () {
      try {
        if (!this.payload.phone || !this.payload.country) return
        this.loading = true
        if (this.actionType === 'create') {
          await this.createContactInfo({ phone: this.payload.phone, country: this.payload.country.id })
          await this.handleUpdateUserType(101)
        } else {
          await this.updateContactInfo({
            phone: this.payload.phone,
            country: this.payload.country.id,
            id: this.payload.id
          })
        }
        this.$modal.hide('despaxo')
        setTimeout(() => {
          this.$modal.show('success')
        }, 200)
        this.resetForm()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      } finally {
        this.loading = true
      }
    }
  }
}
</script>

<style lang="scss" module>
.wrapper :global(.v--modal) {
  background: $main-bg-light;
  box-shadow: 0 3px 11px 1px rgba(0, 0, 0, 0.15);
  // border: 1px solid $border-color;
  font-weight: 400;
  border-radius: 0;
  padding: 36px 32px;
}
.title {
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  text-align: center;
  color: $black;
}
.close {
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 24px;
  right: 20px;
}
.body {
  padding: 40px;
  text-align: center;
  font-size: 16px;
}
</style>
<style lang="scss">
.despaxo {
  .form-row {
    margin-bottom: 50px;
    & > span {
      text-align: left;
      margin-bottom: 10px;
      width: 100%;
      display: block;
    }
  }
  .form-action {
    margin-top: 50px;
  }
  .multiselect__content-wrapper {
    max-height: 180px !important;
    &::-webkit-scrollbar {
      width: 8px;
      position: absolute;
      background: rgba(17, 17, 17, 0.1);
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      position: absolute;
      z-index: -2;
    }
    &::-webkit-scrollbar-thumb {
      background: #00b764;
      border-radius: 5px;
    }
  }
}
</style>
