import { Model } from '@vuex-orm/core'
import User from './User'

export default class UserStatus extends Model {
  static entity = 'userStatuses'

  static fields () {
    return {
      id: this.number(0),
      userId: this.number(null).nullable(),
      user: this.belongsTo(User, 'userId'),
      mediaType: this.string(''),
      statusText: this.string(''),
      mediaLink: this.string(''),
      title: this.string('')
    }
  }
}
