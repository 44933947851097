import { Model } from '@vuex-orm/core'
import User from './User'
import store from '@/store'
import { SocialProfileMy, SocialProfileLookingFor } from './SocialProfileHierarchy'

export default class SocialProfile extends Model {
  static entity = 'socialProfiles'
  static primaryKey = 'nickname'

  static types () {
    return {
      MY: SocialProfileMy,
      LOOKING_FOR: SocialProfileLookingFor
    }
  }

  static fields () {
    return {
      nickname: this.string(''),
      subscribers: this.number(0),
      owner: this.number(0),
      ownerUser: this.belongsTo(User, 'owner'),
      published: this.string(''),
      updatedAt: this.string(''),
      firstName: this.string(''),
      lastName: this.string(''),
      picture: this.string(''),
      background: this.string('').nullable(),
      category: this.string(''),
      location: this.attr({}),
      textLocation: this.string('').nullable(),
      isPublic: this.boolean(false),
      isActive: this.boolean(false),
      showFollowers: this.boolean(false),
      isChatActive: this.boolean(false)
    }
  }

  get isOwn () {
    return this.owner === store.getters['auth/user'].id
  }
}
