import { Model } from '@vuex-orm/core'
import Chat from './Chat'

export default class ChatSettings extends Model {
  static entity = 'chatSettings'
  static primaryKey = 'chatId'

  static fields () {
    return {
      chatId: this.number(null),
      chat: this.belongsTo(Chat, 'chatId'),
      enableNotifications: this.boolean(true),
      enableTyping: this.boolean(true),
      enableRead: this.boolean(true),
      enableOnline: this.boolean(true)
    }
  }
}
