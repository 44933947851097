<template>
  <div style="padding: 15px; text-align: center;">Loading...</div>
</template>

<script>
import { isAndroid, isMobile } from 'mobile-device-detect'
import { mapMutations } from 'vuex'

const ActionTypes = {
  login: 'login',
  recovery: 'recovery',
  registration: 'registration'
}
export default {
  name: 'open-link',
  methods: {
    ...mapMutations({
      clearAuth: 'auth/CLEAR_AUTH'
    })
  },
  async created () {
    this.clearAuth()
    const id = this.$route.query['magic-id']
    const type = this.$route.query['type']
    if (!id) return
    if (!isMobile) {
      try {
        if (type === ActionTypes.recovery) {
          await this.$store.dispatch('auth/magicRecovery', id)
        } else if (type === ActionTypes.registration) {
          await this.$store.dispatch('auth/registerByMagicId', { id: id })
        } else {
          await this.$store.dispatch('auth/magicLogin', id)
        }
        await this.$router.replace({ name: 'profile' })
      } catch (err) {
      }
    } else if (isAndroid) {
      const url = `${process.env.VUE_APP_APIGW_URL}/auth/user/auth-magic?magic-id=${id}`.replace('https://', '')
      const link = `intent://${url}#Intent;scheme=hola;package=com.xgroup.hola.sayHola;action=android.intent.action.VIEW;end`
      window.open(link)
    }
  }
}
</script>
