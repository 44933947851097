<template>
  <ChatModal :name="$options.name" :title="$t('connectWA')" @before-open="beforeOpen">
    <template v-if="step === 1">
      <template slot="footer">
        <BaseButton oval :loading="loading" @click="activate">{{ $t('buttonConnect') }}</BaseButton>
      </template>
    </template>
    <template v-if="step === 2">
      <div style="text-align: left">
        <h2 style="text-align: center; margin-top: 30px">
          {{ $t('scanQRFromWA') }}
        </h2>
        <div style="text-align: center; min-height: 256px">
          <img :src="qrPath" alt="" v-show="showQR" />
          <p v-show="!!responseText" style="font-size: 16px; text-align: center; margin-top: 10px; margin-bottom: 10px">
            {{ responseText }}
          </p>
        </div>
      </div>
      <template slot="footer">
        <BaseButton v-show="showQR || successLogin" oval :loading="loading" @click="closeDialog">{{
          $t('buttonClose')
        }}</BaseButton>
        <BaseButton v-show="responseText && !successLogin" oval :loading="loading" @click="activate">{{
          $t('buttonConnect')
        }}</BaseButton>
      </template>
    </template>
  </ChatModal>
</template>

<script>
import ChatModal from '@/components/modals/ChatModal'
import { mapActions, mapGetters } from 'vuex'
import { dataForCreateBridgeWARoom } from '@/helpers'
import { msgTypes, eventTypes, chatNamesForBridge, selfMembershipTypes } from '@/helpers/variables'

export default {
  name: 'bridge-whatsapp',
  components: {
    ChatModal
  },
  data () {
    return {
      step: 1,
      loading: false,
      roomId: null,
      qrPath: null,
      showQR: false,
      responseText: '',
      successLogin: false,
      WAConnectingRoom: null
    }
  },
  computed: {
    ...mapGetters({
      matrixSetting: 'matrix/matrixCredentials'
    })
  },
  methods: {
    ...mapActions({
      updateMatrixCredentials: 'matrix/updateMatrixCredentials',
      getMatrixCredentials: 'matrix/getMatrixCredentials'
    }),
    beforeOpen () {
      this.step = 1
      this.loading = false
      this.roomId = null
      this.qrPath = null
      this.WAConnectingRoom = null
      this.showQR = false
      this.responseText = ''
      this.successLogin = false
    },
    async createRoom () {
      const result = await window.matrixSDK.createRoom(dataForCreateBridgeWARoom)
      this.roomId = result.room_id
      return result
    },
    createFileUrl (url) {
      return window.matrixSDK.mxcUrlToHttp(url)
    },
    async sendMessage (msgbody) {
      const content = {
        body: msgbody,
        msgtype: msgTypes.text
      }
      await window.matrixSDK.sendEvent(this.roomId, eventTypes.message, content, '')
    },
    async activate () {
      this.loading = true

      const rooms = window.matrixSDK.getRooms()
      const WARoom = rooms.find(
        e => e.name === chatNamesForBridge.whatsapp && e.selfMembership === selfMembershipTypes.join
      )
      if (WARoom) {
        this.WAConnectingRoom = WARoom
        this.roomId = WARoom.roomId
      } else {
        await this.createRoom()
        this.WAConnectingRoom = window.matrixSDK.getRoom(this.roomId)
      }

      this.WAConnectingRoom = window.matrixSDK.getRoom(this.roomId)
      this.WAConnectingRoom.on('Room.timeline', async event => {
        if (event.event.content.msgtype === msgTypes.image) {
          this.qrPath = this.createFileUrl(event.event.content.url)
          this.showQR = true
        }
        if (event.event.content.msgtype === msgTypes.notice) {
          this.showQR = false
          this.successLogin = event.event.content.body.includes('Successfully logged in as')
          if (this.successLogin) {
            this.responseText = event.event.content.body
            await this.updateMatrixCredentials({
              is_bridge_whatsapp: this.successLogin
            })
            await this.getMatrixCredentials()
          }
        }
      })

      await this.sendMessage('login')
      this.step = 2
      this.loading = false
    },
    closeDialog () {
      this.$modal.hide(this.$options.name)
    }
  }
}
</script>
