<template>
  <BaseModal
    name="confirm-email"
    :icon="['far', 'envelope-open']"
    :title="$t('modalConfirmEmail')"
  >
    <p class="confirm-email__txt">{{ $t('modalEmailLink') }}</p>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'

export default {
  name: 'confirm-email-modal',
  components: {
    BaseModal
  }
}
</script>

<style lang="scss">
.confirm-email {
  &__txt {
    padding-bottom: 90px;
  }
}
</style>
