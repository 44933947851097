import axios from 'axios'
import { get } from 'lodash'
import User from '@/models/User'

const API_KEY = 'TfBFg3vIA56sGwBw1tcABjwYxRTlLJ4R' // 'Ehm$bsnDkB&!$tXylzS7x5p7x@S7p^bqlH!4iOalrsZ$BshjeH'
const API_URL = process.env.VUE_APP_AFFILIATE_URL

const state = {
  status: null,
  data: {},
  summ: {},
  summ_potential: {},
  summ_total: null,
  summ_potential_total: null,
  referral: ''
}

const mutations = {
  SET_STATUS (state, status) {
    state.status = status
  },
  SET_LOGIN_DATA (state, { status, data, referral, referral_id, summ, summ_potential, summ_total, summ_potential_total }) {
    state.status = status
    state.data = data
    state.referral = referral
    state.referralId = referral_id
    state.summ = summ
    state.summ_potential = summ_potential
    state.summ_total = summ_total
    state.summ_potential_total = summ_potential_total
  },
  CLEAR (state) {
    state.status = null
    state.data = {}
    state.sum = {}
    state.summ_potential = {}
    state.referral = ''
  }
}

const getters = {
  isRegistered: state => state.status === 'Login success',
  isFetched: state => state.status != null,
  isError: state => state.status === 'error',
  referralUser: state => User.find(state.referralId),
  count: state => Object.values(state.data).reduce((res, levelData) => res + levelData.length, 0)
}

const actions = {
  async fetch ({ commit, rootGetters, dispatch }) {
    const user = rootGetters['auth/user']
    if (!user) return
    let data
    try {
      const resp = await axios.post(`${API_URL}/login`, {
        phone_number: user.phone
      }, {
        headers: {
          'api-key': API_KEY
        }
      })
      data = resp.data
      commit('SET_LOGIN_DATA', {
        data: data.data,
        status: data.message,
        referral: data.referral,
        referral_id: data.referral_id,
        summ: data.summ,
        summ_potential: data.summ_potential,
        summ_total: data.summ_total,
        summ_potential_total: data.summ_potential_total
      })
    } catch (e) {
      let status = 'error'
      if (e.response && e.response.status < 500) {
        const { message } = get(e, 'response.data', {})
        status = message
      }
      commit('SET_STATUS', status)
    }
    await User.dispatch('getProfiles', [data.referral_id])
  },
  async register ({ rootGetters, dispatch }, { referrerPhone, nickname }) { //
    const { phone } = rootGetters['auth/user']
    if (!phone || !(referrerPhone || nickname)) return
    const data = {
      phone_number: phone
    }
    if (nickname) {
      data.referral_nickname = nickname
    } else {
      data.referral_phone = referrerPhone
    }
    await axios.post(`${API_URL}/register`, data, {
      headers: {
        'api-key': API_KEY
      }
    })
    await dispatch('fetch')
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true
}
