import { Database } from '@vuex-orm/core'
import Blog from '@/models/Blog'
import blogs from './modules/blogs'
import Post from '@/models/Post'
import posts from './modules/posts'
import BlogCategory from '@/models/BlogCategory'
import blogCategories from './modules/blogCategories'
import PostCategory from '@/models/PostCategory'
import postCategories from './modules/postCategories'
import Chat from '@/models/Chat'
import chats from './modules/chats'
import Message from '@/models/Message'
import messages from './modules/messages'
import User from '@/models/User'
import users from './modules/users'
import Package from '@/models/Package'
import packages from './modules/packages'
import TranslatedMessage from '@/models/TranslatedMessage'
import translatedMessages from './modules/translatedMessages'
import Callout from '@/models/Callout'
import callouts from './modules/callouts'
import CalloutProfile from '@/models/CalloutProfile'
import calloutProfiles from './modules/calloutProfiles'
import Participant from '@/models/Participant'
import participants from './modules/participants'
import ChatSettings from '@/models/ChatSettings'
import chatSettings from './modules/chatSettings'
// import SocialProfile from '@/models/SocialProfile'
// import SocialProfileMy from '@/models/SocialProfileMy'
// import SocialProfileLookingFor from '@/models/SocialProfileLookingFor'
import { SocialProfile, SocialProfileMy, SocialProfileLookingFor } from '@/models/SocialProfileHierarchy'
import socialProfiles from './modules/socialProfiles'
import SocialPost from '@/models/SocialPost'
import socialPosts from './modules/socialPosts'
import SocialProfileSubscription from '@/models/SocialProfileSubscription'
import SocialComment from '@/models/SocialComment'
import socialComments from './modules/socialComments'
import userStatuses from './modules/userStatuses'
import UserStatus from '@/models/UserStatus'
import moodMessages from './modules/moodMessages'
import MoodMessage from '@/models/MoodMessage'

const database = new Database()

database.register(Blog, blogs)
database.register(Post, posts)
database.register(BlogCategory, blogCategories)
database.register(PostCategory, postCategories)
database.register(Chat, chats)
database.register(Message, messages)
database.register(User, users)
database.register(Package, packages)
database.register(TranslatedMessage, translatedMessages)
database.register(Callout, callouts)
database.register(CalloutProfile, calloutProfiles)
database.register(Participant, participants)
database.register(ChatSettings, chatSettings)
database.register(SocialProfile, socialProfiles)
database.register(SocialProfileMy)
database.register(SocialProfileLookingFor)
database.register(SocialPost, socialPosts)
database.register(SocialProfileSubscription)
database.register(SocialComment, socialComments)
database.register(UserStatus, userStatuses)
database.register(MoodMessage, moodMessages)

export default database
