import { Model } from '@vuex-orm/core'
import User from './User'
export default class Participant extends Model {
  static entity = 'participants'
  static primaryKey = ['chatId', 'userId']

  static fields () {
    return {
      chatId: this.number(null),
      userId: this.number(null),
      user: this.belongsTo(User, 'userId'),
      isModerator: this.boolean(false)
    }
  }
}
