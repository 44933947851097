import auth from './auth.js'
import matrix from './matrix.js'
import filter from './filter.js'
// import main from './main.js'
import affiliate from './affiliate.js'
import giphy from './giphy.js'
import ws from './ws'
import wallet from './wallet'
import tenor from './tenor'
import agents from './agents'
import despaxo from './despaxo'

export default {
  auth,
  // main,
  affiliate,
  giphy,
  filter,
  matrix,
  ws,
  wallet,
  tenor,
  agents,
  despaxo
}
