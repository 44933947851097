export const EMOJI = {
  attachment: '📎',
  call: '📞',
  contact: '👤',
  file: '📎',
  giphy: '💎',
  image: '🖼️',
  location: '📍',
  sticker: '💎',
  video: '📹',
  voice: '🎤'
}
