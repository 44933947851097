<template>
  <div :class="['form-container', { invalid: invalid }]">
    <template v-if="isLabelArray">
      <div v-for="(l, i) in label" :key="i" class="form--label">{{ l }}</div>
    </template>
    <div v-else-if="label" class="form--label">{{ label }}</div>
    <Multiselect
      v-bind="{
        value,
        placeholder,
        options,
        trackBy,
        label: optionLabel,
        showLabels,
        disabled,
        internalSearch
      }"
      v-on="$listeners"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </Multiselect>
    <div
      v-show="!invalid && helpText"
      class="form--help-text"
    >{{ helpText }}</div>
    <div v-show="invalid" class="form--error-msg">
      <span>{{ error }}</span>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'base-select',
  components: {
    Multiselect
  },
  props: {
    value: {
      type: [String, Object]
    },
    placeholder: String,
    invalid: {
      type: Boolean,
      default: false
    },
    error: {
      type: String
    },
    disabled: Boolean,
    label: {
      type: [String, Array]
    },
    helpText: String,
    options: Array,
    trackBy: {
      type: String,
      default: 'value'
    },
    optionLabel: {
      type: String,
      default: 'label'
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    internalSearch: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isLabelArray () {
      return Array.isArray(this.label)
    }
  }
}
</script>

<style lang="scss">
.invalid .multiselect__tags {
  border-bottom-color: $text-error;
}
</style>
