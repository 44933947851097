import { Model } from '@vuex-orm/core'
import Post from './Post'
import BlogCategory from './BlogCategory'
import User from './User'
import store from '@/store'
import i18n from '@/i18n'

export default class Blog extends Model {
  static entity = 'blogs'
  static primaryKey = 'uuid' // 'shortId'

  static fields () {
    return {
      uuid: this.string(''),
      ownerId: this.number(0),
      owner: this.belongsTo(User, 'ownerId'),
      published: this.string(null),
      updatedAt: this.string(null),
      isFree: this.boolean(true),
      monthlyFee: this.number(0),
      annualFee: this.number(0),
      isAdult: this.boolean(false),
      isPrivate: this.boolean(false),
      isHideOwner: this.boolean(false),
      isSubscribed: this.boolean(false),
      categoryId: this.number(null),
      category: this.belongsTo(BlogCategory, 'categoryId'),
      shortId: this.string(null),
      subscribers: this.number(0),
      posts: this.hasMany(Post, 'blogUuid'),
      lastPostPublication: this.string(null).nullable(),
      blogInfo: this.attr([]),
      defaultLanguage: this.string('en'),
      isService: this.boolean(false)
    }
  }

  get isOwn () {
    return this.ownerId === store.getters['auth/user'].id
  }

  get blogInfoDisplay () {
    return this.blogInfo.find(obj => obj.language === i18n.locale) ||
      this.blogInfo.find(obj => obj.language === this.defaultLanguage) ||
      this.blogInfo[0] || {}
  }
}
