import api from '@/api'
import SocialComment from '@/models/SocialComment'
import { uniq } from 'lodash'

const getters = {
  getComment: _ => id => SocialComment.query().whereId(id).with('userObj').first(),
  getPostComments: _ => postId => {
    return SocialComment
      .query()
      .where('postId', postId)
      .where('replyTo', null)
      .with('userObj')
      .orderBy('createdAtTs', 'asc') // desc
      .get()
  },
  getCommentReplies: _ => commentId =>
    SocialComment
      .query()
      .where('replyTo', commentId)
      .with('userObj')
      .orderBy('createdAtTs', 'asc') // desc
      .get(),
  getCommentRepliesCount: _ => commentId =>
    SocialComment
      .query()
      .where('replyTo', commentId)
      .count()
}

const actions = {
  async fetchPostComments ({ dispatch }, { postId, limit, offset }) {
    const { results, next } = await api.social.getPostComments(postId, { limit, offset })
    const data = results.map(comment => ({
      ...comment,
      postId
    }))
    SocialComment.insert({ data })
    const userIds = uniq(data.map(comment => comment.user))
    dispatch('entities/users/getProfiles', userIds, { root: true })
    return {
      isNext: !!next,
      data
    }
  },
  async fetchReplies (_, { postId, commentId }) {
    const { results } = await api.social.getCommentReplies(postId, commentId)
    const data = results.map(comment => ({
      ...comment,
      replyTo: commentId,
      postId
    }))
    SocialComment.insert({ data })
  },
  async sendComment (_, { postId, data }) {
    let comment = await api.social.createComment(postId, data)
    if (data.replyTo) {
      const repliedComment = SocialComment.find(data.replyTo)
      repliedComment.$update({
        repliesCount: repliedComment.repliesCount + 1
      })
      comment = {
        ...comment,
        replyTo: data.replyTo
      }
    }
    SocialComment.insert({
      data: {
        ...comment,
        postId
      }
    })
  },
  async editComment (_, { id, text }) {
    const { postId } = SocialComment.find(id)
    const comment = await api.social.editComment(postId, id, { text })
    SocialComment.insertOrUpdate({
      data: comment
    })
  },
  async deleteComment (_, id) {
    const comment = SocialComment.find(id)
    await api.social.deleteComment(comment.postId, id)
    comment.$delete()
  }
}

export default {
  getters,
  actions
}
