<template>
  <modal name="change-phone"
         class="change-phone"
         height="430px"
         transition="modal-fade">
    <div class="change-phone__wrap">
      <div class="change-phone__close" @click.prevent="closePopup">
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times-circle" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
             class="svg-inline--fa fa-times-circle fa-w-16 fa-2x">
          <path fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z"
                class=""></path>
        </svg>
      </div>
      <div class="change-phone__title">
        {{ $t('changePhoneDesc') }}
      </div>
      <div class="step-1">
        <form action="" class="enter-phone" @submit.prevent="onSubmit">
          <PhoneInput
            :errors="serverErrors.length && serverErrors"
            @on-update="handlePhoneInput($event)"
            @valid="isValidPhone = $event">
          </PhoneInput>
          <BaseButton :loading="loading" :disabled="!isValidPhone"> {{ $t('changeAccountPhoneButton') }} </BaseButton>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex'
import PhoneInput from '@/components/PhoneInput'
import { chatNamesForBridge, eventTypes, msgTypes, selfMembershipTypes } from '@/helpers/variables'

export default {
  name: 'change-phone',
  components: {
    PhoneInput
  },
  data () {
    return {
      serverErrors: [],
      isValidPhone: false,
      phoneInput: '',
      error: '',
      loading: false,
      errors: {}
    }
  },
  methods: {
    ...mapActions({
      getMyProfile: 'entities/users/getMyProfile',
      updatePhone: 'auth/updatePhone',
      updateMatrixCredentials: 'matrix/updateMatrixCredentials',
      getMatrixCredentials: 'matrix/getMatrixCredentials'
    }),
    handlePhoneInput (phone) {
      this.phoneInput = phone
      this.serverErrors = []
    },
    async onSubmit () {
      this.errors = {}
      if (this.loading || !this.isValidPhone) return
      this.loading = true
      this.serverErrors = []
      try {
        await this.updatePhone({ phone: this.phoneInput })
        await this.handleDisconnectMessenger()
        await this.getMyProfile()
        this.closePopup()
      } catch (error) {
        if (error.response.data) {
          const { type } = error.response.data.data || {}
          const errorStr = type ? this.$t(`errorPhoneInvalid_${type}`) : this.$t('errorPhoneInvalid')
          this.serverErrors.push(errorStr)
        }
      } finally {
        this.loading = false
      }
    },
    closePopup () {
      this.$modal.hide('change-phone')
    },
    async handleDisconnectMessenger () {
      await this.sendLogoutMessageRoom(chatNamesForBridge.telegram)
      await this.sendLogoutMessageRoom(chatNamesForBridge.whatsapp)
      const payload = {
        is_bridge_whatsapp: false,
        whatsapp_phone: '',
        is_bridge_telegram: false,
        telegram_phone: ''
      }

      await this.updateMatrixCredentials(payload)
      await this.getMatrixCredentials()
    },
    async sendLogoutMessageRoom (chatName) {
      const rooms = window.matrixSDK.getRooms()
      const providerConnectingRoom = rooms.find(e => e.name === chatName && e.selfMembership === selfMembershipTypes.join)
      const content = {
        body: 'logout',
        msgtype: msgTypes.text
      }
      if (providerConnectingRoom) {
        await window.matrixSDK.sendEvent(providerConnectingRoom.roomId, eventTypes.message, content, '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.change-phone {
  .v--modal-box{
    @include respondTo(650px){
      width: 95%;
      max-width: 95%;
    }
  }
  &__close{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    svg{
      width: 100%;
    }
  }
  &__wrap {
    padding: 40px;
    position: relative;
    @include respondTo(600px){
      padding: 35px;
    }
    .step-1 form {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      max-width: 350px;
      margin: 0 auto;
    }
    input{
      width: 100%;
      height: 40px;
    }
    button{
      margin-top: 20px;
      width: 100%;
    }
  }
  &__title {
    color: #3b3b3b;
    font-size: 16px;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 30px;
    @include respondTo(600px){
      font-size: 14px;
    }
  }
  &__info{
    p:last-child{
      cursor: pointer;
      font-weight: bold;
      margin-top: 15px;
      text-align: center;
    }
  }
  .resend-message{
    margin-top: 10px;
    text-align: center;
  }
}
</style>
