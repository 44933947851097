<template>
  <SecondaryModal
    :name="$options.name"
    :clickToClose="false"
    @before-open="beforeOpen"
    @before-close="beforeClose"
    class="share-contact-by-code"
  >
    <div class="share-contact-by-code__container">
      <div v-if="loading" class="share-contact-by-code__loader">
        <Loader />
      </div>
      <template v-else>
        <img v-if="userInfo" :src="userInfo.avatarLink" alt="avatar" class="share-contact-by-code__avatar" />
        <!--  -->
        <qrcode-vue v-if="url" :size="150" :value="url" level="H" class="share-contact-by-code__code"></qrcode-vue>
        <a v-if="url" :href="url" target="_blank" class="share-contact-by-code__shared-link">{{ url }}</a>
        <div v-if="permissionError" class="share-contact-by-code__error">
          {{ $t('shareContactByCode.permissionError') }}
        </div>
        <div v-if="expiredError" class="share-contact-by-code__error">
          {{ $t('shareContactByCode.expiredError') }}
        </div>
        <BaseButton v-if="permissionError || expiredError" @click="closeModal">{{ $t('buttonClose') }}</BaseButton>
      </template>
    </div>
  </SecondaryModal>
</template>

<script>
import Loader from '@/components/Spinner'
import QrcodeVue from 'qrcode.vue'
import SecondaryModal from './SecondaryModal'
import api from '@/api'

export default {
  name: 'share-contact-by-code',
  components: {
    Loader,
    QrcodeVue,
    SecondaryModal
  },
  data () {
    return {
      userInfo: null,
      url: null,
      loading: false,
      permissionError: false,
      expiredError: false,
      error: false
    }
  },
  mounted () {
    window.addEventListener('keydown', this.escapeHandler, true)
  },
  beforeDestroy () {
    window.addEventListener('keydown', this.escapeHandler, true)
  },
  methods: {
    beforeOpen ({ params = {} }) {
      const { nickname, userId } = params
      this.fetchData(nickname, userId)
    },
    fetchData (nickname, userId) {
      this.loading = true

      const userInfo = api.user
        .userInfo(nickname)
        .then(({ data }) => (this.userInfo = data))
        .catch(this.checkError)

      const sharedLink = api.user
        .generateQRCode(userId)
        .then(({ data }) => (this.url = data.url))
        .catch(this.checkError)

      Promise.allSettled([userInfo, sharedLink]).finally(() => {
        this.loading = false
        this.error && this.closeModal()
      })
    },
    checkError (error) {
      if (error.isAxiosError && error.response.status === 403) {
        const { errorType, data } = error.response.data
        this.permissionError ||= errorType === 'access' && data?.type === 'permission'
        this.expiredError ||= errorType === 'access' && data?.type === 'expired'
      }
      if (!this.permissionError && !this.permissionError) {
        // eslint-disable-next-line no-console
        console.error(error)
        this.error = true
      }
    },
    escapeHandler (event) {
      if (event.key === 'Escape') {
        event.stopPropagation()
        this.closeModal()
      }
    },
    closeModal () {
      this.beforeClose()
      this.$modal.hide(this.$options.name)
    },
    beforeClose () {
      this.userInfo = null
      this.url = null
      this.permissionError = false
      this.expiredError = false
      this.error = false
    }
  }
}
</script>

<style lang="scss" scoped>
.share-contact-by-code {
  &__code {
    canvas {
      user-select: none;
      margin: 15px auto;
    }
  }
  &__container {
    position: relative;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  &__loader {
    flex: 1;
    display: flex;
  }
  &__error {
    flex: 1;
    display: flex;
    align-items: center;
  }
  &__avatar {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
  &__label {
    padding: 0 15px;
    text-align: center;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
    color: #3b3b3b;
    margin: 10px auto;
    max-width: 360px;
    user-select: none;
    @include respondTo(600px) {
      font-size: 14px;
    }
  }

  .vm--overlay {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
  }
  .vm--modal {
    @include respondTo(500px) {
      max-width: 95%;
      margin: 0 auto;
      padding: 15px 0;
      left: auto !important;
    }
  }
}
</style>
