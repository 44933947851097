import { Model } from '@vuex-orm/core'
import uuid from 'uuid'

export const LANGUAGES = {
  'Afrikaans': 'af',
  'Albanian': 'sq',
  'Amharic': 'am',
  'Arabic': 'ar',
  'Azerbaijani': 'az',
  'Bengali': 'bn',
  'Bosnian': 'bs',
  'Bulgarian': 'bg',
  'Chinese (Simplified)': 'zh',
  'Chinese (Traditional)': 'zh-TW',
  'Croatian': 'hr',
  'Czech': 'cs',
  'Danish': 'da',
  'Dari': 'fa-AF',
  'Dutch': 'nl',
  'English': 'en',
  'Estonian': 'et',
  'Finnish': 'fi',
  'French': 'fr',
  'French (Canada)': 'fr-CA',
  'Georgian': 'ka',
  'German': 'de',
  'Greek': 'el',
  'Hausa': 'ha',
  'Hebrew': 'he',
  'Hindi': 'hi',
  'Hungarian': 'hu',
  'Indonesian': 'id',
  'Italian': 'it',
  'Japanese': 'ja',
  'Korean': 'ko',
  'Latvian': 'lv',
  'Malay': 'ms',
  'Norwegian': 'no',
  'Persian': 'fa',
  'Pashto': 'ps',
  'Polish': 'pl',
  'Portuguese': 'pt',
  'Romanian': 'ro',
  'Russian': 'ru',
  'Serbian': 'sr',
  'Slovak': 'sk',
  'Slovenian': 'sl',
  'Somali': 'so',
  'Spanish': 'es',
  'Spanish (Mexico)': 'es-MX',
  'Swahili': 'sw',
  'Swedish': 'sv',
  'Tagalog': 'tl',
  'Tamil': 'ta',
  'Thai': 'th',
  'Turkish': 'tr',
  'Ukrainian': 'uk',
  'Urdu': 'ur',
  'Vietnamese': 'vi'
}

export default class TranslatedMessage extends Model {
  static entity = 'translatedMessages'

  static fields () {
    return {
      id: this.uid(() => uuid.v4()),
      messageId: this.number(0),
      sourceLang: this.string(''),
      sourceText: this.string(''),
      targetLang: this.string(''),
      targetText: this.string('')
    }
  }
}
