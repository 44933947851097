import chats from './chats'
import user from './user'
import giphy from './giphy'
import blogs from './blogs'
import auth from './auth'
import matrix from './matrix'
import payment from './payment'
import translate from './translate'
import push from './push'
import media from './media'
import wallet from './wallet'
import social from './social'
import callouts from './callouts'
import importTool from './importTool'
import location from './location'
import tenor from './tenor'
import agents from './agents'
import despaxo from './despaxo'

export default {
  auth,
  chats,
  user,
  giphy,
  blogs,
  payment,
  translate,
  push,
  matrix,
  media,
  wallet,
  social,
  callouts,
  importTool,
  location,
  tenor,
  agents,
  despaxo
}
