<template>
  <SecondaryModal
    :name="$options.name"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="set-offer">
      <form action="" class="set-offer__form" @submit.prevent="setOfferRequest">
        <div class="set-offer__title">{{ $t('enterUsernameOrEmail') }}</div>
        <div class="set-offer__option">
          <div class="set-offer__option-item">
            <Radio value="nickname" name="nickname" class="radio" @input="resetValidation" v-model="radioOption">{{ $t('formNickName') }}</Radio>
          </div>
          <div class="set-offer__option-item">
            <Radio value="email" name="email" class="radio" @input="resetValidation" v-model="radioOption">{{ $t('email') }}</Radio>
          </div>
        </div>
        <div class="form-row">
          <BaseInput
            v-if="radioOption === 'nickname'"
            v-model="payload.nickname"
            @input="resetErrors"
            :invalid="$v.payload.nickname.$error"
            :error="nicknameError"
          />
          <BaseInput
            v-else
            v-model="payload.email"
            @input="resetErrors"
            :invalid="$v.payload.email.$error"
            :error="emailError"
          />
        </div>
        <p v-if="error" class="form--error">{{ error }}</p>
      </form>
    </div>
    <template v-slot:footer>
      <BaseButton oval secondary @click="$modal.hide($options.name)">{{ $t('buttonCancel') }}</BaseButton>
      <BaseButton oval :loading="loading" @click="setOfferRequest">{{ $t('set') }}</BaseButton>
    </template>
  </SecondaryModal>
</template>

<script>
import SecondaryModal from './SecondaryModal'
import { mapActions } from 'vuex'
import { email, helpers, required } from 'vuelidate/lib/validators'
import { Radio } from 'vue-checkbox-radio'
const alphaNumHyphenUnderscoreValidator = helpers.regex('alphaNumAndDot', /^[-a-zA-Z0-9_]+$/i)

export default {
  name: 'set-bdtl-offer',
  components: {
    SecondaryModal,
    Radio
  },
  data () {
    return {
      payload: {
        nickname: null,
        email: null
      },
      loading: false,
      item: null,
      radioOption: 'nickname',
      error: null
    }
  },
  validations () {
    if (this.radioOption === 'nickname') {
      return {
        payload: {
          nickname: {
            required,
            alphaNumHyphenUnderscoreValidator,
            uniq () {
              return !this.errorUniqNickname
            }
          }
        }
      }
    } else {
      return {
        payload: {
          email: {
            required,
            email
          }
        }
      }
    }
  },
  computed: {
    emailError () {
      let error = this.$t('errorEmptyField')
      if (!this.$v.payload.email.email) error = this.$t('errorEmailInvalid')
      return error
    },
    nicknameError () {
      if (!this.$v.payload.nickname.required) {
        return this.$t('errorEmptyField')
      } else if (!this.$v.payload.nickname.alphaNumHyphenUnderscoreValidator) {
        return this.$t('errorAlphanumericDashUnderscore')
      }
      return this.$t('errorEmptyField')
    }
  },
  methods: {
    ...mapActions({
      setBDTLOffer: 'agents/setBDTLOffer',
      fetchAgentOffers: 'agents/fetchAgentOffers'
    }),
    beforeOpen ({ params: { item } }) {
      this.item = item
    },
    resetValidation () {
      this.$v.$reset()
      this.payload.nickname = null
      this.payload.email = null
    },
    resetErrors () {
      this.error = null
    },
    beforeClose () {
      this.error = null
      this.item = null
      this.payload.nickname = null
      this.payload.email = null
      this.loading = false
      this.$v.$reset()
    },
    async setOfferRequest () {
      this.$v.$touch()
      if (this.loading || this.$v.$invalid) return
      try {
        this.loading = true
        const data = {}
        data.id = this.item.id
        if (this.radioOption === 'nickname') {
          data.nickname = this.payload.nickname
        } else {
          data.email = this.payload.email
        }
        await this.setBDTLOffer(data)
        await this.fetchAgentOffers()
        this.$modal.hide('set-bdtl-offer')
        setTimeout(() => {
          this.$modal.show('success')
        }, 200)
      } catch (e) {
        if (e.data.data && e.data.data.userIds) {
          this.error = e.data.data.userIds
        }
        if (e.data && e.data.paid) {
          this.error = this.$t('needPayMessage')
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.set-offer {
  &__option {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    &-item {
      width: 50%;
      text-align: center;
      font-weight: 500;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
