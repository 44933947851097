import { Model } from '@vuex-orm/core'
import Chat from './Chat'
import User from './User'
import { invert } from 'lodash'
import store from '@/store'
import TranslatedMessage from '@/models/TranslatedMessage'

export const TYPE_CODE = {
  'service': 0,
  'text': 1,
  'attachment': 2,
  'giphy': 3,
  'voice': 4,
  'video': 5,
  'call': 6,
  'blog': 7,
  'contact': 8,
  'photo': 9,
  'forward': 10
}
export const TYPE_NAME = invert(TYPE_CODE)

export const REASON_CODE = {
  createGroupChat: 0,
  removeGroupChat: 1,
  addNewMember: 2,
  removeMember: 3,
  joinToChat: 4,
  leftFromGroup: 5
}
export const REASON_NAME = invert(REASON_CODE)

export const ATTACHMENT_CODE = {
  'file': 1,
  'audio': 2,
  'video': 3,
  'image': 4
}
export const ATTACHMENT_NAME = invert(ATTACHMENT_CODE)

export default class Message extends Model {
  static entity = 'messages'

  static fields () {
    return {
      id: this.number(null),
      type: this.number(null),
      senderId: this.number(null),
      sender: this.belongsTo(User, 'senderId'),
      payload: this.attr(null).nullable(),
      chatId: this.number(null),
      chat: this.belongsTo(Chat, 'chatId'),
      properties: this.attr({}),
      created: this.attr(() => new Date().toISOString().slice(0, -1)),
      attachment: this.attr(null).nullable(),
      isPending: this.boolean(false),
      translatedMessage: this.hasOne(TranslatedMessage, 'messageId')
    }
  }

  get typeName () {
    return TYPE_NAME[this.type]
  }
  get createdTs () {
    return +new Date(this.created + 'Z')
  }
  get isMy () {
    return this.senderId === store.getters['auth/user'].id
  }
  get attachmentTypeName () {
    if (!this.typeName === 'attachment' || !this.attachment) return
    return ATTACHMENT_NAME[this.attachment.type]
  }
}
