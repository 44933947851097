import Package from '@/models/Package'
import api from '@/api'
import i18n from '@/i18n'
import { createToken } from 'vue-stripe-elements-plus'

const actions = {
  async fetch () {
    const language = i18n.locale
    const { data } = await api.payment.getPackageList(language)
    Package.insertOrUpdate({ data })
  },
  async buy ({ rootGetters, commit }, { name, packageId }) {
    let stripeResp
    if (name) {
      stripeResp = await createToken({ name })
    }
    const params = {
      packageId
    }
    if (stripeResp) {
      const { token: { id } } = stripeResp
      params.chargeToken = id
    } else {
      params.chargeToken = 'free'
    }
    const { data } = await api.payment.buyPackage(params)
    const authUser = rootGetters['auth/user']
    const user = {
      ...authUser,
      package: data.package,
      packageExpires: data.packageExpires
    }
    commit('auth/SET_USER', user, { root: true })
  }
}

export default {
  actions
}
