import { render, staticRenderFns } from "./Logout.vue?vue&type=template&id=40168236"
import script from "./Logout.vue?vue&type=script&lang=js"
export * from "./Logout.vue?vue&type=script&lang=js"
import style0 from "./Logout.vue?vue&type=style&index=0&id=40168236&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports