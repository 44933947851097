import WebSocketAsPromised from 'websocket-as-promised'
import Vue from 'vue'
import camelcaseKeysDeep from 'camelcase-keys-deep'
import decamelizeKeysDeep from 'decamelize-keys-deep'
import eventEmitter from 'event-emitter'
import chatsApi from './chats'
import store from '@/store'

const API = process.env.VUE_APP_WEBSOCKET
const VERSION = '2.0'
const RECONNECTION_DELAY = 5000
const MAX_RECONNECTION_ATTEMPTS = 10

let reconnectionTimer = null
let reconnectionAttempt = 0

const packMessage = data => JSON.stringify(decamelizeKeysDeep(data))
const unpackMessage = data => camelcaseKeysDeep(JSON.parse(data))
const attachRequestId = (data, requestId) => Object.assign({ id: requestId }, data)
const extractRequestId = data => data && data.id

const emitter = eventEmitter()

const Ws = {
  wsp: null,

  on: (e, data) => emitter.on(e, data),

  async connect () {
    if (!store.getters['auth/isLogged']) {
      this.clearReconnection()
      return
    }
    const { data: { token } } = await chatsApi.getToken()
    const wsp = new WebSocketAsPromised(`${API}?token=${token}`, {
      packMessage,
      unpackMessage,
      attachRequestId,
      extractRequestId
    })
    wsp.onOpen.addListener(() => {
      store.commit('ws/SET_OPENED', true)
      this.clearReconnection()
    })
    wsp.onClose.addListener((event) => {
      store.commit('ws/SET_OPENED', false)
      store.commit('ws/SET_CLOSED', true)
      if (store.getters.isOnline) {
        this.reconnect()
      }
    })
    wsp.onUnpackedMessage.addListener(message => {
      const { status, model, action, data } = message
      if (status === 'event') {
        emitter.emit(`${model}.${action}`, data)
      }
    })
    // wsp.onError.addListener(event => console.error(event))
    this.wsp = wsp
    try {
      await wsp.open()
    } catch (e) {
      // console.log('e.message', e.message)
      if (e.message === 'Error during WebSocket handshake: Unexpected response code: 502') {
        this.clearReconnection()
      }
    }
  },
  disconnect () {
    if (!this.wsp || !this.wsp.isOpened) return
    this.wsp.close()
  },
  reconnect () {
    if (!this.wsp || this.wsp.isOpened) return
    reconnectionAttempt++
    if (reconnectionAttempt > MAX_RECONNECTION_ATTEMPTS) return
    const delay = RECONNECTION_DELAY * (Math.random() + 1)
    const reconnectionFn = async () => {
      try {
        await this.connect()
      } catch (e) {
        this.reconnect()
      }
    }
    reconnectionTimer = setTimeout(reconnectionFn, delay)
  },
  clearReconnection () {
    clearTimeout(reconnectionTimer)
    reconnectionAttempt = 0
  },
  async req ({ version = VERSION, id = Date.now(), ...params }) {
    if (!this.wsp) {
      throw new Error('No connection')
    }
    const response = await this.wsp.sendRequest({ version, ...params }, { requestId: id })
    const { status, data, errorType } = response
    if (status !== 'error') {
      return response
    }
    const error = new Error(`Ws request error: ${errorType} - ${JSON.stringify(data)}`)
    error.type = errorType
    error.data = data
    return Promise.reject(error)
  }
}

window.addEventListener('online', () => {
  if (!store.getters['auth/isLogged']) return
  Ws.clearReconnection()
  try {
    Ws.connect()
  } catch (e) {
    Ws.reconnect()
  }
})
window.addEventListener('offline', () => {
  Ws.clearReconnection()
})

Vue.prototype.$ws = Ws

export default Ws
