<template>
  <label class="form--file">
    <slot />
    <span v-if="displayLabel" class="form--file-label">{{ displayLabel }}</span>
    <input
      ref="input"
      :accept="accept"
      :multiple="multiple"
      type="file"
      class="form--file-input"
      @change="handleFileChange"
    >
  </label>
</template>

<script>
export default {
  name: 'file-input',
  props: {
    value: [File, FileList],
    label: String,
    accept: String,
    multiple: Boolean,
    withName: Boolean
  },
  computed: {
    fileName () {
      if (this.withName && this.value) {
        return this.value.name
      }
      return null
    },
    displayLabel () {
      return this.fileName ? this.fileName : this.label
    }
  },
  methods: {
    handleFileChange (e) {
      const files = e.target.files
      const value = this.multiple ? files : files[0]
      this.$emit('input', value)
    },
    clear () {
      this.$refs.input.value = null
    }
  }
}
</script>

<style lang="scss">
.form--file {
  cursor: pointer;
  &-label {
    font-weight: 600;
    text-decoration: underline;
    color: $text-colored;
  }
  &-input {
    display: none;
  }
}
</style>
