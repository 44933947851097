<template>
  <SecondaryModal
    :name="$options.name"
    class="delete-phone"
    :width="900"
  >
    <div class="delete-phone__message">
      {{ $t('emailAlreadyRegisterMessage') }}
    </div>
    <template v-slot:footer>
      <div class="action-button">
        <BaseButton oval green @click="changeInfoPopup" :loading="changeInfoPopupLoading">
          {{ $t('registerAccount') }}
        </BaseButton>
        <BaseButton oval green :loading="loading" @click="changeDeletePopup">
          {{ $t('changeExistingAccount') }}
        </BaseButton>
      </div>
    </template>
  </SecondaryModal>
</template>

<script>
import SecondaryModal from './SecondaryModal'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'delete-phone',
  components: {
    SecondaryModal
  },
  data () {
    return {
      loading: false,
      postId: null,
      changeInfoPopupLoading: false
    }
  },
  computed: {
    ...mapState('auth', ['deviceId']),
    ...mapGetters({
      user: 'auth/user',
      otp: 'auth/otp'
    })
  },
  methods: {
    ...mapActions({
      deletePhone: 'entities/users/deletePhone'
    }),
    changeInfoPopup () {
      this.changeInfoPopupLoading = true
      this.$modal.hide('delete-phone')
      setTimeout(() => {
        this.$modal.show('info', {
          text: this.$t('useSameEmailWarning')
        })
        this.changeInfoPopupLoading = false
      }, 200)
    },
    changeDeletePopup () {
      this.$modal.hide('delete-phone')
      setTimeout(() => {
        let obj = {
          device: [
            {
              id: this.deviceId,
              type: 'web'
            }
          ],
          phone: this.user.phone
        }
        if (this.otp) {
          obj.otp = this.otp
        }
        this.$modal.show('confirm', {
          content: this.$t('deletePhoneMessage'),
          onSubmit: () => {
            this.deleteRequest(obj)
          }
        })
      }, 300)
    },
    async deleteRequest (obj) {
      this.loading = true
      try {
        await this.deletePhone(obj)
      } catch (e) {
        if (e.response.data.data.type === 'otp_unconfirmed') {
          this.$modal.show('resend-otp')
        } else {
          this.$modal.show('error', {
            error: e.response.data
          })
        }
      } finally {
        this.loading = false
        this.$modal.hide(this.$options.name)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.action-button{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .form--button{
    width: 200px;
    padding: 10px;
    height: auto;
    font-size: 12px;
    line-height: 1.2;
  }
  .form--button + .form--button {
    margin-left: 10px;
  }
}
.delete-phone{
  z-index: 99999;
  &__message{
    text-align: center;
    margin: 50px auto;
    font-size: 16px;
    line-height: 1.2;
    max-width: 500px;
    font-weight: 500;
  }
}
</style>
