import api from '@/api'
import SocialProfile from '@/models/SocialProfile'
import SocialProfileMy from '@/models/SocialProfileMy'
import SocialProfileLookingFor from '@/models/SocialProfileLookingFor'
import SocialProfileSubscription from '@/models/SocialProfileSubscription'
import { partition } from 'lodash'

const getters = {
  myProfiles: (state, getters, rootState, rootGetters) => {
    return SocialProfile.query().where('type', 'MY').orWhere('subscribeStatus', 1).get()
  },
  getProfile: () => nickname => SocialProfile.query().whereId(nickname).first(),
  getFollowers: () => nickname =>
    SocialProfileSubscription
      .query()
      .where('profileNickname', nickname)
      .where('status', 1)
      .with('userObj')
      .get(),
  getPending: () => nickname =>
    SocialProfileSubscription
      .query()
      .where('profileNickname', nickname)
      .where('status', 3)
      .with('userObj')
      .get()
}

const actions = {
  async fetchAll ({ rootGetters }) {
    const { results } = await api.social.getProfiles()
    const myUserId = rootGetters['auth/user'].id
    const [myProfiles, otherProfiles] = partition(results, profile => profile.owner === myUserId)
    SocialProfileMy.insertOrUpdate({ data: myProfiles })
    SocialProfileLookingFor.insertOrUpdate({ data: otherProfiles })
  },
  async fetchMyProfiles (_, { limit, offset }) {
    const { results } = await api.social.getMyProfiles({ limit, offset })
    SocialProfileMy.insertOrUpdate({ data: results })
  },
  async createProfile (_, data) {
    const formData = new FormData()
    for (let key in data) {
      if (data[key] instanceof File) {
        formData.append(key, data[key])
      } else {
        formData.set(key, data[key])
      }
    }
    const createdProfile = await api.social.createProfile(formData)
    SocialProfileMy.insertOrUpdate({ data: createdProfile })
  },
  async updateProfile (_, { nickname, data }) {
    const formData = new FormData()
    for (let key in data) {
      if (data[key] instanceof File) {
        formData.append(key, data[key])
      } else {
        formData.set(key, data[key])
      }
    }
    const createdProfile = await api.social.editProfile(nickname, formData)
    SocialProfileMy.insertOrUpdate({ data: createdProfile })
  },
  async fetchMyProfile (_, nickname) {
    const profile = await api.social.getMyProfile(nickname)
    SocialProfileMy.insertOrUpdate({ data: profile })
  },
  async fetchProfile ({ rootGetters, dispatch }, nickname) {
    const profile = await api.social.getProfile(nickname)
    if (profile.owner !== rootGetters['auth/user'].id) {
      SocialProfileLookingFor.insertOrUpdate({ data: profile })
      return
    }
    SocialProfileMy.insertOrUpdate({ data: profile })
  },
  async subscribe (_, nickname) {
    await api.social.subscribe(nickname)
    SocialProfile.update({
      where: nickname,
      data: {
        subscribeStatus: 3
      }
    })
  },
  async unsubscribe (_, nickname) {
    await api.social.unsubscribe(nickname)
    SocialProfile.update({
      where: nickname,
      data: {
        subscribeStatus: null
      }
    })
  },
  async fetchSubscribers ({ dispatch }, profileNickname) {
    await api.social.getSubscribers(profileNickname)
    const { results } = await api.social.getSubscribers(profileNickname)
    const data = results.map(subscription => ({
      ...subscription,
      profileNickname
    }))
    const userIds = results.map(subscription => subscription.user)
    dispatch('entities/users/getProfiles', userIds, { root: true })
    SocialProfileSubscription.insert({ data })
  },
  async manageSubscription (_, { subscriptionId, type = 'accept' }) {
    const status = type === 'decline' ? 2 : 1
    const subscription = SocialProfileSubscription.find(subscriptionId)
    await api.social.manageSubscriptions(subscription.profileNickname, subscriptionId, { status })
    subscription.$update({
      status
    })
    const profile = SocialProfile.find(subscription.profileNickname)
    const profileUpdate = {
      pending: profile.pending ? profile.pending - 1 : 0
    }
    if (type === 'accept') {
      profileUpdate.subscribers = profile.subscribers + 1
    }
    profile.$update(profileUpdate)
  },
  async checkUsername (_, nickname) {
    try {
      const response = await api.social.checkUsername(nickname)
      return response
    } catch (e) {
      throw e
    }
  }
}

export default {
  getters,
  actions
}
