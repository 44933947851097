export const isMobile = {
  Android: function () {
    return !!navigator.userAgent.match(/Android/i)
  },
  BlackBerry: function () {
    return !!navigator.userAgent.match(/BlackBerry/i)
  },
  iOS: function () {
    return !!navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  Windows: function () {
    return !!navigator.userAgent.match(/IEMobile/i)
  },
  any: function () {
    return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows()
  }
}

export const msgTypes = {
  image: 'm.image',
  video: 'm.video',
  sticker: 'm.sticker',
  text: 'm.text',
  file: 'm.file',
  replace: 'm.replace',
  location: 'm.location',
  notice: 'm.notice',
  gif: 'm.gif',
  read: 'm.read',
  audio: 'm.audio'
}

export const eventTypes = {
  message: 'm.room.message',
  member: 'm.room.member',
  avatar: 'm.room.avatar',
  redaction: 'm.room.redaction',
  create: 'm.room.create',
  topic: 'm.room.topic',
  name: 'm.room.name'
}

export const chatNamesForBridge = {
  telegram: 'tg_bridge_bot',
  whatsapp: 'wa_bridge_bot'
}

export const messengerProviders = {
  telegram: 'telegram',
  whatsapp: 'whatsapp',
  matrix: 'matrix'
}

export const selfMembershipTypes = {
  invite: 'invite',
  join: 'join',
  leave: 'leave'
}

export * from './emoji'
