import api from '@/api/despaxo'

const state = {
  countryList: [],
  contactInfo: null
}

const getters = {
  countryList: state => state.countryList,
  contactInfo: state => state.contactInfo
}

const actions = {
  async getDespaxoCountriesList ({ commit }) {
    try {
      const { data } = await api.getDespaxoCountriesList()
      commit('setCountriesList', data)
    } catch (err) {
      throw err.response
    }
  },
  async getContactInfo ({ commit }) {
    try {
      const { data } = await api.getContactInfo()
      commit('setContactInfo', data)
    } catch (err) {
      throw err.response
    }
  },
  async createContactInfo ({ commit }, payload) {
    try {
      await api.createContactInfo(payload)
    } catch (err) {
      throw err.response
    }
  },
  async updateContactInfo ({ commit }, payload) {
    try {
      await api.updateContactInfo(payload)
    } catch (err) {
      throw err.response
    }
  },
  async setDespaxoUserType ({ commit }, payload) {
    try {
      const { data } = await api.setDespaxoUserType(payload)
      commit('auth/replaceUserProperty', data, { root: true })
    } catch (err) {
      throw err.response
    }
  }
}

const mutations = {
  setCountriesList (state, data) {
    state.countryList = data
  },
  setContactInfo (state, data) {
    state.contactInfo = data
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true
}
