<template>
  <modal
    v-bind="$attrs"
    v-on="$listeners"
    :class="$style.wrapper"
    :name="name"
    width="450"
    height="auto"
    adaptive
    scrollable
    transition="modal-fade"
  >
    <h1 v-if="title" :class="$style.title">{{ title }}</h1>
    <BaseIcon custom name="close" @click="$modal.hide(name)" :class="$style.close" />

    <div :class="$style.body">
      <slot name="default" />
    </div>

    <div v-if="$slots.footer" :class="$style.footer">
      <slot name="footer" />
    </div>
  </modal>
</template>

<script>
export default {
  name: 'secondary-modal',
  props: {
    name: String,
    title: String
  }
}
</script>

<style lang="scss" module>
.wrapper :global(.v--modal) {
  background: $main-bg-light;
  box-shadow: 0 3px 11px 1px rgba(0, 0, 0, 0.15);
  // border: 1px solid $border-color;
  font-weight: 400;
  border-radius: 0;
  padding: 36px 32px;
}
.title {
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  text-align: center;
  @include respondTo(600px){
    font-size: 16px;
    line-height: 1.2;
  }
}
.close {
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 24px;
  right: 20px;
}
.body {
  margin-top: 20px;
  text-align: center;
}
.footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
