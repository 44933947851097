// import BlogCategory from './BlogCategory'
import { Model } from '@vuex-orm/core'

export default class PostCategory extends Model {
  static entity = 'postCategories'

  static fields () {
    return {
      id: this.number(null),
      icon: this.string(null),
      en: this.string(null),
      de: this.string(null),
      es: this.string(null),
      fr: this.string(null),
      ja: this.string(null),
      pt: this.string(null),
      ru: this.string(null),
      zhHans: this.string(null)
    }
  }
}
