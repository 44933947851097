import apiReq from './request'

const BASE_URL = `${process.env.VUE_APP_APIGW_URL}/payment`
const USER_URL = `${process.env.VUE_APP_APIGW_URL}/user`

export default {
  fetchOffersList (data) {
    return apiReq.get(`${BASE_URL}/bdtl-agent/packages-offers/list`, data)
  },
  buyOffers (data) {
    return apiReq.post(`${BASE_URL}/user/packages`, data)
  },
  fetchAgentOffers (data) {
    return apiReq.get(`${BASE_URL}/bdtl-agent/packages-offers/purchased/list`, data)
  },
  setBDTLOffer (data) {
    return apiReq.patch(`${BASE_URL}/bdtl-agent/packages-offers/purchased?id=${data.id}&nickname=${data.nickname ? data.nickname : ''}&email=${data.email ? data.email : ''}`, data)
  },
  setFreeBDTLOffers (data) {
    return apiReq.post(`${BASE_URL}/user/packages`, data)
  },
  getBDTLOfferUserList (data) {
    return apiReq.get(`${USER_URL}/user/profiles?${data}`, data)
  }
}
