import apiReq from './request'

const BASE_URL = `${process.env.VUE_APP_APIGW_URL}/user`
const PHONE_URL = `${process.env.VUE_APP_APIGW_URL}/auth`

export default {
  getProfiles (usersId, params = {}) {
    return apiReq.get(`${BASE_URL}/user/profiles`, {
      params: {
        usersId,
        ...params
      }
    })
  },
  getMyProfile () {
    return apiReq.get(`${BASE_URL}/user/my_profile`)
  },
  saveProfile (data) {
    return apiReq.patch(`${BASE_URL}/user/my_profile`, data)
  },
  getContacts () {
    return apiReq.get(`${BASE_URL}/user/contacts`)
  },
  getContactsUpdates (params = {}) {
    return apiReq.get(`${BASE_URL}/user/contacts-updates`, {
      params
    })
  },
  getContactsProfile (params = {}) {
    return apiReq.get(`${BASE_URL}/user/contacts-profile`, {
      params
    })
  },
  addContacts (phones) {
    return apiReq.post(`${BASE_URL}/user/contacts`, { phones })
  },
  removeContacts (usersId) {
    return apiReq.delete(`${BASE_URL}/user/contacts`, {
      params: { usersId }
    })
  },
  getSettings () {
    return apiReq.get(`${BASE_URL}/user/settings`)
  },
  updateSettings (data) {
    return apiReq.patch(`${BASE_URL}/user/settings`, data)
  },
  publicSearch (data) {
    return apiReq.get(`${BASE_URL}/user/public-search`, data)
  },
  getStatuses () {
    return apiReq.get(`${BASE_URL}/user/status`)
  },
  createStatus (data) {
    return apiReq.post(`${BASE_URL}/user/status`, data)
  },
  deleteStatus (id) {
    return apiReq.delete(`${BASE_URL}/user/status/${id}`)
  },
  getStatusById (id) {
    return apiReq.get(`${BASE_URL}/user/status/${id}`)
  },
  editStatus (id, data) {
    return apiReq.put(`${BASE_URL}/user/status/${id}`, data)
  },
  removePhone (data) {
    return apiReq.post(`${PHONE_URL}/phone/delete-phone`, data)
  },
  checkUsername (nickname) {
    return apiReq.post(`${BASE_URL}/user/check-unique-nickname?nickname=${nickname}`, { nickname: nickname })
  },
  userInfo (value) {
    return apiReq.get(`${BASE_URL}/info/user`, { params: { value } })
  },
  generateQRCode (user_id) {
    return apiReq.post(`${BASE_URL}/user/generate-qrcode`, { user_id })
  }
}
