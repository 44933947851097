import api from '@/api'
import Callout from '@/models/Callout'
import CalloutProfile from '@/models/CalloutProfile'
// import User from '@/models/User'
import { uniq } from 'lodash'

// const state

const getters = {
  list: () => Callout.query().with('sender').orderBy('created', 'desc').get()
}

const actions = {
  async fetch () {
    const { data } = await api.chats.advertising()
    Callout.insert({ data })
    const profiles = data.map(obj => obj.payload && obj.payload.profile)
    CalloutProfile.dispatch('fetch', uniq(profiles))
    // let userIds = []
    // data.forEach(obj => {
    //   if (userIds.indexOf(obj.senderId) > -1) return
    //   userIds.push(obj.senderId)
    // })
    // await User.dispatch('getProfiles', userIds)
  },
  async deleteMessage (_, messageId) {
    await api.chats.deleteAdvertising(messageId)
    Callout.delete(messageId)
  }
}

export default {
  getters,
  actions
}
