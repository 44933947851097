import { Model } from '@vuex-orm/core'
import User from './User'
import store from '@/store'

export default class MoodMessage extends Model {
  static entity = 'moodMessages'
  static primaryKey = 'messageId'

  static fields () {
    return {
      messageId: this.number(null),
      type: this.number(null),
      senderId: this.number(null),
      sender: this.belongsTo(User, 'senderId'),
      payload: this.attr(null).nullable(),
      created: this.attr(() => new Date().toISOString().slice(0, -1))
    }
  }

  get createdTs () {
    return +new Date(this.created + 'Z')
  }
  get isMy () {
    return this.senderId === store.getters['auth/user'].id
  }
}
