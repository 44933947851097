import apiReq from './request'

const BASE_URL = `${process.env.VUE_APP_APIGW_URL}/auth`
const USER_URL = `${process.env.VUE_APP_USER_URL}/auth`
export const REFRESH_TOKEN_URL = `${BASE_URL}/refresh_token`

export default {
  getOtp (data) {
    return apiReq.post(`${BASE_URL}/${process.env.VUE_APP_OTP_ENDPOINT_SLUG}`, data)
  },
  tokenOtpRecovery (data) {
    return apiReq.post(`${BASE_URL}/phone/token-otp-recovery`, data)
  },
  login (data) {
    return apiReq.post(`${BASE_URL}/user/auth`, data)
  },
  logout (data) {
    return apiReq.post(`${BASE_URL}/user/logout`, data)
  },
  refreshToken (data) {
    return apiReq.post(REFRESH_TOKEN_URL, data)
  },
  createPinCode (data) {
    return apiReq.post(`${BASE_URL}/pin-code`, data)
  },
  changePinCode (data) {
    return apiReq.patch(`${BASE_URL}/pin-code`, data)
  },
  deletePinCode (data) {
    return apiReq.delete(`${BASE_URL}/pin-code`, {
      data
    })
  },
  pinCodeRecovery (data) {
    return apiReq.post(`${BASE_URL}/pin-code/otp-recovery`, data)
  },
  registerByEmail (data) {
    return apiReq.post(`${BASE_URL}/v4BPx8kxPUM5utBksHOg`, data)
  },
  registerByMagicId (magicId) {
    return apiReq.get(`${BASE_URL}/v4BPx8kxPUM5utBksHOg?magic-id=${magicId}`)
  },
  requestMagicLogin (data) {
    return apiReq.post(`${BASE_URL}/user/auth-magic`, data)
  },
  registrationByMagicLink (data) {
    return apiReq.post(`${BASE_URL}/${process.env.VUE_APP_EMAIL_ENDPOINT_SLUG}`, data)
  },
  requestMagicRecovery (data) {
    return apiReq.post(`${BASE_URL}/user/restore-magic`, data)
  },
  magicLogin (magicId) {
    return apiReq.get(`${BASE_URL}/user/auth-magic?magic-id=${magicId}`)
  },
  magicRecovery (magicId) {
    return apiReq.get(`${BASE_URL}/user/restore-magic?magic-id=${magicId}`)
  },
  authByCode (data) {
    return apiReq.post(`${BASE_URL}/user/qr-login?fingerprint=${data.fingerprint}`, data.data)
  },
  updatePhone (data) {
    return apiReq.post(`${BASE_URL}/phone/update-phone`, data)
  },
  loginAfterScanQRCode (data) {
    return apiReq.get(`${BASE_URL}/user/qr-login?fingerprint=${data.fingerprint}&id=${data.id}&type=${data.type}`, data)
  },
  async storeToken (data) {
    const response = await apiReq.post(`${USER_URL}/token-store`, data)
    return response.data
  }
}
