<template>
  <div class="phone-input" :class="{ 'error' : errors }">
    <div class="phone-input__label" v-show="!!label">
      {{ label }}
    </div>
    <vue-tel-input
      :disabled="disabled"
      :autoDefaultCountry="autoDefaultCountry"
      :inputOptions="{
          placeholder: $t('formPhone'),
          autocomplete: true,
          type: 'tel'
        }"
      :dropdownOptions="{
          showDialCodeInSelection: true,
          showFlags: true,
          showDialCodeInList: true
        }"
      :ignoredCountries="['sn']"
      :value="value"
      @input="changePhoneInput">
      <template slot="arrow-icon">
        <span class="vti__dropdown-arrow">
          <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M0.292969 1.70712L1.70718 0.292908L6.00008 4.5858L10.293 0.292908L11.7072 1.70712L6.00008 7.41423L0.292969 1.70712Z" fill="#111111"/>
          </svg>
        </span>
      </template>
    </vue-tel-input>
    <div class="phone-input__errors" v-if="errors">
      <span class="phone-input__error" v-for="error in errors" :key="error">
        {{ error }}
      </span>
    </div>
  </div>
</template>
<script>
import PhoneNumber from 'awesome-phonenumber'
export default {
  name: 'PhoneInput',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    errors: {
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    autoDefaultCountry: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      phone: ''
    }
  },
  watch: {
    value: {
      handler () {
        if (this.value) {
          this.phone = new PhoneNumber(this.value).getNumber()
        }
      },
      immediate: true
    }
  },
  emits: ['on-update', 'valid'],
  methods: {
    changePhoneInput (number, info) {
      if (info) {
        this.$emit('on-update', info.number)
        this.$emit('valid', !!info.valid)
        this.phone = info.number
      }
    }
  }
}
</script>
<style lang="scss">
@mixin respondTo($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

.phone-input {
  margin-bottom: 20px;
  position: relative;

  &.error {
    .vue-tel-input {
      border-color: #d63a31;
    }

    input {
      border-color: #d63a31;
    }
  }

  &__error {
    margin-top: 5px;
    color: #d63a31;
    display: block;
    font-size: 12px;
    animation: shake 1 1s ease-in-out;
  }

  &__label {
    color: #a0a0a0;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 14px;
    @include respondTo(1950px) {
      font-size: 12px;
    }
  }

  input {
    width: 100%;
    border: 1px solid rgba(17, 17, 17, 0.15);
    height: 42px;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    padding: 0 19px;
    transition: border-color .2s ease;
    border-radius: 3px;

    &.vti__input {
      font-family: 'Montserrat', sans-serif;
      border: none;
      border-left: 1px solid rgba(17, 17, 17, 0.15);
      border-radius: 0;
      width: calc(100% - 110px);
      @include respondTo(550px) {
        font-size: 16px;
        width: calc(100% - 90px);
      }
    }

    &:focus {
      border-color: rgba(17, 17, 17, 0.5);
    }
  }

  .vti__dropdown-list {
    width: calc(100% + 2px);
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.15);
    border: none;
    max-height: 230px;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 11;

    &::-webkit-scrollbar {
      width: 8px;
      position: absolute;
      background: rgba(17, 17, 17, 0.1);
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      position: absolute;
      z-index: -2
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(17, 17, 17, 0.4);
      border-radius: 5px;
    }
  }

  .vti__dropdown-list.below {
    top: 50px;
  }

  .vue-tel-input {
    position: relative;
    border-radius: 3px;
    border: 1px solid rgba(17, 17, 17, 0.15);
    transition: border-color .2s ease;
    background: #fff;
  }

  .vue-tel-input:focus-within {
    box-shadow: none;
    border-color: rgba(17, 17, 17, 0.5);
  }

  .vti__dropdown {
    width: 120px;
    padding-left: 15px;
    position: initial;
    @include respondTo(550px) {
      width: 90px;
      padding: 5px;
    }

    &.open {
      .vti__dropdown-arrow {
        transform: scale(1) rotateX(-180deg);
      }
    }
  }

  .vti__selection {
    font-size: 0;
  }

  .vti__selection .vti__country-code {
    font-size: 16px;
    color: #000;
    font-weight: 400;
  }

  .vti__dropdown-arrow{
    font-size: 0;
    width: 13px;
    height: 8px;
    background-image: url('../assets/icons/custom/arrow-down-ico.svg');
    background-position: center;
    background-repeat: no-repeat;
    transform: scale(1);
    margin-left: 5px;
    transition: transform $trans;
  }

  .vti__dropdown-item {
    height: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    position: relative;
    padding-left: 46px;

    .vti__flag {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;

      &.ua ~ span {
        font-size: 0;

        &::before {
          content: '+38';
          position: relative;
          display: inline-block;
          font-size: 14px;
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 46px;
      bottom: 0;
      height: 1px;
      right: 0;
      background: rgba(0, 0, 0, 0.07);
      display: block;
    }

    strong {
      font-weight: 400;
      margin-left: 7px;
    }

    .vti__flag {
      margin-right: 13px;
    }
  }

  .vti__flag.ua + .vti__country-code {
    font-size: 0;

    &::before {
      content: '+38';
      position: relative;
      display: inline-block;
      font-size: 16px;
      @include respondTo(550px) {
        font-size: 14px;
      }
    }
  }
}
</style>
