import api from '@/api'
import SocialPost from '@/models/SocialPost'

const getters = {
  getPost: _ => id => {
    return SocialPost.query().whereId(id).with('profileObj').first()
  },
  postsByProfile: _ => nickname => {
    return SocialPost.query()
      .where('profile', nickname)
      .with('profileObj')
      .orderBy('createdAtTs', 'desc')
      .get()
  }
}

const actions = {
  async fetchProfilePosts (_, { nickname, limit, offset }) {
    const { results } = await api.social.getProfilePosts(nickname, { limit, offset })
    const data = results.map(post => ({
      ...post,
      profile: nickname
    }))
    SocialPost.insert({ data })
    return results
  },
  async fetchPost (_, { profileNickname, postId }) {
    const post = await api.social.getPost(profileNickname, postId)
    SocialPost.insert({ data: post })
  },
  async createPost (_, { profileNickname, data }) {
    let postData = data
    if (data.image) {
      const formData = new FormData()
      for (let key in data) {
        if (data[key] instanceof File) {
          formData.append(key, data[key])
        } else {
          formData.set(key, data[key])
        }
      }
      postData = formData
    }
    const post = await api.social.createPost(profileNickname, postData)
    SocialPost.insert({ data: post })
  },
  async updatePost (_, { profileNickname, postId, data }) {
    const formData = new FormData()
    for (let key in data) {
      if (data[key] instanceof File) {
        formData.append(key, data[key])
      } else {
        formData.set(key, data[key])
      }
    }
    const post = await api.social.editPost(profileNickname, postId, formData)
    SocialPost.insertOrUpdate({ data: post })
  },
  async deletePost ({ getters }, postId) {
    const post = getters.getPost(postId)
    await api.social.deletePost(post.profileObj.nickname, postId)
    SocialPost.delete(postId)
  },
  async toggleLike ({ getters }, { postId, isLiked }) {
    const post = getters.getPost(postId)
    post.$update({
      isLiked: true
    })
    await api.social.likePost(post.profileObj.nickname, postId, { isLiked })
  }
}

export default {
  getters,
  actions
}
