import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import database from './database'
import modules from './modules'
import createPersistedState from 'vuex-persistedstate'
import i18n from '@/i18n'
import VuexORMLocalForage from 'vuex-orm-localforage'

Vue.use(Vuex)

const state = {
  online: true
}
const getters = {
  isOnline: state => state.online
}
const mutations = {
  SET_ONLINE (state, flag) {
    state.online = flag
  },
  SET_LOCALE (state, locale) {
    i18n.locale = locale
    localStorage.setItem('locale', locale)
  }
}
const actions = {
  async selectLanguage ({ commit, dispatch, rootGetters }, locale) {
    commit('SET_LOCALE', locale)
    if (!rootGetters['auth/isLogged']) return
    await dispatch('auth/updateSettings', {
      language: locale
    }, { root: true })
  }
}

VuexORM.use(VuexORMLocalForage, {
  database
})

const plugins = [
  VuexORM.install(database),
  createPersistedState({
    paths: ['auth', 'entities.translatedMessages.language']
  })
]

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
  plugins,
  strict: process.env.NODE_ENV !== 'production'
})
