import { Model } from '@vuex-orm/core'

export default class BlogCategory extends Model {
  static entity = 'blogCategories'

  static fields () {
    return {
      id: this.number(null),
      en: this.string(null),
      de: this.string(null),
      es: this.string(null),
      fr: this.string(null),
      ja: this.string(null),
      pt: this.string(null),
      ru: this.string(null),
      zhHans: this.string(null)
    }
  }
}
