var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone-input",class:{ 'error' : _vm.errors }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.label),expression:"!!label"}],staticClass:"phone-input__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('vue-tel-input',{attrs:{"disabled":_vm.disabled,"autoDefaultCountry":_vm.autoDefaultCountry,"inputOptions":{
        placeholder: _vm.$t('formPhone'),
        autocomplete: true,
        type: 'tel'
      },"dropdownOptions":{
        showDialCodeInSelection: true,
        showFlags: true,
        showDialCodeInList: true
      },"ignoredCountries":['sn'],"value":_vm.value},on:{"input":_vm.changePhoneInput}},[_c('template',{slot:"arrow-icon"},[_c('span',{staticClass:"vti__dropdown-arrow"},[_c('svg',{attrs:{"width":"12","height":"8","viewBox":"0 0 12 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"opacity":"0.5","fill-rule":"evenodd","clip-rule":"evenodd","d":"M0.292969 1.70712L1.70718 0.292908L6.00008 4.5858L10.293 0.292908L11.7072 1.70712L6.00008 7.41423L0.292969 1.70712Z","fill":"#111111"}})])])])],2),(_vm.errors)?_c('div',{staticClass:"phone-input__errors"},_vm._l((_vm.errors),function(error){return _c('span',{key:error,staticClass:"phone-input__error"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }