import Blog from '@/models/Blog'
import User from '@/models/User'
import api from '@/api'
import { uniq, pick } from 'lodash'

const state = {
  activeBlogId: null
}

const mutations = {
  SET_ACTIVE (state, id) {
    state.activeBlogId = id
  }
}

const getters = {
  getBlog: _ => shortId => {
    // return Blog.query().whereId(shortId).with(['posts', 'category', 'owner']).first()
    return Blog.query().where('shortId', shortId).with(['posts', 'category', 'owner']).first()
  },

  myBlogs: (state, getters, rootState, rootGetters) => {
    return Blog.query()
      .where('isSubscribed', true)
      .orWhere('isOwn', true)
      .orderBy('lastPostPublication', 'desc')
      .get()
  },

  activeBlog: (state, getters) => getters.getBlog(state.activeBlogId)
}

const actions = {
  async fetch () {
    const { results } = await api.blogs.getBlogs({})
    Blog.insert({ data: results })
  },
  async fetchByShortId (_, shortId) {
    const { results } = await api.blogs.getBlogs({ shortId })
    Blog.insert({ data: results })
  },
  async fetchByUuid (_, Uuid) {
    const blog = await api.blogs.getBlog(Uuid)
    Blog.insert({ data: blog })
  },
  async fetchMyBlogs ({ dispatch, getters }, { limit, offset }) {
    const { results } = await api.blogs.getMyBlogs({ limit, offset })
    Blog.insert({ data: results })
    // let sayholaBlog = getters.find(SAYHOLA_BLOG_ID)
    // if (!sayholaBlog) {
    //   await dispatch('fetchByShortId', SAYHOLA_BLOG_ID)
    //   sayholaBlog = getters.find(SAYHOLA_BLOG_ID)
    //   if (!sayholaBlog.isSubscribed) {
    //     await dispatch('subscribe', sayholaBlog)
    //   }
    // }
    let userIds = results.map(blog => blog.ownerId)
    userIds = uniq(userIds).filter(id => !User.find(id)) // [...userIds, sayholaBlog.ownerId]
    try {
      await User.dispatch('getProfiles', userIds)
    } catch (e) {}
    return results
  },
  async fetchMyBlogById (_, uuid) {
    const blog = await api.blogs.getMyBlogByID(uuid)
    Blog.insert({ data: blog })
  },
  async createBlog (_, { blog, blogInfoList }) {
    const createdBlog = await api.blogs.createBlog(blog)
    const createdBlogInfo = await api.blogs.createBlogInfo(createdBlog.uuid, blogInfoList)
    createdBlog.blogInfo = createdBlogInfo
    Blog.insert({ data: createdBlog })
    return createdBlog
  },
  async editBlog (_, { uuid, blog, blogInfo }) {
    let newBlog
    if (blogInfo.length) {
      const blogInfosToCreate = blogInfo.filter(obj => !obj.id)
      const blogInfosToEdit = blogInfo.filter(obj => obj.id)
      const currentBlog = Blog.query().where('uuid', uuid).first()
      const createdBlogInfoList = await api.blogs.createBlogInfo(uuid, blogInfosToCreate)
      currentBlog.$update({
        blogInfo: [...currentBlog.blogInfo, ...createdBlogInfoList]
      })
      blogInfosToEdit.forEach(async obj => {
        const respBlogInfoObj = await api.blogs.updateBlogInfo({
          blogUuid: uuid,
          blogInfoId: obj.id,
          data: pick(obj, ['language', 'name', 'description'])
        })
        const newBlogInfo = [...currentBlog.blogInfo]
        const idx = newBlogInfo.findIndex(obj => obj.id === respBlogInfoObj.id)
        newBlogInfo[idx] = { ...respBlogInfoObj }
        currentBlog.$update({
          blogInfo: newBlogInfo
        })
      })
      newBlog = currentBlog
    }
    if (blog && Object.keys(blog).length) {
      newBlog = await api.blogs.updateBlog(uuid, blog)
      Blog.insertOrUpdate({ data: newBlog })
    }
    return newBlog
  },
  async deleteBlog (_, blog) {
    if (!blog) return
    await api.blogs.deleteBlog(blog.uuid)
    blog.$delete()
  },
  async subscribe (_, blog) {
    if (!blog) return
    await api.blogs.subscribe(blog.uuid)
    blog.$update({
      isSubscribed: true
    })
  },
  async unsubscribe (_, blog) {
    if (!blog) return
    await api.blogs.unsubscribe(blog.uuid)
    blog.$update({
      isSubscribed: false
    })
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
