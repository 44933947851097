import apiReq from './request'

const BASE_URL = `${process.env.VUE_APP_APIGW_URL}/social`

export default {
  support (data) {
    return apiReq.post(`${BASE_URL}/help/report/`, data)
  },
  getProfiles (params = {}) {
    return apiReq.get(`${BASE_URL}/profile/`, { params })
  },
  getMyProfiles (params = {}) {
    return apiReq.get(`${BASE_URL}/profile/my/`, { params })
  },
  createProfile (data) {
    return apiReq.post(`${BASE_URL}/profile/my/`, data)
  },
  getMyProfile (nickname) {
    return apiReq.get(`${BASE_URL}/profile/my/${nickname}/`)
  },
  editProfile (nickname, data) {
    return apiReq.patch(`${BASE_URL}/profile/my/${nickname}/`, data)
  },
  deleteProfile (nickname) {
    return apiReq.delete(`${BASE_URL}/profile/my/${nickname}/`)
  },
  getSubscribers (nickname) {
    return apiReq.get(`${BASE_URL}/profile/my/${nickname}/subscribers/`)
  },
  manageSubscriptions (nickname, subscriptionId, data) {
    return apiReq.patch(`${BASE_URL}/profile/my/${nickname}/subscribers/${subscriptionId}/`, data)
  },
  getProfile (nickname) {
    return apiReq.get(`${BASE_URL}/profile/${nickname}/`)
  },
  getProfilePosts (nickname, params = {}) {
    return apiReq.get(`${BASE_URL}/profile/${nickname}/post/`, { params })
  },
  createPost (nickname, data) {
    return apiReq.post(`${BASE_URL}/profile/${nickname}/post/`, data)
  },
  getPost (nickname, postId) {
    return apiReq.get(`${BASE_URL}/profile/${nickname}/post/${postId}/`)
  },
  editPost (nickname, postId, data) {
    return apiReq.patch(`${BASE_URL}/profile/${nickname}/post/${postId}/`, data)
  },
  deletePost (nickname, postId) {
    return apiReq.delete(`${BASE_URL}/profile/${nickname}/post/${postId}/`)
  },
  likePost (nickname, postId, data) {
    return apiReq.post(`${BASE_URL}/profile/${nickname}/post/${postId}/like/`, data)
  },
  subscribe (nickname) {
    return apiReq.post(`${BASE_URL}/profile/${nickname}/subscribe/`)
  },
  unsubscribe (nickname) {
    return apiReq.delete(`${BASE_URL}/profile/${nickname}/subscribe/`)
  },
  getPostComments (postId, params = {}) {
    return apiReq.get(`${BASE_URL}/profile/${postId}/comments/`, { params })
  },
  createComment (postId, data) {
    return apiReq.post(`${BASE_URL}/profile/${postId}/comments/`, data)
  },
  getComment (postId, commentId) {
    return apiReq.get(`${BASE_URL}/profile/${postId}/comments/${commentId}/`)
  },
  editComment (postId, commentId, data) {
    return apiReq.patch(`${BASE_URL}/profile/${postId}/comments/${commentId}/`, data)
  },
  deleteComment (postId, commentId) {
    return apiReq.delete(`${BASE_URL}/profile/${postId}/comments/${commentId}/`)
  },
  getCommentReplies (postId, commentId) {
    return apiReq.get(`${BASE_URL}/profile/${postId}/comments/${commentId}/replies/`)
  },
  checkUsername (nickname) {
    return apiReq.post(`${BASE_URL}/profile/my/check-unique-nickname?nickname=${nickname}`, { nickname: nickname })
  }
}
