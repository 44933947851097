import UserStatus from '@/models/UserStatus'
import api from '@/api'

const getters = {
  list: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['auth/user']
    if (!user) return []
    return UserStatus
      .query()
      .where('userId', null)
      .orWhere('userId', user.id)
      .orderBy('userId')
      .get()
  },
  myCurrentStatus: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['auth/user']
    if (!user || !user.statusId) return
    return UserStatus.query().whereId(user.statusId).with('user').first()
  }
}

const actions = {
  async fetchStatuses () {
    const { data } = await api.user.getStatuses()
    UserStatus.insert({ data })
  },
  async createStatus (_, { mediaFile, ...data }) {
    const mediaLink = await api.media.upload(mediaFile)
    const newStatus = {
      ...data,
      mediaLink
    }
    const resp = await api.user.createStatus(newStatus)
    UserStatus.insert({ data: resp.data })
  },
  async setStatus ({ dispatch, rootGetters }, statusId) {
    dispatch('entities/users/saveProfile', { statusId, nickname: rootGetters['auth/user'].nickname }, { root: true })
  },
  async editStatus (_, { id, title, statusText, mediaType, mediaFile }) {
    const status = UserStatus.find(id)
    const newData = {
      title,
      statusText,
      mediaType
    }
    if (mediaFile) {
      newData.mediaLink = await api.media.upload(mediaFile)
    } else {
      newData.mediaLink = status.mediaLink
    }
    if (status.userId) {
      const respData = await api.user.editStatus(id, newData)
      UserStatus.update({
        where: id,
        data: respData.data
      })
    } else {
      const respData = await api.user.createStatus(newData)
      UserStatus.insert({ data: respData.data })
    }
  },
  async deleteStatus (_, id) {
    await api.user.deleteStatus(id)
    UserStatus.delete(id)
  }
}

export default {
  getters,
  actions
}
