import { Model } from '@vuex-orm/core'
import User from './User'

export default class Callout extends Model {
  static entity = 'callouts'
  static primaryKey = 'messageId'

  static fields () {
    return {
      messageId: this.number(null),
      type: this.number(null),
      senderId: this.number(null),
      sender: this.belongsTo(User, 'senderId'),
      payload: this.attr(null).nullable(),
      created: this.attr(() => new Date().toISOString().slice(0, -1))
    }
  }
}
