import { library as fontAwesomeIconLibrary } from '@fortawesome/fontawesome-svg-core'
import {
  faSignInAlt,
  faAngleDown,
  faAngleUp,
  faHome,
  faShareAlt,
  faCog,
  faCodeBranch,
  faCommentDots,
  faQuestionCircle,
  faLanguage,
  faBomb,
  faUsers,
  faCloud,
  faKey,
  faUserSecret,
  faWindowClose,
  faBars,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faCircle,
  faMinusCircle,
  faPlusCircle,
  faTimes,
  faCheck,
  faPaperclip,
  faStar,
  faLocationArrow,
  faFile,
  faCamera,
  faCommentMedical,
  faSearch,
  faPencilAlt,
  faTrashAlt,
  faComment,
  faUserPlus,
  faUpload,
  faDownload,
  faEye,
  faSignOutAlt,
  faEnvelope,
  faEnvelopeOpen,
  faMedal,
  faAddressBook,
  faPhoneAlt,
  faArrowUp,
  faExclamationTriangle,
  faShare,
  faReplyAll,
  faQuestion,
  faArrowLeft,
  faMicrophone,
  faStop,
  faBookmark,
  faInfoCircle,
  faPlay,
  faPause,
  faExternalLinkSquareAlt,
  faChevronDown,
  faChevronUp,
  faPlayCircle,
  faVideo
} from '@fortawesome/free-solid-svg-icons'
import {
  faListAlt as farListAlt,
  faDotCircle as farDotCircle,
  faCheckCircle as farCheckCircle,
  faEnvelopeOpen as farEnvelopeOpen,
  faClone as farClone,
  faSquare as farSquare,
  faCheckSquare as farCheckSquare,
  faUser as farUser,
  faSmile as farSmile,
  faBookmark as farBookmark
} from '@fortawesome/free-regular-svg-icons'

// https://fontawesome.com/icons
fontAwesomeIconLibrary.add(
  faSignInAlt,
  faAngleDown,
  faAngleUp,
  faHome,
  farListAlt,
  faShareAlt,
  faCog,
  faCodeBranch,
  faCommentDots,
  faQuestionCircle,
  faLanguage,
  faBomb,
  faUsers,
  faCloud,
  faKey,
  faUserSecret,
  faWindowClose,
  faBars,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faCircle,
  farDotCircle,
  faMinusCircle,
  faPlusCircle,
  farCheckCircle,
  faTimes,
  farEnvelopeOpen,
  farClone,
  farSquare,
  farCheckSquare,
  farUser,
  faCheck,
  faPaperclip,
  faStar,
  faLocationArrow,
  farSmile,
  faFile,
  faCamera,
  faCommentMedical,
  faAddressBook,
  faSearch,
  faPencilAlt,
  faTrashAlt,
  faComment,
  faUserPlus,
  faUpload,
  faDownload,
  faEye,
  faSignOutAlt,
  faEnvelope,
  faEnvelopeOpen,
  faMedal,
  faPhoneAlt,
  faArrowUp,
  faExclamationTriangle,
  faShare,
  faReplyAll,
  faQuestion,
  faArrowLeft,
  faMicrophone,
  faStop,
  faBookmark,
  farBookmark,
  faInfoCircle,
  faPlay,
  faPause,
  faExternalLinkSquareAlt,
  faChevronDown,
  faChevronUp,
  faPlayCircle,
  faVideo
)
