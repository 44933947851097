import BlogCategory from '@/models/BlogCategory'
import api from '@/api'

const getters = {
  list () {
    return BlogCategory.all()
  }
}

const actions = {
  async fetch () {
    const { results } = await api.blogs.getBlogCategories({ limit: 2000 })
    BlogCategory.insert({ data: results })
  }
}

export default {
  getters,
  actions
}
