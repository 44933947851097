export default {
  state: {
    isOpened: false,
    isClosed: false
  },

  getters: {
    isOpened: state => state.isOpened,
    isClosed: state => state.isClosed
  },

  mutations: {
    SET_OPENED (state, flag) {
      state.isOpened = flag
    },
    SET_CLOSED (state, flag) {
      state.isClosed = flag
    }
  },

  actions: {},

  namespaced: true
}
