<template>
  <div v-if="isInited" id="app" class="app">
    <div class="app-top">
      <main class="main">
        <router-view />
      </main>
    </div>
    <div class="app-bot"></div>
    <ConfirmEmailModal />
    <ChangePhoneModal />
    <ResendOTPModal />
    <DeletePhone />
    <ConfirmRecoveryModal />
    <SupportSuccessModal />
    <Logout />
    <PremiumFeature />
    <SetStatus />
    <Cropper />
    <Success />
    <Info />
    <Error />
    <Confirm />
    <Video />
    <UserInfo />
    <Registration />
    <LoginByCode />
    <ShareContactByCode />
    <BridgeTelegram />
    <BridgeWhatsApp />
    <SetBDTLOffer />
    <BDTLOffersUserList />
    <DespaxoType />
  </div>
</template>

<script>
import ConfirmEmailModal from '@/components/modals/ConfirmEmailModal'
import ChangePhoneModal from '@/components/modals/ChangePhone'
import ResendOTPModal from '@/components/modals/ResendOTP'
import DeletePhone from '@/components/modals/DeletePhone'
import ConfirmRecoveryModal from '@/components/modals/ConfirmRecoveryModal'
import Error from '@/components/modals/Error'
import Info from '@/components/modals/Info'
import Success from '@/components/modals/Success'
import SupportSuccessModal from '@/components/modals/SupportSuccess'
import Logout from '@/components/modals/Logout'
import UserInfo from '@/components/modals/UserInfo'
import Confirm from '@/components/modals/Confirm'
import PremiumFeature from '@/components/modals/PremiumFeature'
import Cropper from '@/components/modals/Cropper'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import SetStatus from '@/components/modals/status/SetStatus'
import Video from '@/components/modals/Video'
import Registration from '@/components/modals/Registration.vue'
import LoginByCode from '@/components/modals/LoginByCode.vue'
import ShareContactByCode from '@/components/modals/ShareContactByCode.vue'
import BridgeTelegram from '@/components/modals/bridge/BridgeTelegram'
import BridgeWhatsApp from '@/components/modals/bridge/BridgeWhatsApp'
import SetBDTLOffer from '@/components/modals/SetBDTLOffer'
import BDTLOffersUserList from '@/components/modals/BDTLOffersUserList'
import DespaxoType from '@/components/modals/DespaxoType.vue'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import apiAuth from '@/api/auth'
// eslint-disable-next-line no-extend-native
String.prototype.replaceLast = function (what, replacement) {
  const pcs = this.split(what)
  const lastPc = pcs.pop()
  return pcs.join(what) + replacement + lastPc
}

export default {
  name: 'app',
  metaInfo () {
    return {
      titleTemplate: 'Virtuo | %s'
    }
  },
  components: {
    BridgeTelegram,
    BridgeWhatsApp,
    Registration,
    ConfirmEmailModal,
    ConfirmRecoveryModal,
    ChangePhoneModal,
    Error,
    Info,
    UserInfo,
    Success,
    SupportSuccessModal,
    Logout,
    Confirm,
    PremiumFeature,
    Cropper,
    SetStatus,
    Video,
    DeletePhone,
    ResendOTPModal,
    LoginByCode,
    ShareContactByCode,
    SetBDTLOffer,
    BDTLOffersUserList,
    DespaxoType
  },
  data () {
    return {
      isInited: false
    }
  },
  watch: {
    user: {
      handler () {
        if (this.user && !this.user.nickname) {
          setTimeout(() => {
            this.$modal.show('user-info')
          })
        }
      },
      immediate: true
    }
  },
  async created () {
    const useFingerprint = this.getQueryVariable('useFingerprint')
    if (useFingerprint) {
      const deviceId = await this.getFingerprintId()
      this.setTokens({ accessToken: null, refreshToken: null })
      try {
        const storeToken = await apiAuth.storeToken({
          fingerprint: deviceId
        })
        if (storeToken) {
          this.setTokens({ accessToken: storeToken.token, refreshToken: null })
          this.setUser({})
          this.getProfile().then(() => {
            this.isInited = true
          })
        }
        this.$router.replace({ name: this.$route.name })
      } catch {
        this.$router.push({ name: 'sign-in' })
      }
    }

    // need remove later, its old danger logic
    const token = this.getQueryVariable('token')
    if (token) {
      this.setTokens({ accessToken: token, refreshToken: null })
      this.setUser({})
      this.getProfile().then(() => {
        this.isInited = true
        // this.$router.push({ name: 'payments-sponsor' }).catch(e => {})
      })
    }
    // end remove block

    await this.initAuth().then(() => {
      if (this.isLogged) {
        if (!this.user.nickname || !this.user.email) {
          setTimeout(() => {
            this.$modal.show('user-info')
          }, 0)
        }
      }
    })
    this.isInited = true
  },
  computed: {
    ...mapGetters({
      isLogged: 'auth/isLogged'
    }),
    ...mapState('auth', ['user']),
    isChat () {
      // return this.$route.path === '/chat' // || this.$route.path === '/app'
      // if (!this.$route.matched) return false
      return this.$route.matched.find(route => route.path === '/chat' || route.path === '/app')
    }
  },
  methods: {
    ...mapMutations({
      setTokens: 'auth/SET_TOKENS',
      setUser: 'auth/SET_USER'
    }),
    ...mapActions({
      initAuth: 'auth/init',
      getProfile: 'entities/users/getMyProfile'
      // getUser: 'auth/getUser'
    }),
    async getFingerprintId () {
      const fpPromise = await FingerprintJS.load()
      const result = await fpPromise.get()
      return result.visitorId
    },
    getQueryVariable (variable) {
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1])
        }
      }
    }
  }
}
</script>

<style src="swiper/css/swiper.css"></style>

<style lang="scss">
@import '~normalize.css/normalize.css';
@import '@/assets/styles/app.scss';
@import '@/assets/styles/multiselect.scss';
@import '@/assets/styles/forms.scss';
@import '@/assets/styles/tooltip.scss';
@import '@/assets/styles/tabs.scss';
@import '@/assets/styles/fonts.scss';

.swiper-pagination-bullet {
  background: $main-bg-darkest;
  opacity: 0.8;
}

.swiper-pagination-bullet-active {
  background: $main-bg-light;
}

.viewer-container {
  z-index: 999999 !important;
}

.title {
  color: $text-colored;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  padding-bottom: 5px;
  text-align: center;
}

.link {
  color: $text-colored;
  cursor: pointer;

  &.disabled {
    cursor: default;
    color: rgba($text-colored, 0.7);
  }
}

.red {
  color: $text-error;
  cursor: pointer;
}

.txt {
  font-size: 15px;
  font-weight: 400;
  text-align: center;

  &-m {
    font-size: 18px;
  }
}

.center {
  text-align: center;
}

.input-nickname {
  position: relative;

  &::before {
    content: '@';
    position: absolute;
    top: 27px;
    color: $text-mid;
  }

  .form--input {
    padding-left: 18px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal-fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.3s, transform 0.3s;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(50px);
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.1s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(50%);
}

.tooltip.popover .popover-inner {
  padding: 0;
}

// .croppa-container {
//   background-color: rgba($main-bg-dark, 0.9);
//   // border: 2px solid grey;
//   // border-radius: 8px;
//   transition: all 0.2s;
//   cursor: pointer;
//   position: relative;
//   .icon {
//     position: absolute;
//   }
//   &.circled:: {

//   }
// }

// .croppa-container:hover {
//   opacity: 1;
//   background-color: rgba($main-bg-dark, 0.7);
// }

.croppa-container {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  font-size: 0;
  align-self: flex-start;
  background-color: #e6e6e6;

  canvas {
    transition: all 0.3s;
  }

  &:hover {
    opacity: 0.7;
  }

  &.croppa--dropzone {
    box-shadow: inset 0 0 10px rgba(black, 0.2);

    canvas {
      opacity: 0.5;
    }
  }

  &.croppa--disabled-cc {
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }

  &.croppa--has-target {
    cursor: move;

    &:hover {
      opacity: 1;
    }

    &.croppa--disabled-mz {
      cursor: default;
    }
  }

  &.croppa--disabled {
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }

  &.croppa--passive {
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }

  svg.icon-remove {
    position: absolute;
    background: white;
    border-radius: 50%;
    filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.7));
    z-index: 10;
    cursor: pointer;
    border: 2px solid white;
  }

  &.croppa--circled::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.custom-icon {
  width: 1em;
  height: 1em;
}

img[lazy] {
  transition: all 0.3s;
}

img[lazy='loading'] {
  opacity: 0.6;
  background-color: $main-bg-dark;
}

img[lazy='loaded'] {
  opacity: 1;
}

.radio.radio-component {
  > input + label > .input-box {
    border: 1px solid $text-dark;

    .input-box-circle {
      background: $text-colored;
    }
  }
}
</style>
