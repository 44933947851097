<template>
  <ul :class="$style.container">
    <slot>
      <li
        v-for="item in renderList" :key="item.name"
        :class="[$style.item, { [$style.itemDisabled]: item.disabled }]"
        @click="item.handler"
      >
        <BaseIcon :name="item.icon" :custom="item.customIcon" :class="$style.icon" />
        <div>{{ item.name }}</div>
      </li>
    </slot>
  </ul>
</template>

<script>
export default {
  name: 'menu-base',
  props: {
    options: Array
  },
  computed: {
    renderList () {
      if (!this.options) return []
      return this.options.filter(item => {
        if (!item.displayCondition) return true
        return item.displayCondition()
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: 100%;
  color: $text-darkest;
  padding: 6px 20px 6px 0;
}
.item {
  height: 42px;
  display: flex;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  &Disabled {
    color: $text-mid;
    cursor: default;
  }
  &:not(.itemDisabled):hover {
    color: $text-colored;
    .icon {
      color: $text-colored;
    }
  }
}
.icon {
  margin: 0 12px;
  color: $text-light;
  font-size: 16px;
}
</style>
