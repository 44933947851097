import api from '@/api'
import router from '@/router'
import { pick } from 'lodash'
import Ws from '@/api/ws'
import uuid from 'uuid'
import User from '@/models/User'
import sha512 from 'js-sha512'

const state = {
  accessToken: null,
  // expiry: null,
  refreshToken: null,
  user: null,
  settings: null,
  deviceId: null,
  isPinProtected: false,
  otp: '',
  authByCodeLoading: false
}

const getters = {
  isLogged: state => state.accessToken && state.user,
  user: state => state.user,
  settings: state => state.settings,
  userPackage: (state, getters, rootState, rootGetters) => {
    const user = state.user
    if (!user) return
    return rootGetters['entities/packages/find'](user.package)
  },
  isPremium: (state, getters) => getters.userPackage && getters.userPackage.isPremium,
  otp: state => state.otp,
  authByCodeLoading: state => state.authByCodeLoading
}

const mutations = {
  SET_TOKENS (state, { accessToken, refreshToken }) {
    state.accessToken = accessToken
    state.refreshToken = refreshToken
  },
  SET_OTP (state, otp) {
    state.otp = otp
    localStorage.setItem('otp', otp)
  },
  SET_USER (state, user) {
    state.user = user
  },
  CLEAR_AUTH (state) {
    state.accessToken = null
    state.refreshToken = null
    state.user = null
    localStorage.removeItem('vuex')
  },
  SET_SETTINGS (state, settings) {
    state.settings = settings
  },
  UPDATE_SETTINGS (state, settings) {
    state.settings = {
      ...state.settings,
      ...settings
    }
  },
  GENERATE_DEVICE_ID (state) {
    state.deviceId = uuid.v4()
  },
  IS_PIN_PROTECTED (state, isPinProtected) {
    state.isPinProtected = isPinProtected
  },
  changeAuthByCodeLoading (state, status) {
    state.authByCodeLoading = status
  },
  replaceUserProperty (state, data) {
    Object.assign(state.user, data)
  }
}

const actions = {
  async init ({ state, getters, dispatch, commit }) {
    if (!state.deviceId) {
      commit('GENERATE_DEVICE_ID')
    }
    if (!getters.isLogged) return
    await dispatch('entities/users/getMyProfile', null, { root: true })
    await dispatch('getSettings')
  },
  getOtp (_, phone) {
    const prefix = 'F+FZPQuv)Jn8tWETjQ%U(*8p^TnIu(8T~fI^nWQGujzPa72B7cfTwTYu'
    const suffix = 'v)TC~w66XNb9T)L4ZcTq^unr$QAGIV8^t2U%#I(E~32Qn!4L!Avq(n(9'
    let secret = sha512(prefix + phone + suffix)
    const data = {
      phone: phone,
      secret: secret
    }
    return api.auth.getOtp(data)
  },
  async login ({ state, commit, dispatch }, { phone, otp, pinCode }) {
    const params = {
      phone,
      otp,
      device: {
        id: state.deviceId,
        type: 'web'
      }
    }
    if (pinCode) {
      params.pinCode = pinCode
    }
    const resp = await api.auth.login(params)
    commit('SET_OTP', otp)
    await dispatch('processLogin', { resp, pinCode })
    return resp
  },
  async tokenOtpRecovery ({ state, commit, dispatch }, { phone, otp }) {
    const params = {
      phone,
      otp,
      device: {
        id: state.deviceId,
        type: 'web'
      }
    }
    const resp = await api.auth.tokenOtpRecovery(params)
    await dispatch('processLogin', { resp })
    return resp
  },
  async registerByEmail (_, payload) {
    try {
      const resp = await api.auth.registerByEmail(payload)
      return resp
    } catch (err) {
      throw err
    }
  },
  async registerByMagicId ({ dispatch }, { id }) {
    try {
      const resp = await api.auth.registerByMagicId(id)
      await dispatch('processLogin', { resp, pinCode: resp.data.isPinProtected })
      return resp
    } catch (err) {
      throw err
    }
  },
  async magicLogin ({ dispatch }, magicId) {
    const resp = await api.auth.magicLogin(magicId)
    const pinCode = !!resp.data.isPinProtected
    await dispatch('processLogin', { resp, pinCode })
  },
  async magicRecovery ({ dispatch }, magicId) {
    const resp = await api.auth.magicRecovery(magicId)
    const pinCode = !!resp.data.isPinProtected
    await dispatch('processLogin', { resp, pinCode })
  },
  async requestMagicLogin ({ state }, { email, lang }) {
    const params = {
      lang,
      email,
      device: {
        id: state.deviceId,
        type: 'web'
      }
    }
    await api.auth.requestMagicLogin(params)
  },
  async requestMagicRecovery ({ state }, { email, lang }) {
    const params = {
      lang,
      email,
      device: {
        id: state.deviceId,
        type: 'web'
      }
    }
    await api.auth.requestMagicRecovery(params)
  },
  async processLogin ({ commit, dispatch }, { resp, pinCode }) {
    const { data } = resp
    const { accessToken, refreshToken } = data.token
    const user = pick(data, [
      'id',
      'banned',
      'package',
      'packageExpires',
      'phone',
      'firstName',
      'lastName',
      'nickname',
      'avatarLink',
      'birthDate',
      'email',
      'accountTypes'
    ])
    commit('SET_TOKENS', { accessToken, refreshToken })
    commit('SET_USER', user)
    commit('IS_PIN_PROTECTED', !!pinCode)
    await dispatch('getSettings')
  },
  async refresh ({ dispatch, commit, state }) {
    const handleError = () => {
      dispatch('cleanUp')
      window.location = '/sign-in'
    }
    try {
      const { status, data } = await api.auth.refreshToken({
        refreshToken: state.refreshToken
      })
      if (status === 'error') {
        handleError()
        return Promise.reject(data)
      }
      const { accessToken, refreshToken } = data
      commit('SET_TOKENS', { accessToken, refreshToken })
    } catch (e) {
      handleError()
      return e
    }
  },
  async logout ({ dispatch, state }, allSessions = false) {
    const { refreshToken } = state
    try {
      await api.auth.logout({
        refreshToken,
        allSessions
      })
    } catch (error) {
      //
    } finally {
      dispatch('cleanUp')
      router.push({ name: 'sign-in' })
    }
  },
  async getSettings ({ commit }) {
    const { data } = await api.user.getSettings()
    commit('SET_SETTINGS', data)
    const { language } = data
    if (language) {
      commit('SET_LOCALE', language, { root: true })
    }
  },
  async updateSettings ({ commit }, params) {
    const { data } = await api.user.updateSettings(params)
    commit('UPDATE_SETTINGS', data)
  },
  async updatePhone ({ commit }, payload) {
    try {
      const { data } = await api.auth.updatePhone(payload)
      commit('replaceUserProperty', data)
      return data
    } catch (err) {
      throw err
    }
  },
  cleanUp ({ commit, dispatch }) {
    commit('CLEAR_AUTH')
    commit('affiliate/CLEAR', null, { root: true })
    commit('entities/chats/SET_INITED', false, { root: true })
    Ws.disconnect()
    User.$deleteAll()
    dispatch('entities/deleteAll', null, { root: true })
    window.location.reload()
  },
  async authByCode ({ commit, dispatch }, payload) {
    try {
      commit('changeAuthByCodeLoading', true)
      const response = await api.auth.authByCode(payload)
      if (
        response.data &&
        Object.keys(response.data).length === 0 &&
        Object.getPrototypeOf(response.data) === Object.prototype
      ) {
      } else {
        const { data } = response
        const { accessToken, refreshToken } = data.token
        const user = pick(data, [
          'id',
          'banned',
          'package',
          'packageExpires',
          'phone',
          'firstName',
          'lastName',
          'nickname',
          'avatarLink',
          'birthDate',
          'email',
          'accountTypes'
        ])
        commit('SET_TOKENS', { accessToken, refreshToken })
        commit('SET_USER', user)
        commit('IS_PIN_PROTECTED', !!data.isPinProtected)
        dispatch('getSettings')
      }
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeAuthByCodeLoading', false)
    }
  },
  async loginAfterScanQRCode ({ commit, dispatch }, payload) {
    try {
      commit('changeAuthByCodeLoading', true)
      const response = await api.auth.loginAfterScanQRCode(payload)
      const { data } = response
      const { accessToken, refreshToken } = data.token
      const user = pick(data, [
        'id',
        'banned',
        'package',
        'packageExpires',
        'phone',
        'firstName',
        'lastName',
        'nickname',
        'avatarLink',
        'birthDate',
        'email',
        'accountTypes'
      ])
      commit('SET_TOKENS', { accessToken, refreshToken })
      commit('SET_USER', user)
      commit('IS_PIN_PROTECTED', !!data.isPinProtected)
      dispatch('getSettings')
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeAuthByCodeLoading', false)
    }
  }
}

export default { state, getters, actions, mutations, namespaced: true }
