<template>
  <label class="form-container">
    <template v-if="isLabelArray">
      <div v-for="(l, i) in label" :key="i" class="form--label">{{ l }}</div>
    </template>
    <div v-else-if="label" class="form--label">{{ label }}</div>
    <input
      v-if="!multiline"
      :type="type"
      :readonly="readonly"
      v-bind="$attrs"
      v-on="inputListeners"
      :value="value"
      :class="['form--input', { 'input--error': invalid }]"
    />
    <textarea
      v-else
      :type="type"
      v-bind="$attrs"
      v-on="inputListeners"
      :value="value"
      :class="['form--textarea', { 'input--error': invalid }]"
    />
    <span v-show="!invalid && helpText" class="input--help-text">{{ helpText }}</span>
    <span v-show="invalid" class="input--error-msg">
      <span>{{ error }}</span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'base-input',
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  // Change the v-model event name to `update` to avoid changing
  // the behavior of the native `input` event.
  // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
  // model: {
  //   event: 'input'
  // },
  props: {
    invalid: {
      type: Boolean,
      default: false
    },
    error: {
      type: String
    },
    label: {
      type: [String, Array]
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {},
    type: {
      type: String,
      default: 'text',
      // Only allow types that essentially just render text boxes.
      validator (value) {
        return ['email', 'number', 'password', 'search', 'tel', 'text', 'url'].includes(value)
      }
    },
    multiline: {
      type: Boolean,
      default: false
    },
    helpText: String
  },
  computed: {
    inputListeners () {
      return Object.assign({}, this.$listeners, {
        input: event => {
          this.$emit('input', event.target.value)
        }
      })
    },
    isLabelArray () {
      return Array.isArray(this.label)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/forms';

.form--input,
p.form--input {
  @extend %form--input;
}

.form--textarea {
  @extend %form--input;
  height: 150px;
  &[rows] {
    height: auto;
  }
}

.input--error-msg {
  font-size: 12px;
  color: $text-error;
}
.form--input:disabled {
  color: $text-mid-dark;
}
.input--help-text {
  font-size: 12px;
  color: $text-mid-dark;
}
</style>
