import { Model } from '@vuex-orm/core'
import SocialProfile from './SocialProfile'
import SocialComment from './SocialComment'
export default class SocialPost extends Model {
  static entity = 'socialPosts'

  static fields () {
    return {
      id: this.number(0),
      likes: this.number(0),
      isLiked: this.boolean(false),
      text: this.string(''),
      createdAt: this.string(''),
      updatedAt: this.string(''),
      image: this.string('').nullable(),
      isEnabledComments: this.boolean(false),
      isEnabledLikes: this.boolean(false),
      type: this.number(0),
      giphy: this.string('').nullable(),
      video: this.string('').nullable(),
      profile: this.string(''),
      profileObj: this.belongsTo(SocialProfile, 'profile'),
      comments: this.hasMany(SocialComment, 'postId')
    }
  }
  get createdAtTs () {
    return +new Date(this.createdAt)
  }
}
