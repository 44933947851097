import apiReq from './request'

export default {
  facebook (data) {
    return apiReq.post('https://import.stage.sayhola.io/api/v1/import/facebook', data)
  },
  facebookStatus () {
    return apiReq.get('https://import.stage.sayhola.io/api/v1/import/facebook')
  }
}
