import Vue from 'vue'
import apiHola from '@/api/matrix'
import { createLinkImage, formattedUserName } from '@/helpers'
import moment from 'moment'
import camelcaseKeysDeep from 'camelcase-keys-deep'
import { messengerProviders } from '@/helpers/variables'
import { cloneDeep } from 'lodash'

const byId =
  currentId =>
    ({ id }) =>
      id === currentId
const byEventId =
  currentId =>
    ({ event_id }) =>
      event_id === currentId
const byCredentials =
  credentials =>
    ({ subProvider }) =>
      (credentials?.isBridgeWhatsapp && subProvider === messengerProviders.whatsapp) ||
    (credentials?.isBridgeTelegram && subProvider === messengerProviders.telegram)

const state = {
  matrixCredentials: null,
  isLoading: false,
  rooms: [],
  inviteRooms: [],
  currentRoomMessages: [],
  currentRoom: null,
  roomMembers: [],
  isReadyMatrix: false
}

const getters = {
  rooms: (state, getters) =>
    state.rooms.filter(byCredentials(getters.matrixCredentials)).map(e => {
      e.lastMessages.map(t => {
        if (t && t.origin_server_ts) {
          t.created = moment(t.origin_server_ts).utc().format('YYYY-MM-DD HH:mm:ss')
          return t
        }
      })
      return e
    }),
  inviteRooms: (state, getters) => state.inviteRooms.filter(byCredentials(getters.matrixCredentials)),
  matrixCredentials: state => {
    if (state.matrixCredentials) {
      return {
        ...state.matrixCredentials,
        waPhone: null,
        tgPhone: null
      }
    }
    return null
  },
  currentRoomMessages: state => state.currentRoomMessages,
  getRoomById: (_, getters) => id => getters.rooms.find(byId(id)) ?? getters.inviteRooms.find(byId(id)) ?? null,
  currentRoom: state => state.currentRoom,
  isReadyMatrix: state => state.isReadyMatrix,
  roomMembers: state =>
    state.roomMembers.map(e => {
      return {
        ...e,
        display_name: formattedUserName(e.display_name)
      }
    }),
  isLoading: state => state.isLoading
}

const actions = {
  async getMatrixCredentials ({ commit }) {
    try {
      commit('CHANGE_LOADING', true)
      const { data } = await apiHola.getMatrixCredentials()
      commit('SET_MATRIX_CREDENTIALS', data)
    } finally {
      commit('CHANGE_LOADING', false)
    }
  },
  async saveMatrixCredentials ({ commit }, payload) {
    try {
      commit('CHANGE_LOADING', true)
      await apiHola.saveMatrixCredentials(payload)
      commit('SET_MATRIX_CREDENTIALS', payload)
    } finally {
      commit('CHANGE_LOADING', false)
    }
  },
  async updateMatrixCredentials ({ commit }, payload) {
    try {
      commit('CHANGE_LOADING', true)
      await apiHola.updateMatrixCredentials(payload)
      commit('SET_MATRIX_CREDENTIALS', payload)
    } finally {
      commit('CHANGE_LOADING', false)
    }
  },
  async getMessages ({ commit }, payload) {
    try {
      commit('CHANGE_LOADING', true)
      const { data } = await apiHola.getMessages(payload)
      return data
    } finally {
      commit('CHANGE_LOADING', false)
    }
  }
}

const mutations = {
  REFRESH_CURRENT_ROOM (state, room) {
    if (!state.currentRoom) return
    const { receiptCacheByEventId, receipts } = room
    Object.assign(state.currentRoom, cloneDeep({ receiptCacheByEventId, receipts }))
  },
  SET_MATRIX_CREDENTIALS (state, data) {
    if (data) {
      if (state.matrixCredentials) {
        Object.assign(state.matrixCredentials, camelcaseKeysDeep(data))
      } else {
        state.matrixCredentials = data
      }
    } else {
      state.matrixCredentials = null
    }
  },
  SET_ROOM_BY_ID (state, { id }) {
    const room = state.rooms.find(byId(id)) ?? state.inviteRooms.find(byId(id))
    if (room) {
      const isAccess = byCredentials(state.matrixCredentials)
      state.currentRoom = isAccess(room) ? room : state.rooms.find(isAccess) ?? state.inviteRooms.find(isAccess) ?? null
    } else {
      state.currentRoom = null
    }
  },
  SET_CURRENT_ROOM (state, data) {
    state.currentRoom = data
  },
  REMOVE_ROOM_FROM_LIST (state, { id, type = 'join' }) {
    const findIndexDeleteRoom = state[type === 'join' ? 'rooms' : 'inviteRooms'].findIndex(byId(id))
    Vue.delete(state[type === 'join' ? 'rooms' : 'inviteRooms'], findIndexDeleteRoom)
  },
  SET_ROOMS (state, data) {
    state.rooms = data
  },
  ADD_ROOM_TO_LIST (state, data) {
    state.rooms.unshift(data)
  },
  ADD_INVITE_ROOM_TO_LIST (state, data) {
    state.inviteRooms.unshift(data)
  },
  SET_IS_READY_MATRIX (state, status) {
    state.isReadyMatrix = status
  },
  SET_INVITE_ROOMS (state, data) {
    state.inviteRooms = data
  },
  SET_ROOM_MEMBERS (state, data) {
    state.roomMembers = data
  },
  REPLACE_ROOM_AVATAR (state, { id, url }) {
    state.currentRoom.mxcUrl = url
    const imageList = createLinkImage(url)
    state.currentRoom.imgLink = imageList

    const indexRoom = state.rooms.findIndex(byId(id))
    const findRoomById = state.rooms.find(byId(id))
    findRoomById.mxcUrl = url
    findRoomById.imgLink = imageList
    Vue.set(state.rooms, indexRoom, findRoomById)
  },
  REPLACE_ROOM_PROPERTY (state, { id, value, property }) {
    state.currentRoom[property] = value
    const indexRoom = state.rooms.findIndex(byId(id))
    const findRoomById = state.rooms.find(byId(id))
    findRoomById[property] = value
    Vue.set(state.rooms, indexRoom, findRoomById)
  },
  REMOVE_ROOM_MEMBER (state, { id }) {
    const removedIndex = state.roomMembers.findIndex(byId(id))
    Vue.delete(state.roomMembers, removedIndex)
  },
  REPLACE_ROOM_TOPIC (state, { id, topic }) {
    const roomIndex = state.rooms.findIndex(byId(id))
    const currentRoom = state.rooms.find(byId(id))
    currentRoom.topic = topic
    Vue.set(state.rooms, roomIndex, currentRoom)
  },
  REPLACE_ROOM (state, data) {
    const roomIndex = state.rooms.findIndex(byId(data.id))
    Vue.set(state.rooms, roomIndex, data)
  },
  CHANGE_LOADING (state, status) {
    state.isLoading = status
  },
  SET_ROOM_TYPING_USER (state, data) {
    const currentRoom = state.rooms.find(byId(data.roomId))
    if (!currentRoom) return
    const currentRoomIndex = state.rooms.findIndex(byId(data.roomId))
    if (data.actionType === 'add') {
      currentRoom.typingUsers.push(data.user)
    } else {
      const index = currentRoom.typingUsers.find(e => e === data.user)
      currentRoom.typingUsers.splice(index, 1)
    }
    Vue.set(state.rooms, currentRoomIndex, currentRoom)
  },
  ADD_LAST_MESSAGES_TO_CHAT (state, data) {
    const currentRoom = state.rooms.find(byId(data.roomId))
    if (!currentRoom) return
    const currentRoomIndex = state.rooms.findIndex(byId(data.roomId))

    currentRoom.lastMessages.unshift(data.event)

    Vue.set(state.rooms, currentRoomIndex, currentRoom)
  },
  ADD_MESSAGE_TO_CURRENT_ROOM (state, data) {
    state.currentRoomMessages.unshift(...data)
  },
  REPLACE_MESSAGE_IN_CURRENT_ROOM (state, data) {
    const findOldMessageIndex = state.currentRoomMessages.findIndex(
      e => e.event_id === data.content['m.relates_to'].event_id
    )
    const findOldMessage = state.currentRoomMessages.find(e => e.event_id === data.content['m.relates_to'].event_id)
    findOldMessage.content.body = data.content['m.new_content'].body
    const unsigned = { age: 171219, 'm.relations': {} }
    Vue.set(findOldMessage, 'unsigned', unsigned)

    Vue.set(state.currentRoomMessages, findOldMessageIndex, findOldMessage)
  },
  REMOVE_MESSAGE_IN_CURRENT_ROOM (state, data) {
    const findIndexDeletedMessage = state.currentRoomMessages.findIndex(e => e.event_id === data.redacts)
    const findDeletedMessage = state.currentRoomMessages.find(byId(data.redacts))
    data.origin_server_ts = findDeletedMessage.origin_server_ts
    Vue.set(state.currentRoomMessages, findIndexDeletedMessage, data)
  },
  REPLACE_MESSAGE_PROPERTY (state, { id, value, property }) {
    const indexMessage = state.currentRoomMessages.findIndex(byId(id))
    const findMessageById = state.currentRoomMessages.find(byId(id))
    Vue.set(findMessageById, property, value)
    Vue.set(state.currentRoomMessages, indexMessage, findMessageById)
  },
  REMOVE_MESSAGE (state, { id }) {
    const indexMessage = state.currentRoomMessages.findIndex(byId(id))
    Vue.delete(state.currentRoomMessages, indexMessage)
  },
  ADD_NEW_MESSAGE_TO_CURRENT_ROOM (state, data) {
    state.currentRoomMessages.push(data)
  },
  DELETE_MESSAGES_FROM_CURRENT_ROOM (state) {
    state.currentRoomMessages = []
  },
  TRANSLATE_MESSAGE (state, data) {
    const indexMessage = state.currentRoomMessages.findIndex(byEventId(data.event_id))
    const currentMessage = state.currentRoomMessages.find(byEventId(data.event_id))
    Vue.set(currentMessage.content, 'new_body', data.new_body)
    Vue.set(currentMessage.content, 'isTranslated', data.isTranslated)
    Vue.set(state.currentRoomMessages, indexMessage, currentMessage)
  },
  REMOVE_TRANSLATE_MESSAGE (state, data) {
    const indexMessage = state.currentRoomMessages.findIndex(byEventId(data.event_id))
    const currentMessage = state.currentRoomMessages.find(byEventId(data.event_id))
    Vue.delete(currentMessage.content, 'new_body')
    Vue.delete(currentMessage.content, 'isTranslated')
    Vue.set(state.currentRoomMessages, indexMessage, currentMessage)
  }
}

export default { state, getters, actions, mutations, namespaced: true }
