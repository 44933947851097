import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import OpenLink from '@/views/OpenLink'
import Support from '@/views/Support'

// const Index = () => import(/* webpackChunkName: "authed" */'@/views/Index')
const AuthRoute = () => import(/* webpackChunkName: "authed" */ '@/views/AuthRoute')
const Package = () => import(/* webpackChunkName: "authed" */ '@/views/Package')
const Payment = () => import(/* webpackChunkName: "authed" */ '@/views/Payment')
// const Support = () => import(/* webpackChunkName: "authed" */'@/views/Support')

const Payments = () => import(/* webpackChunkName: "authed" */ '@/views/Payments/Payments')
const PaymentsSponsor = () => import(/* webpackChunkName: "authed" */ '@/views/Payments/PaymentsSponsor')
const PaymentsCommissions = () => import(/* webpackChunkName: "authed" */ '@/views/Payments/PaymentsCommissions')
const PaymentsWallet = () => import(/* webpackChunkName: "authed" */ '@/views/Payments/PaymentsWallet')

// const Tools = () => import(/* webpackChunkName: "authed" */'@/views/Tools/Tools')
// const ToolsFacebook = () => import(/* webpackChunkName: "authed" */'@/views/Tools/ToolsFacebook')

const Profile = () => import(/* webpackChunkName: "authed" */ '@/views/Profile')
const PackagesTable = () => import(/* webpackChunkName: "authed" */ '@/views/PackagesTable')
const PackagesList = () => import(/* webpackChunkName: "authed" */ '@/views/PackagesList')

const MessengerIndex = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/Index')
const ChatSidebar = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/chat/ChatSidebar')
const ChatInfoHeader = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/chat/ChatInfoHeader')
const ChatInfoHeaderMatrix = () =>
  import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/chat/ChatInfoHeaderMatrix')
const ChatView = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/chat/ChatView')
const ChatViewMatrix = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/chat/ChatViewMatrix')

const BlogSidebar = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/blogs/BlogSidebar')
const BlogInfoHeader = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/blogs/BlogInfoHeader')
const BlogIndex = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/blogs/BlogIndex')
const BlogView = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/blogs/BlogView')
const BlogPostView = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/blogs/BlogPostView')
const BlogEditPost = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/blogs/BlogEditPost')

const CalloutsList = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/callouts/CalloutsList')
const CalloutPost = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/callouts/CalloutPost')
const CalloutPostHeader = () =>
  import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/callouts/CalloutPostHeader')

const BlogBookmarks = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/blogs/BlogBookmarks')

const SocialIndex = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/social/SocialIndex')
const SocialCreateProfile = () =>
  import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/social/SocialCreateProfile')
const SocialEditProfile = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/social/SocialEditProfile')
const SocialInfoHeader = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/social/SocialInfoHeader')
// const SocialView = () => import(/* webpackChunkName: "chat-app" */'@/views/Messenger/social/SocialView')
const SocialPostList = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/social/SocialPostList')
const SocialPostView = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/social/SocialPostView')
const SocialCreatePost = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/social/SocialCreatePost')
const SocialEditPost = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/social/SocialEditPost')

const MoodInfoHeader = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/mood/MoodInfoHeader')
const MoodView = () => import(/* webpackChunkName: "chat-app" */ '@/views/Messenger/mood/MoodView')
const BDTLStore = () => import(/* webpackChunkName: "chat-app" */ '@/views/BDTLStore')
const BDTLCabinet = () => import(/* webpackChunkName: "chat-app" */ '@/views/BDTLCabinet')

const NotFound = () => import(/* webpackChunkName: "chat-app" */ '@/views/NotFound')
const WalletTransactions = () => import(/* webpackChunkName: "chat-app" */ '@/views/Payments/PaymentsTransactions')
const WalletSettings = () => import(/* webpackChunkName: "chat-app" */ '@/views/Payments/WalletSettings')
const WalletSendFunds = () => import(/* webpackChunkName: "chat-app" */ '@/views/Payments/WalletSendFunds')
const WalletReceiveFunds = () => import(/* webpackChunkName: "chat-app" */ '@/views/Payments/WalletReceiveFunds')
const WalletAddFunds = () => import(/* webpackChunkName: "chat-app" */ '@/views/Payments/WalletAddFunds')

Vue.use(VueRouter)

const beforeAuthEnter = async (to, from, next) => {
  if (store.getters['auth/isLogged'] && !to.query.token) {
    next({ name: 'profile' })
  } else if (store.getters['auth/isLogged'] && to.query.token) {
    next({ name: 'payments-sponsor' })
  } else {
    next()
  }
}
const beforeMainEnter = async (to, from, next) => {
  if (!store.getters['auth/isLogged']) {
    if (to.meta.blogLandingOnUnauth) {
      const query = { id: to.params.blogId }
      if (to.params.postId) {
        query.postId = to.params.postId
      }
      next({ name: 'sign-in-blog', query })
    } else if (to.meta.socialLandingOnUnauth) {
      const query = { profile: to.params.nickname }
      if (to.params.postId) {
        query.postId = to.params.postId
      }
      next({ name: 'sign-in-social', query })
    } else {
      next({ name: 'AuthDashboard', query: { redirect: to.fullPath } })
    }
  } else {
    if (to.name !== 'profile' && !store.getters['auth/user'].nickname) {
      next({ name: 'profile' })
    } else {
      next()
    }
  }
}
const beforeEach = (to, from, next) => {
  if (to.meta.requiresAuth) {
    beforeMainEnter(to, from, next)
  } else if (to.meta.requiresUnauth) {
    beforeAuthEnter(to, from, next)
  } else {
    next()
  }
}

const routes = [
  {
    path: '',
    name: 'index',
    redirect: {
      name: 'AuthDashboard'
    }
    // meta: { requiresUnauth: true },
    // component: Index
  },
  {
    path: '/auth',
    name: 'AuthDashboard',
    component: () => import('@/views/Auth/Dashboard.vue')
  },
  {
    path: '/open',
    name: 'open-link',
    component: OpenLink
  },
  {
    path: '/registration',
    name: 'registration',
    // component: AuthRegistration,
    // props: true,
    redirect: {
      name: 'sign-in'
    }
  },
  {
    path: '/',
    component: AuthRoute,
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: { requiresAuth: true }
      },
      {
        path: 'bdtl-store',
        name: 'bdtl-store',
        component: BDTLStore,
        meta: { requiresAuth: true }
      },
      {
        path: 'bdtl-cabinet',
        name: 'bdtl-cabinet',
        component: BDTLCabinet,
        meta: { requiresAuth: true }
      },
      {
        path: 'packages',
        name: 'packages-table',
        component: PackagesTable
      },
      {
        path: 'package/:packageId',
        name: 'auth-package',
        props: route => ({ packageId: Number(route.params.packageId) }),
        component: Package,
        meta: { requiresAuth: true }
      },
      {
        path: 'payment/:packageId',
        name: 'auth-payment',
        props: route => ({ packageId: Number(route.params.packageId) }),
        component: Payment
      },
      {
        path: 'wallet',
        redirect: { name: 'wallet-transactions' },
        name: 'payments-wallet',
        component: PaymentsWallet,
        children: [
          {
            path: 'transactions',
            name: 'wallet-transactions',
            component: WalletTransactions
          },
          {
            path: 'settings',
            name: 'wallet-settings',
            component: WalletSettings,
            meta: { requiresAuth: true }
          },
          {
            path: 'send-funds',
            name: 'wallet-send-funds',
            component: WalletSendFunds,
            meta: { requiresAuth: true }
          },
          {
            path: 'receive-funds',
            name: 'wallet-receive-funds',
            component: WalletReceiveFunds,
            meta: { requiresAuth: true }
          },
          {
            path: 'add-funds',
            name: 'wallet-add-funds',
            component: WalletAddFunds,
            meta: { requiresAuth: true }
          }
        ]
      },
      // {
      //   path: 'affiliate',
      //   name: 'affiliate',
      //   component: Affiliate
      // }
      {
        path: 'payments',
        redirect: { name: 'payments-sponsor' },
        component: Payments,
        children: [
          {
            path: 'sponsor',
            name: 'payments-sponsor',
            component: PaymentsSponsor,
            meta: { requiresAuth: true }
          },
          {
            path: 'commissions',
            name: 'payments-commissions',
            component: PaymentsCommissions,
            meta: { requiresAuth: true }
          }
        ]
      },
      // {
      //   path: 'tools',
      //   redirect: { name: 'tools-facebook' },
      //   component: Tools,
      //   children: [
      //     {
      //       path: 'facebook',
      //       name: 'tools-facebook',
      //       component: ToolsFacebook
      //     }
      //   ]
      // },
      {
        path: 'support',
        name: 'support',
        meta: { requiresAuth: true },
        component: Support
      },
      // {
      //   path: 'non-for-profit',
      //   name: 'non-for-profit',
      //   component: NonForProfit
      // },
      // {
      //   path: 'influencer',
      //   name: 'influencer',
      //   component: Influencer
      // },
      {
        path: 'chat', // 'app',
        component: MessengerIndex,
        redirect: 'chat/hola',
        children: [
          {
            path: 'bridge', // ':activeChatId?',
            name: 'app-chat-matrix',
            meta: { requiresAuth: true },
            props: { default: true },
            components: {
              sidebar: ChatSidebar,
              infoHeader: ChatInfoHeaderMatrix,
              default: ChatViewMatrix
            }
          },
          {
            path: 'hola', // ':activeChatId?',
            name: 'app-chat',
            meta: { requiresAuth: true },
            props: { default: true },
            components: {
              sidebar: ChatSidebar,
              infoHeader: ChatInfoHeader,
              default: ChatView
            }
          },

          {
            path: '/callouts',
            name: 'callouts',
            meta: { requiresAuth: true },
            components: {
              sidebar: ChatSidebar,
              default: CalloutsList
            }
          },
          {
            path: '/callouts/:id',
            // props: (route) => ({ id: Number(route.params.id) }),
            props: { default: true, sidebar: false, infoHeader: true },
            name: 'callouts-post',
            meta: { requiresAuth: true },
            components: {
              sidebar: ChatSidebar,
              infoHeader: CalloutPostHeader,
              default: CalloutPost
            }
          },
          {
            path: '/blogs/:blogId?',
            meta: { requiresAuth: true },
            props: { default: true, sidebar: true, infoHeader: true },
            components: {
              sidebar: BlogSidebar,
              infoHeader: BlogInfoHeader,
              default: BlogIndex
            },
            children: [
              {
                path: '',
                meta: {
                  requiresAuth: true,
                  blogLandingOnUnauth: true
                },
                props: true,
                name: 'app-blogs',
                component: BlogView
              },
              {
                path: ':postId',
                meta: {
                  requiresAuth: true,
                  blogLandingOnUnauth: true
                },
                props: true,
                name: 'app-blogs-post',
                component: BlogPostView
              },
              {
                path: ':postId/edit',
                meta: { requiresAuth: true },
                props: true,
                name: 'app-blogs-post-edit',
                component: BlogEditPost
              }
            ]
          },
          {
            path: '/bookmarks',
            meta: { requiresAuth: true },
            name: 'app-blogs-bookmarks',
            components: {
              sidebar: BlogSidebar,
              default: BlogBookmarks
            }
          },
          {
            path: '/social/create-profile',
            name: 'social-create-profile',
            props: { default: true, sidebar: true, infoHeader: true },
            components: {
              sidebar: BlogSidebar,
              default: SocialCreateProfile
            }
          },
          // {
          //   path: '/social/:profileNickname',
          //   props: { default: true, sidebar: true, infoHeader: true },
          //   components: {
          //     sidebar: BlogSidebar,
          //     // infoHeader: SocialInfoHeader,
          //     default: SocialPostList
          //   }
          // },
          {
            path: '/social/:profileNickname?',
            props: { default: true, sidebar: true, infoHeader: true },
            components: {
              sidebar: BlogSidebar,
              infoHeader: SocialInfoHeader,
              default: SocialIndex
            },
            children: [
              {
                path: '',
                meta: {
                  requiresAuth: true,
                  socialLandingOnUnauth: true
                },
                props: true,
                name: 'social',
                component: SocialPostList // SocialView
              },
              {
                path: 'edit',
                meta: {
                  requiresAuth: true
                },
                props: true,
                name: 'social-edit-profile',
                component: SocialEditProfile
              },
              {
                path: 'new-post',
                meta: {
                  requiresAuth: true
                },
                props: true,
                name: 'social-new-post',
                component: SocialCreatePost
              },
              {
                path: ':postId',
                meta: {
                  requiresAuth: true,
                  socialLandingOnUnauth: true
                },
                props: route => ({
                  profileNickname: route.params.profileNickname,
                  postId: Number(route.params.postId)
                }),
                name: 'social-post',
                component: SocialPostView
              },
              {
                path: ':postId/edit',
                meta: {
                  requiresAuth: true,
                  socialLandingOnUnauth: true
                },
                props: route => ({
                  profileNickname: route.params.profileNickname,
                  postId: Number(route.params.postId)
                }),
                name: 'social-post-edit',
                component: SocialEditPost
              }
            ]
          },
          {
            path: '/mood',
            meta: { requiresAuth: true },
            name: 'mood',
            components: {
              sidebar: ChatSidebar,
              infoHeader: MoodInfoHeader,
              default: MoodView
            }
          }
        ]
      }
    ]
  },
  {
    path: '/packages-list',
    name: 'packages-list',
    meta: { requiresUnauth: true },
    component: PackagesList
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '*',
    redirect: {
      name: 'not-found'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
router.beforeEach(beforeEach)

export default router
