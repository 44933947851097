import {
  isToday,
  format as formatFNS,
  formatDistanceToNow as formatDistanceToNowFNS,
  formatDistanceStrict as formatDistanceStrictFNS,
  isThisWeek,
  isThisYear
} from 'date-fns'
import { enUS, de, es, fr, ja, pt, ru, zhCN } from 'date-fns/locale'
import i18n from '@/i18n'

const locales = { en: enUS, de, es, fr, ja, pt, ru, 'zh-hans': zhCN }

export function format (date, formatStr = 'PP', options) {
  date = new Date(date)
  return formatFNS(date, formatStr, {
    locale: locales[i18n.locale],
    ...options
  })
}

export function formatDistanceToNow (date, options) {
  date = new Date(date)
  return formatDistanceToNowFNS(date, {
    locale: locales[i18n.locale],
    ...options
  })
}

export function formatDistanceStrict (date, baseDate, options) {
  date = new Date(date)
  return formatDistanceStrictFNS(date, baseDate, {
    locale: locales[i18n.locale],
    ...options
  })
}

export function formatMessageListDate (date) {
  // const date = new Date(timestamp)
  if (isToday(date)) {
    return format(date, 'h:mm a')
  }
  const isLast7Days = isThisWeek(date, { weekStartsOn: date.getDay() })
  if (isLast7Days) {
    return format(date, 'ccc')
  }
  if (isThisYear(date)) {
    return format(date, 'd MMM')
  }
  return format(date, 'P')
}
