import { Model } from '@vuex-orm/core'

export default class Callout extends Model {
  static entity = 'calloutProfiles'
  static primaryKey = 'uuid'

  static fields () {
    return {
      uuid: this.string(''),
      name: this.string(''),
      description: this.string(''),
      logo: this.string(''),
      // status: this.number(0),
      createdAt: this.string(''),
      updatedAt: this.string(''),
      isSubscribed: this.boolean(false)
    }
  }
}
