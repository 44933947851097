<template>
  <modal
    v-bind="$attrs"
    v-on="$listeners"
    class="base-modal"
    :name="name"
    width="450"
    height="auto"
    adaptive
    scrollable
  >
    <BaseIcon
      custom name="close"
      class="base-modal__close-btn"
      @click="$modal.hide(name)"
    />

    <BaseIcon v-if="icon" class="base-modal__icon" :name="icon" />
    <h1 v-if="title" class="base-modal__title">{{ title }}</h1>

    <slot />
  </modal>
</template>

<script>
export default {
  name: 'base-modal',
  props: {
    name: String,
    icon: [String, Array],
    title: String
  }
}
</script>

<style lang="scss">
.base-modal .v--modal {
  padding: 50px;
  background: $main-bg-light;
  box-shadow: 0 3px 11px 1px rgba(0, 0, 0, 0.15);
  border: 1px solid $border-color;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  border-radius: 0;
}
.base-modal {
  &__close-btn {
    cursor: pointer;
    color: $text-dark;
    font-size: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: color .3s;
    &:hover {
      color: $text-darkest;
    }
  }
  &__title {
    padding-bottom: 5px;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    color: $text-colored;
  }
  &__icon {
    font-size: 70px;
    color: $text-success;
  }
}

</style>
