import Vue from 'vue'
import api from '@/api/chats'
const state = {
  chats: [],
  currentChats: [],
  isLoadingFilters: false,
  isLoadingChats: false,
  filters: []
}

const getters = {
  chats: state => state.chats,
  currentChats: state => state.currentChats,
  filters: state => state.filters,
  isLoadingChats: state => state.isLoadingChats,
  isLoadingFilters: state => state.isLoadingFilters
}

const actions = {
  async fetchFilters ({ commit }) {
    try {
      commit('changeLoadingFilters', true)
      const { data } = await api.getFilters()
      commit('setFilters', data)
    } catch (err) {
      throw err.response
    } finally {
      commit('changeLoadingFilters', false)
    }
  },
  async createFilter ({ commit }, payload) {
    try {
      commit('changeLoadingFilters', true)
      const { data } = await api.createFilter(payload)
      commit('addFilter', data)
    } catch (err) {
      throw err.response
    } finally {
      commit('changeLoadingFilters', false)
    }
  },
  async updateFilter ({ commit }, payload) {
    try {
      commit('changeLoadingFilters', true)
      const { data } = await api.updateFilter(payload)
      commit('replaceFilter', { id: payload.id, ...data })
    } catch (err) {
      throw err.response
    } finally {
      commit('changeLoadingFilters', false)
    }
  },
  async deleteFilter ({ commit }, payload) {
    try {
      commit('changeLoadingFilters', true)
      await api.deleteFilter(payload)
      commit('removeFilter', payload)
    } catch (err) {
      throw err.response
    } finally {
      commit('changeLoadingFilters', false)
    }
  },
  async getFilterChats ({ commit }) {
    try {
      commit('changeLoadingChats', true)
      const { data } = await api.getFilterChats()
      commit('setCurrentChats', data)
    } catch (err) {
      throw err.response
    } finally {
      commit('changeLoadingChats', false)
    }
  },
  async addChatToFilter ({ commit }, payload) {
    try {
      commit('changeLoadingChats', true)
      const { data } = await api.addChatToFilter(payload)
      commit('addItemToChats', data)
    } catch (err) {
      throw err.response
    } finally {
      commit('changeLoadingChats', false)
    }
  },
  async deleteChatFromFilter ({ commit }, payload) {
    try {
      commit('changeLoadingChats', true)
      await api.deleteChatFromFilter(payload)
      commit('removeItemFromChats', payload)
    } catch (err) {
      throw err.response
    } finally {
      commit('changeLoadingChats', false)
    }
  }
}

const mutations = {
  setChats (state, data) {
    state.chats = data
  },
  setCurrentChats (state, data) {
    state.currentChats = data
  },
  setFilters (state, data) {
    state.filters = data
  },
  changeLoadingChats (state, status) {
    state.isLoadingChats = status
  },
  changeLoadingFilters (state, status) {
    state.isLoadingFilters = status
  },
  addFilter (state, data) {
    state.filters.push(data)
  },
  replaceFilter (state, data) {
    const index = state.filters.findIndex(e => e.id === data.id)
    Vue.set(state.filters, index, data)
  },
  removeFilter (state, { id }) {
    const index = state.filters.findIndex(e => e.id === id)
    Vue.delete(state.filters, index)
  },
  removeItemFromChats (state, { chatId }) {
    const index = state.currentChats.findIndex(e => e.chatId === chatId)
    Vue.delete(state.currentChats, index)
  },
  addItemToChats (state, data) {
    state.chats.push(data)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
