<template>
  <modal
    :class="$style.wrapper"
    :name="name"
    :width="width"
    :height="height"
    :scrollable="scrollable"
    transition="modal-fade"
    reset
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div :class="$style.header">
      <h1 v-if="title" :class="$style.title">{{ title }}</h1>
      <BaseIcon custom name="close" @click="$modal.hide(name)" :class="$style.close" />
    </div>
    <slot name="default" />
    <div v-if="$slots.footer" :class="$style.footer">
      <slot name="footer" />
    </div>
  </modal>
</template>

<script>
export default {
  name: 'chat-modal',
  props: {
    name: String,
    title: String,
    scrollable: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '450'
    },
    height: {
      type: String,
      default: 'auto'
    }
  }
}
</script>

<style lang="scss" module>
.wrapper :global(.v--modal) {
  padding: 0;
  background: $main-bg-light;
  box-shadow: 0 3px 11px 1px rgba(0, 0, 0, 0.15);
  // border: 1px solid $border-color;
  font-weight: 400;
  border-radius: 0;
  //min-height: 400px;
}
.header {
  color: $text-lightest;
  height: 50px;
  background-color: $main-bg-colored;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.title {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.close {
  cursor: pointer;
  font-size: 16px;
}
.footer {
  display: flex;
  justify-content: center;
  border-top: 1px solid $border-color;
  padding: 24px 0;
}
</style>
