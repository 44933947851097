import api from '@/api'
import MoodMessage from '@/models/MoodMessage'
import User from '@/models/User'
import { uniq } from 'lodash'

const getters = {
  list: () => MoodMessage.query().with('sender').orderBy('createdTs', 'acs').get()
}

const actions = {
  async fetch (_, { limit = 20, page = 0 } = {}) {
    const { data } = await api.chats.getMood({ limit, page })
    MoodMessage.insert({ data })
    await User.dispatch('getProfiles', uniq(data.map(obj => obj.senderId)))
    return data
  }
}

export default {
  getters,
  actions
}
