<template>
  <modal
    :name="$options.name"
    :width="800"
    height="auto"
    class="offer-users__modal"
    @before-open="beforeOpen"
    @before-close="beforeClose">
    <div class="offer-users">
      <div class="offer-users__close" @click="closeModal">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.171 241.171"><path d="M138.138 120.754l99.118-98.576a11.931 11.931 0 000-17.011c-4.74-4.704-12.439-4.704-17.179 0l-99.033 98.492-99.949-99.96c-4.74-4.752-12.439-4.752-17.179 0-4.74 4.764-4.74 12.475 0 17.227l99.876 99.888L3.555 220.497c-4.74 4.704-4.74 12.319 0 17.011 4.74 4.704 12.439 4.704 17.179 0l100.152-99.599 99.551 99.563c4.74 4.752 12.439 4.752 17.179 0 4.74-4.764 4.74-12.475 0-17.227l-99.478-99.491z"></path></svg>
      </div>
      <div class="offer-users__content">
        <div class="offer-users__table">
          <vue-good-table
            :columns="columns"
            :fixed-header="true"
            :isLoading="loading"
            theme="polar-bear"
            :pagination-options="{
             enabled: true,
             mode: 'records'
             }"
            :rows="BDTLOfferUserList">
            <template slot="loadingContent">&nbsp;</template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'bdtl-offers-user-list',
  data () {
    return {
      columns: [
        {
          label: 'Phone',
          field: 'phone'
        },
        {
          label: 'Username',
          field: 'nickname'
        },
        {
          label: 'First Name',
          field: 'firstName'
        },
        {
          label: 'Last Name',
          field: 'lastName'
        },
        {
          label: 'User id',
          field: 'userId'
        }
      ],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      BDTLOfferUserList: 'agents/BDTLOfferUserList'
    })
  },
  methods: {
    ...mapActions({
      getBDTLOfferUserList: 'agents/getBDTLOfferUserList'
    }),
    beforeOpen ({ params: { item } }) {
      this.getList(item)
    },
    async getList (item) {
      try {
        this.loading = true
        await this.getBDTLOfferUserList(item.userIds)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    beforeClose () {
    },
    closeModal () {
      this.$modal.hide(this.$options.name)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
<style lang="scss">
.vgt-table.polar-bear {
  font-size: 14px !important;
}
.vgt-loading__content {
  background-color: #00b764 !important;
  color: white !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.vgt-inner-wrap.is-loading {
  opacity: 0.2 !important;
  filter: blur(3px);
}
.vgt-wrap__footer .footer__row-count__label,
.vgt-wrap__footer .footer__navigation__page-btn span,
.vgt-wrap__footer .footer__navigation {
  font-size: 12px !important;
}
.vgt-wrap__footer {
  font-size: 12px !important;
  padding: 10px !important;
}
.vgt-table thead th {
  font-size: 12px;
  padding: 5px 20px 5px 5px;
  vertical-align: middle;
}
.offer-users {
  padding: 35px;
  background: white;
  position: relative;
  &__modal {
    background: rgba(0,0,0, 0.5) !important;
    .v--modal{
      @include respondTo(800px) {
        max-width: 95%;
        width: 95% !important;
        margin: 0 auto;
        left: 0 !important;
      }
    }
  }
  &__table {
    min-height: 200px;
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 8px;
      position: absolute;
      background: rgba(17, 17, 17, 0.1);
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      position: absolute;
      z-index: -2
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(17, 17, 17, 0.4);
      border-radius: 5px;
    }
  }
  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 2;
    cursor: pointer;
    svg {
      width: 15px;
      height: 15px;
    }
  }
}
</style>
