import api from '@/api/tenor'

const state = {
  list: []
}

const getters = {
  list: state => state.list
}

const actions = {
  async fetchList ({ commit }, payload = {}) {
    const { data } = await api.getGifs(payload)
    commit('SET_LIST', data)
  }
}

const mutations = {
  SET_LIST (state, list) {
    state.list = list
  }
}
export default { state, getters, actions, mutations, namespaced: true }
