import apiReq from './request'
import axios from 'axios'
import { eventTypes } from '@/helpers/variables'

const HOLA_CHAT_API = process.env.VUE_APP_CHAT_URL
const BASE_URL = `${process.env.VUE_APP_MATRIX_URL}/_matrix/client/r0`

export default {
  getMatrixCredentials () {
    return apiReq.get(`${HOLA_CHAT_API}/chat/matrix`)
  },
  saveMatrixCredentials (payload) {
    return apiReq.post(`${HOLA_CHAT_API}/chat/matrix`, payload)
  },
  updateMatrixCredentials (payload) {
    return apiReq.put(`${HOLA_CHAT_API}/chat/matrix`, payload)
  },
  getEventById ({ roomId, eventId }) {
    return axios({
      method: 'get',
      url: `${BASE_URL}/rooms/${roomId}/event/${eventId}`,
      headers: {
        'authorization': `Bearer ${window.matrixSDK.getAccessToken()}`
      }
    })
  },
  getMessages ({ roomId, from }) {
    const filters = JSON.stringify({ types: [eventTypes.message, eventTypes.member, eventTypes.avatar, eventTypes.name] })
    return axios({
      method: 'get',
      url: `${BASE_URL}/rooms/${roomId}/messages?limit=20&dir=b&from=${from}&filter=${filters}`,
      headers: {
        'authorization': `Bearer ${window.matrixSDK.getAccessToken()}`
      }
    })
  }
}
