import axios from 'axios'

const API = process.env.VUE_APP_TENOR_API
const API_KEY = process.env.VUE_APP_TENOR_KEY
const clientKey = 'hola'

export default {
  async getGifs ({ q = '', limit = 10, type = 'featured', searchFilter = '', pos = '' }) {
    return axios({
      method: 'get',
      url: `${API}/${type}?q=${q}&key=${API_KEY}&limit=${limit}&client_key=${clientKey}&searchfilter=${searchFilter}&pos=${pos}`
    })
  }
}
