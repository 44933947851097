<template>
  <modal name="user-info" class="user-info__popup" :clickToClose="false" height="auto" :width="550" adaptive scrollable>
    <div class="user-info">
      <form @submit.prevent="onSubmit" class="user-info__form">
        <div class="user-info__title">
          {{ $t('fillUserInfo') }}
        </div>
        <div class="form--row">
          <BaseInput
            v-model="form.firstName"
            :label="$t('formFirstName')"
            :invalid="$v.form.firstName.$error"
            :error="$t('errorEmptyField')"
          />
          <BaseInput
            v-model="form.lastName"
            :label="$t('formLastName')"
            :invalid="$v.form.lastName.$error"
            :error="$t('errorEmptyField')"
          />
        </div>
        <p class="email-info">
          <BaseIcon name="exclamation-triangle" />
          {{ $t('profileEmailInfo') }}
        </p>
        <BaseInput
          v-model="form.email"
          :label="$t('formEmail') + '*'"
          :invalid="$v.form.email.$error"
          :error="emailError"
          @input="errors = {}"
        />
        <div class="names">
          <BaseInput
            v-model="form.nickname"
            :label="$t('formNickName') + '*'"
            :invalid="$v.form.nickname.$error"
            :error="nicknameError"
            class="nickname"
            @input="normalizeNickname"
          />
        </div>
        <div class="user-info__message">* {{ $t('requiredFields') }}</div>
        <BaseButton
          :disabled="this.$v.form.$invalid || errorUniqNickname"
          :loading="submitting || checkNicknameLoading"
          class="action"
          >{{ $t('buttonSave') }}
        </BaseButton>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required, email, helpers } from 'vuelidate/lib/validators'
import { debounce } from 'lodash'
const alphaNumHyphenUnderscoreValidator = helpers.regex('alphaNumAndDot', /^[-a-zA-Z0-9_]+$/i)

export default {
  name: 'user-info',
  components: {},
  data () {
    return {
      form: {
        firstName: '',
        lastName: '',
        nickname: '',
        email: '',
        phone: ''
      },
      submitting: false,
      errors: {},
      errorUniqNickname: false,
      checkNicknameLoading: false
    }
  },
  validations: {
    form: {
      firstName: {
        server () {
          return !this.errors.first_name
        }
      },
      lastName: {
        server () {
          return !this.errors.last_name
        }
      },
      nickname: {
        required,
        alphaNumHyphenUnderscoreValidator,
        uniq () {
          return !this.errorUniqNickname
        },
        server () {
          return !this.errors.nickname
        }
      },
      email: {
        required,
        email,
        server () {
          return !this.errors.email
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isLogged: 'auth/isLogged'
    }),
    emailError () {
      if (!this.$v.form.email.required) {
        return this.$t('errorEmptyField')
      } else if (!this.$v.form.email.email) {
        return this.$t('errorEmailInvalid')
      } else if (!this.$v.form.email.server) {
        return this.$t('error_email_exists')
      }
      return this.$t('errorEmptyField')
    },
    nicknameError () {
      if (!this.$v.form.nickname.required) {
        return this.$t('errorEmptyField')
      } else if (!this.$v.form.nickname.alphaNumHyphenUnderscoreValidator) {
        return this.$t('errorAlphanumericDashUnderscore')
      } else if (!this.$v.form.nickname.uniq) {
        return this.$t('socialProfile.errorNicknameExists')
      }
      return this.$t('errorEmptyField')
    }
  },
  watch: {
    user: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.resetForm()
        }
      }
    }
  },
  created () {
    if (this.isLogged) {
      this.resetForm()
    }
  },
  methods: {
    ...mapActions({
      checkUsername: 'entities/users/checkUsername',
      updateProfile: 'entities/users/saveProfile',
      getProfile: 'entities/users/getMyProfile'
    }),
    resetForm () {
      this.$v.form.$reset()
      Object.keys(this.form).forEach(key => {
        this.form[key] = this.user[key]
      })
    },
    clearForm () {
      this.$v.form.$reset()
      this.form.email = ''
      this.form.firstName = ''
      this.form.lastName = ''
      this.form.nickname = ''
    },
    normalizeNickname (e) {
      this.form.nickname = this.form.nickname.toLowerCase()
      if (this.form.nickname.length) {
        this.checkNicknameLoading = true
        this.changeValidationUserName()
      }
    },
    changeValidationUserName: debounce(function () {
      this.checkUsername(this.form.nickname)
        .then(response => {
          this.errorUniqNickname = response.data.info !== 'Nickname is unique'
        })
        .finally(() => {
          this.checkNicknameLoading = false
          this.$v.form.nickname.$touch()
        })
    }, 500),
    async onSubmit () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid || this.submitting) return
      this.submitting = true
      try {
        const obj = {}
        if (this.form.firstName) {
          obj.firstName = this.form.firstName
        }
        if (this.form.lastName) {
          obj.lastName = this.form.lastName
        }
        obj.email = this.form.email
        obj.nickname = this.form.nickname
        await this.updateProfile(obj)
        this.$modal.hide('user-info')
        setTimeout(() => {
          this.$modal.show('success')
        }, 200)
      } catch (error) {
        const { data } = error.response.data
        if (data.field === 'nickname' && data.type === 'exists') {
          this.$set(this.errors, data.field, this.$t('socialProfile.errorNicknameExists'))
        } else {
          this.$set(this.errors, data.field, this.$t(`error_${data.field}_${data.type}`))
        }
        // this.error = this.$t('error')
      } finally {
        this.submitting = false
      }
    }
  },
  destroyed () {
    this.clearForm()
  }
}
</script>
<style lang="scss">
.user-info__popup {
  background: rgba(0, 0, 0, 0.5) !important;
  .v--modal {
    @include respondTo(600px) {
      max-width: 95%;
      margin: 0 auto;
    }
  }
}
</style>
<style lang="scss" scoped>
.user-info {
  padding: 30px 15px;
  @include respondTo(600px) {
    padding: 20px 15px;
  }
  &__title {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
    @include respondTo(600px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .email-info {
    color: #9c9c9c;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
  }
  &__message {
    font-size: 12px;
    line-height: 1.2;
    font-weight: 500;
    color: #9c9c9c;
  }
  .form--button.button--disabled {
    pointer-events: none;
    user-select: none;
  }
}
</style>
