<template>
  <div :class="[$style.container, {'verify':isVerifyOtpPage}]"
        class="auth-container__wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  data () {
    return {
      isVerifyOtpPage: false
    }
  },
  created () {
    this.isVerifyOtpPage = this.$route.name === 'verify-otp'
  }
}
</script>

<style module lang="scss">
.container {
  color: $text-mid;
  margin: 0 auto;
  width: 630px;
  position: relative;
  padding: 20px 30px 40px;
  @include respondTo(650px){
    width: 100%;
    padding: 20px 10px;
  }
}
</style>
