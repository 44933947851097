import apiReq from './request'

const BASE_URL = `${process.env.VUE_APP_APIGW_URL}/payment`

export default {
  getPackageList (language) {
    return apiReq.get(`${BASE_URL}/user/packages?count=50`, {
      params: {
        language
      }
    })
  },
  buyPackage (data) {
    return apiReq.post(`${BASE_URL}/user/packages`, data)
  }
}
