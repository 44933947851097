<template>
  <ChatModal
    :name="$options.name"
    :title="modalTitle"
    @before-open="beforeOpen"
  >
    <div :class="$style.form">
      <BaseInput
        v-model="title"
        :placeholder="$t('userStatus.titlePlaceholder')"
      />
        <!-- :invalid="$v.title.$error"
        :error="titleError"
        @blur="$v.title.$touch" -->
      <BaseInput
        :placeholder="$t('userStatus.textPlaceholder')"
        v-model="text"
        multiline rows="3"
        :invalid="$v.text.$error"
        :error="$t('errorEmptyField')"
        @blur="$v.text.$touch"
      />
      <div class="form-container">
        <!-- <label class="form--label">{{ $t('userStatus.formMedia') }}</label> -->
        <FileInput
          v-if="!mediaSrc"
          :label="$t('userStatus.attachMedia')"
          accept="image/*,video/*"
          @input="onFileChange"
          :class="$style.fileInput"
        >
          <BaseIcon name="paperclip" :class="$style.fileIcon" />
        </FileInput>
        <MediaPreview
          :src="mediaSrc"
          @close="mediaSrc = null"
          v-viewer
        />

        <BaseButton :loading="loading" @click="onSubmit">{{ submitBtnTxt }}</BaseButton>
      </div>
    </div>
  </ChatModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChatModal from '../ChatModal'
import imageCompression from 'browser-image-compression'
import MediaPreview from '@/components/MediaPreview'
import { required } from 'vuelidate/lib/validators'
import FileInput from '@/components/FileInput'

export default {
  name: 'create-status',
  components: {
    ChatModal,
    MediaPreview,
    FileInput
  },
  data () {
    return {
      title: '',
      text: '',
      mediaSrc: null,
      mediaFile: null,
      loading: false
      // videoSrc: ''
    }
  },
  computed: {
    ...mapGetters({
      // statusList: 'entities/userStatuses/list',
      // currentStatus: 'entities/userStatuses/myCurrentStatus'
    }),
    modalTitle () {
      return this.$t('userStatus.tabCreateStatus')
    },
    submitBtnTxt () {
      return this.$t('userStatus.btnAdd')
    }
  },
  validations: {
    text: { required },
    mediaSrc: { required }
  },
  methods: {
    beforeOpen () {
      this.resetForm()
    },
    resetForm () {
      this.$v.$reset()
      this.loading = false
      this.currentTab = 'set'
      this.title = ''
      this.text = ''
      this.mediaSrc = null
      this.mediaFile = null
    },
    ...mapActions({
      // fetchStatuses: 'entities/userStatuses/fetchStatuses',
      createStatus: 'entities/userStatuses/createStatus'
      // setStatus: 'entities/userStatuses/setStatus'
    }),
    // async onSet (id) {
    //   await this.setStatus(id)
    //   this.$modal.hide(this.$options.name)
    // },
    // isStatusActive (id) {
    //   return this.currentStatus && (id === this.currentStatus.id) && !this.isCurrentStatusExpired
    // },
    async onSubmit () {
      this.$v.$touch()
      if (this.loading || this.$v.$invalid) return
      const data = {
        mediaType: 'IMAGE',
        title: this.title,
        statusText: this.text,
        mediaFile: this.mediaFile
      }
      try {
        this.loading = true
        await this.createStatus(data)
        this.resetForm()
      } catch (e) {
        const { data } = e.response.data
        this.$modal.show('error', { error: data })
      } finally {
        this.loading = false
      }
    },
    async onFileChange (file) {
      // check file is it image or video
      this.$modal.show('cropper', {
        aspectRatio: 1,
        file,
        onDone: async canvas => {
          this.mediaSrc = canvas.toDataURL('image/jpeg')
          const blob = await imageCompression.getFilefromDataUrl(this.mediaSrc, 'status-picture')
          const compressedBlob = await imageCompression(blob, {
            maxSizeMB: 0.1
          })
          this.mediaFile = new File([compressedBlob], `${compressedBlob.name}.${compressedBlob.type.split('/')[1]}`)
        }
      })
    }
  }
}
</script>

<style module lang="scss">
// .container {
//   padding: 14px 30px 0;
// }
.list {
  height: 360px;
  overflow-y: scroll;
}
.form {
  padding: 14px 30px;
}
.item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
  border-bottom: 1px solid $border-color;
  &.active {
    background-color: rgba($main-bg-colored, 0.3);
  }
}
.mediaWrap {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  overflow: hidden;
  flex-shrink: 0;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.info {
  margin-left: 16px;
  color: $text-darkest;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.file {
  margin-bottom: 14px;
}
.fileIcon {
  font-size: 18px;
  color: $text-colored;
  margin-right: 4px;
}
</style>
