import apiReq from './request'
import store from '@/store'

const BASE_URL = `${process.env.VUE_APP_APIGW_URL}/push`

export default {
  deviceToken (token) {
    const data = {
      id: store.state.auth.deviceId,
      token,
      type: 'web'
    }
    return apiReq.put(`${BASE_URL}/device_token`, data)
  }
}
