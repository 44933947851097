import { Model } from '@vuex-orm/core'
import User from './User'
import SocialPost from './SocialPost'
import store from '@/store'

export default class SocialComment extends Model {
  static entity = 'socialComments'

  static fields () {
    return {
      id: this.number(0),
      repliesCount: this.number(null).nullable(),
      user: this.number(0),
      userObj: this.belongsTo(User, 'user'),
      createdAt: this.string(''),
      text: this.string(''),
      replyTo: this.number(null).nullable(),
      postId: this.number(0),
      post: this.belongsTo(SocialPost, 'postId')
    }
  }

  get createdAtTs () {
    return +new Date(this.createdAt)
  }

  get isOwn () {
    return this.user === store.getters['auth/user'].id
  }
}
