import ChatSettings from '@/models/ChatSettings'
import api from '@/api'

const actions = {
  async fetchAll () {
    const { data } = await api.chats.getAllChatSettings()
    ChatSettings.$create({ data })
  },
  async fetchByChat (_, chatId) {
    const { data } = await api.chats.getChatSettings(chatId)
    ChatSettings.$create({ data })
  },
  async updateByChat (_, { chatId, enableNotifications, enableTyping, enableRead, enableOnline }) {
    const current = ChatSettings.find(chatId)
    const updated = {
      enableNotifications: enableNotifications != null ? enableNotifications : current.enableNotifications,
      enableTyping: enableTyping != null ? enableTyping : current.enableTyping,
      enableRead: enableRead != null ? enableRead : current.enableRead,
      enableOnline: enableOnline != null ? enableOnline : current.enableOnline
    }
    const { data } = await api.chats.updateChatSettings(chatId, updated)
    ChatSettings.$update({ data })
  }
}

export default {
  actions
}
