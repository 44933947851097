<template>
  <div v-if="src" :class="[$style.container, 'gl-media-preview']">
    <div v-if="isCloseBtn" :class="$style.closeWrap" @click="$emit('close')">
      <BaseIcon
        custom
        name="close"
        :class="$style.close"
      />
    </div>
    <img
      v-if="!isVideo"
      :src="src"
      alt="preview"
      :class="$style.image"
    >
    <template v-else>
      <BaseIcon name="play-circle" :class="$style.play" />
      <video
        :src="src"
        preload="metadata"
        :class="$style.image"
        @click="openVideo"
      ></video>
    </template>
  </div>
</template>

<script>
export default {
  name: 'media-preview',
  props: {
    src: String,
    isVideo: Boolean,
    isCloseBtn: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    openVideo () {
      this.$modal.show('video', { src: this.src })
    }
  }
}
</script>

<style module lang="scss">
.container {
  position: relative;
  max-width: 200px;
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    .closeWrap {
      opacity: 1;
    }
  }
}
.closeWrap {
  opacity: 0;
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba($main-bg-darkest, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  &:hover {
    background-color: rgba($main-bg-darkest, 1);
  }
}
.close {
  font-size: 12px;
  color: $text-lightest;
}
.image {
  max-width: 100%;
  max-height: 100%;
}
.play {
  font-size: 34px;
  color: $text-lightest;
  position: absolute;
  top: calc(50% - 17px);
  left: calc(50% - 17px);
}
</style>
