import api from '@/api'
import Vue from 'vue'

export const TransactionType = {
  WITHDRAW_TO_XPAY: 1,
  COMMISSION: 2,
  PACKAGE_PURCHASE: 3,
  REFILL: 4,
  AFFILIATE: 5,
  BDTL_AGENT: 6,
  TRANSFER_OUTGOING: 7,
  TRANSFER_INCOMING: 8
}

export default {
  state: {
    balance: 0,
    xPayBalance: 0,
    transactions: [],
    walletInfo: null,
    balanceInfo: null,
    walletTokens: []
  },
  mutations: {
    BALANCE (state, balance) {
      state.balance = balance
    },
    SET_XPAY_BALANCE (state, balance) {
      state.xPayBalance = balance
    },
    TRANSACTIONS (state, transactions) {
      state.transactions = transactions
    },
    WALLETINFO (state, data) {
      state.walletInfo = data
    },
    BALANCEINFO (state, data) {
      state.balanceInfo = data
    },
    INCREMENT_TRANSACTION_COUNT (state) {
      state.walletInfo.transactions = state.walletInfo.transactions + 1
    },
    WALLETTOKENS (state, data) {
      state.walletTokens = data
    },
    DELETETOKEN (state, id) {
      const index = state.walletTokens.findIndex(e => e.id === id)
      Vue.delete(state.walletTokens, index)
    },
    ADDTOKEN (state, data) {
      state.walletTokens.push(data)
    }
  },
  getters: {
    balance: state => state.balance,
    xPayBalance: state => state.xPayBalance,
    transactions: state => state.transactions,
    walletInfo: state => state.walletInfo,
    balanceInfo: state => state.balanceInfo,
    walletTokens: state => state.walletTokens
  },
  actions: {
    async fetchBalance ({ commit }) {
      const { data } = await api.wallet.balance()
      commit('BALANCE', data.balance)
      commit('WALLETINFO', data)
    },
    async fetchXpayBalance ({ commit }, payload) {
      const { data } = await api.wallet.xPayBalance(payload)
      commit('SET_XPAY_BALANCE', data)
    },
    async fetchBalanceInfo ({ commit }) {
      const { data } = await api.wallet.fetchBalanceInfo()
      commit('BALANCEINFO', data)
    },
    async fetchTransactions ({ commit }) {
      const { data } = await api.wallet.transactions()
      commit('TRANSACTIONS', data)
    },
    async withdraw ({ commit, dispatch }, amount) {
      const { data } = await api.wallet.withdraw({ amount })
      commit('BALANCE', data.balance)
      await dispatch('fetchTransactions')
    },
    async registerWallet ({ commit }) {
      const result = await api.wallet.registerWallet()
      commit('WALLETINFO', result)
    },
    async createWalletCard ({ commit }, payload) {
      const result = await api.wallet.createWalletCard(payload)
      commit('WALLETINFO', result.data)
    },
    async getUserId ({ commit }, data) {
      const result = await api.wallet.getUserId(data)
      return result
    },
    async getCommission ({ commit }, data) {
      const result = await api.wallet.getCommission(data)
      return result.data
    },
    async addFunds ({ commit }, payload) {
      const result = await api.wallet.addFunds(payload)
      return result
    },
    async transferFunds ({ commit }, params) {
      const { data } = await api.wallet.transferFunds(params)
      commit('WALLETINFO', data)
      commit('BALANCE', data.balance)
    },
    async convertFunds ({ commit }, params) {
      const response = await api.wallet.convertFunds(params)
      return response
    },
    async createWalletToken ({ commit }, params) {
      const { data } = await api.wallet.createWalletToken(params)
      commit('ADDTOKEN', data)
    },
    async getWalletTokens ({ commit }, params) {
      const { data } = await api.wallet.getWalletTokens(params)
      commit('WALLETTOKENS', data)
    },
    async deleteWalletToken ({ commit }, params) {
      await api.wallet.deleteWalletToken(params)
      commit('DELETETOKEN', params.id)
    }
  },
  namespaced: true
}
