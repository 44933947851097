import Chat from '@/models/Chat'
import ChatSettings from '@/models/ChatSettings'
import Message, { TYPE_CODE } from '@/models/Message'
import User from '@/models/User'
import Ws from '@/api/ws'
import { uniq, cloneDeep } from 'lodash'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import { EMOJI } from '@/helpers/variables/index'

const showNotif = messageId => {
  if (!('Notification' in window) || Notification.permission !== 'granted') return
  let message = Message.query().whereId(messageId).with('sender').first()
  if (store.getters['auth/user'].id === message.senderId) return
  if (router.currentRoute.name === 'app-chat' && message.chatId === store.state.entities.chats.activeChatId) return
  const chatSettings = ChatSettings.find(message.chatId)
  if (chatSettings && !chatSettings.enableNotifications) return
  if (message.typeName === 'call') return
  else if (message.typeName === 'forward') {
    message = message.payload
  }
  const title = message.sender ? message.sender.displayName : message.senderId
  const bodyByType = {
    contact: `${EMOJI.contact} ${i18n.t('messageType.contact')}`,
    giphy: `${EMOJI.giphy} ${i18n.t('messageType.giphy')}`,
    attachment: `${EMOJI.attachment} ${message.payload || i18n.t('messageType.attachment')}`,
    voice: `${EMOJI.voice} ${i18n.t('messageType.voice')}`
  }
  const body = bodyByType[message.typeName] || message.payload
  // eslint-disable-next-line
  const notification = new Notification(title, {
    body,
    icon: '/img/hola-icon.png'
  })
  notification.onclick = event => {
    event.preventDefault()
    window.focus()
    router.push({ name: 'app-chat', query: { id: window.btoa(message.chatId) } }).catch(e => {})
  }
}

Ws.on('message.new', data => {
  Message.insert({ data })
  if (data.type === TYPE_CODE.forward && !User.find(data.payload.senderId)) {
    User.dispatch('getProfiles', [data.payload.senderId])
  }
  const { id } = data
  showNotif(id)
})
Ws.on('message.update', data => {
  Message.insertOrUpdate({ data })
})
Ws.on('message.delete', data => {
  data.forEach(id => Message.delete(id))
})
Ws.on('message.read', data => {
  if (!data.messagesId || !data.messagesId.length) return
  data.messagesId.forEach(id => {
    const message = Message.find(id)
    if (!message) return
    message.$update({
      properties: {
        ...message.properties,
        isRead: true
      }
    })
  })
})

const state = {
  foundMessages: null
}

const getters = {
  getById: _ => id => Message.query().whereId(id).with('chat.participantsUsers').with('sender').first(),

  listByChatId: _ => chatId => {
    const chat = Chat.query().whereId(chatId).with('messages.sender').with('messages.translatedMessage').first()
    const messages = chat ? chat.messages : []
    return messages.sort((a, b) => a.createdTs - b.createdTs) // start to end
    // .sort((a, b) => b.timestamp - a.timestamp) // end to start
  }
}

const mutations = {
  SET_FOUND_MESSAGES (state, foundMessages) {
    state.foundMessages = foundMessages
  }
}

const readMessagesByChatId = {}
let readTimeout = null

const sendReadMsgList = () => {
  for (const chatId in readMessagesByChatId) {
    const messagesId = readMessagesByChatId[chatId]
    if (messagesId.length) {
      Ws.req({
        method: 'message.read',
        params: {
          chatId: Number(chatId),
          messagesId
        }
      })
    }
    delete readMessagesByChatId[chatId]
  }
  readTimeout = null
}

const actions = {
  async fetch ({ dispatch, rootGetters }, { chatId, datetimeTo, limit, pinCode }) {
    if (!chatId) return
    const { data: { messages, deleted } } = await Ws.req({
      method: 'message.get_chat_messages',
      params: {
        chatId,
        // pinCode,
        limit,
        datetimeTo
      }
    })
    Message.insert({ data: messages })
    deleted.forEach(id => Message.delete(id))
    const forwardedSenderIds = messages
      .filter(msg => {
        return msg.type === TYPE_CODE.forward && msg.payload.senderId
      })
      .map(msg => msg.payload.senderId)
    const unfetchedUserIds = uniq(forwardedSenderIds).filter(id => !User.find(id))
    User.dispatch('getProfiles', unfetchedUserIds)
    return messages
  },

  async send ({ rootGetters }, params) {
    if (!params.chatId) return
    const { data } = await Ws.req({
      method: 'message.send',
      params
    })
    Message.insert({ data })
  },
  addPending ({ rootGetters }, params) {
    const id = Date.now()
    Message.insert({
      data: {
        id,
        isPending: true,
        senderId: rootGetters['auth/user'].id,
        ...params
      }
    })
    return id
  },
  async sendWithPending ({ dispatch, rootGetters }, params) {
    if (!params.chatId) return
    const requestId = await dispatch('addPending', params)
    const { data } = await Ws.req({
      id: requestId,
      method: 'message.send',
      params
    })
    Message.delete(requestId)
    Message.insert({ data })
  },
  async edit (_, params) {
    const { data } = await Ws.req({
      method: 'message.edit',
      params
    })
    Message.update({ data })
  },
  async remove (_, { chatId, messageIds, isDeleteForAll }) {
    if (!messageIds || !messageIds.length) return
    await Ws.req({
      method: 'message.remove',
      params: {
        id: messageIds,
        forAll: isDeleteForAll,
        chatId
      }
    })
    messageIds.forEach(id => Message.delete(id))
  },
  async read ({ rootGetters }, { chatId, id }) {
    // const { hideRead } = rootGetters['auth/settings']
    // if (hideRead) return
    const chat = Chat.query().whereId(chatId).with('chatSettings').first()
    if (chat.typeName === 'one-to-one' && !chat.chatSettings.enableRead) return
    readMessagesByChatId[chatId] = readMessagesByChatId[chatId] || []
    const message = Message.find(id)
    if (!message.properties.isRead) {
      readMessagesByChatId[chatId].push(id)
    }
    if (chat.message.id === id) {
      const message = cloneDeep(chat.message)
      message.properties.isRead = true
      chat.$update({ message })
    }
    if (readTimeout) return
    readTimeout = setTimeout(sendReadMsgList, 1000)
  },
  typing ({ rootGetters }, chatId) {
    // const { hideTyping } = rootGetters['auth/settings']
    // if (hideTyping) return
    const chat = Chat.query().whereId(chatId).with('chatSettings').first()
    if (chat.typeName === 'one-to-one' && !chat.chatSettings.enableTyping) return
    Ws.req({
      method: 'message.typing',
      params: {
        chatId
      }
    })
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
