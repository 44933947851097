import apiReq from './request'
import axios from 'axios'

const BASE_URL = `${process.env.VUE_APP_APIGW_URL}/wallet`
const BUY_URL = `${process.env.VUE_APP_APIGW_URL}/payment`
const USER_URL = `${process.env.VUE_APP_APIGW_URL}/user`
const XPAY_URL = process.env.VUE_APP_XPAY_API
export default {
  balance () {
    return apiReq.get(`${BASE_URL}/api/v1/balance`)
  },
  xPayBalance (params) {
    return axios({
      url: `${XPAY_URL}/hola/user/wallets/balance/`,
      method: 'POST',
      data: params
    })
  },
  transactions () {
    return apiReq.get(`${BASE_URL}/api/v1/transactions`)
  },
  withdraw (data) {
    return apiReq.post(`${BASE_URL}/api/v1/withdraw`, data)
  },
  registerWallet (data) {
    return apiReq.post(`${BASE_URL}/api/v1/my-xpay/register`, data)
  },
  fetchBalanceInfo (data) {
    return apiReq.get(`${BASE_URL}/api/v1/wallet_meta`, data)
  },
  createWalletCard (data) {
    return apiReq.put(`${BASE_URL}/api/v1/wallet/card`, data)
  },
  addFunds (data) {
    return apiReq.post(`${BUY_URL}/user/xpr/purchase`, data)
  },
  getUserId (data) {
    return apiReq.get(`${USER_URL}/info/get-user-id?${data.type}=${data.value}`, data)
  },
  getCommission ({ amount, transactionType }) {
    return apiReq.get(`${BASE_URL}/get-commission?amount=${amount}&transaction_type=${transactionType}`)
  },
  transferFunds (data) {
    return apiReq.post(`${BASE_URL}/api/v1/transfer`, data)
  },
  convertFunds (data) {
    return apiReq.get(`${XPAY_URL}/hola/user/xpr-rate/?amount=${data}`, data)
  },
  createWalletToken (data) {
    return apiReq.post(`${BUY_URL}/user/stripe/tokens`, data)
  },
  getWalletTokens (data) {
    return apiReq.get(`${BUY_URL}/user/stripe/tokens`, data)
  },
  deleteWalletToken (payload) {
    return apiReq.delete(`${BUY_URL}/user/stripe/tokens?id=${payload.id}`)
  }
}
