import { Model } from '@vuex-orm/core'
import Blog from './Blog'
import PostCategory from './PostCategory'

export default class Post extends Model {
  static entity = 'posts'
  static primaryKey = 'uuid'

  static fields () {
    return {
      uuid: this.string(''),
      title: this.string(''),
      subtitle: this.string(''),
      text: this.string(''),
      isPublished: this.boolean(false),
      categoryId: this.number(null),
      category: this.belongsTo(PostCategory, 'categoryId'), // this.hasOne(PostCategory, 'id'),
      blogUuid: this.string(''),
      blog: this.belongsTo(Blog, 'blogUuid'),
      views: this.number(0),
      images: this.attr([]),
      videos: this.attr([]),
      createdAt: this.string(''),
      updatedAt: this.string(''),
      author: this.string(''),
      isMarked: this.boolean(false),
      language: this.string(''),
      relationId: this.string('')
    }
  }
}
