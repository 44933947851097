<template>
  <SecondaryModal
    :name="$options.name"
    :title="$t('popupConfirmTitle')"
    @before-open="reset"
  >
    <div v-html="content"></div>

    <template v-slot:footer>
      <BaseButton oval secondary @click="$modal.hide($options.name)">{{ $t('buttonCancel') }}</BaseButton>
      <BaseButton oval @click="submit">{{ $t('buttonConfirm') }}</BaseButton>
    </template>
  </SecondaryModal>
</template>

<script>
import SecondaryModal from './SecondaryModal'

export default {
  name: 'confirm',
  components: {
    SecondaryModal
  },
  data () {
    return {
      content: this.$t('popupConfirmText'),
      onSubmit: () => {}
    }
  },
  methods: {
    reset ({ params: { content, onSubmit = () => {} } }) {
      this.onSubmit = onSubmit
      this.content = content || this.$t('popupConfirmText')
    },
    submit () {
      this.onSubmit()
      this.$modal.hide(this.$options.name)
    }
  }
}
</script>

<style module lang="scss">

</style>
