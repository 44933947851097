<template>
  <div>
    <AuthContainer>
      <h1 class="title">{{ $t('supportTitle') }}</h1>
      <p :class="$style.txt">{{ $t('supportDescription') }}</p>
      <form @submit.prevent="onSubmit" :class="$style.form">
        <BaseInput
          v-model="email"
          :label="$t('formEmail')"
          :invalid="$v.email.$error"
          :error="!$v.email.email ? $t('errorEmailInvalid') : $t('errorEmptyField')"
        />
        <BaseInput
          v-model="text"
          :label="$t('formMessage')"
          :invalid="$v.text.$error"
          :error="$t('errorEmptyField')"
          multiline
        />
        <FileInput
          v-model="image"
          withName
          :label="$t('formPhoto')"
          accept="image/*"
        />

        <p v-if="error" class="form--error">
          {{ error }}
        </p>

        <BaseButton :loading="submitting" :class="$style.button">{{ $t('buttonSend') }}</BaseButton>
      </form>
      <div :class="$style.linkBlock">
        <a href="https://privateapp.io/en/rewards" target="_blank">{{ $t('learnMore') }}</a> {{ $t('supportAboutRewardPrograms') }}
      </div>
    </AuthContainer>
  </div>
</template>

<script>
import FileInput from '@/components/FileInput'
import { mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import api from '@/api'
import AuthContainer from '@/components/AuthContainer'

export default {
  name: 'support',
  components: {
    FileInput,
    AuthContainer
  },
  metaInfo () {
    return {
      title: this.$t('supportTitle')
    }
  },
  data () {
    return {
      email: '',
      text: '',
      image: null,
      submitting: false,
      error: ''
    }
  },
  created () {
    this.resetForm()
  },
  validations: {
    email: { required, email },
    text: { required }
  },
  computed: {
    ...mapState('auth', [
      'user'
    ])
  },
  methods: {
    resetForm () {
      this.$v.$reset()
      this.email = this.user.email
      this.text = ''
      this.image = null
    },
    async onSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid || this.submitting) return
      const { email, text, image } = this
      this.error = ''
      this.submitting = true
      try {
        let form
        if (image) {
          form = new FormData()
          form.append('attached_image', image)
          form.set('email', email)
          form.set('text', text)
        } else {
          form = { email, text }
        }
        await api.social.support(form)
        this.$modal.show('support-success')
        this.resetForm()
      } catch (error) {
        this.error = (error.response && error.response.data)
          ? Object.values(error.response.data).join(' ')
          : this.$t('error')
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss" module>
.form {
  width: 100%;
}
.button:global(.form--button) {
  margin-top: 22px;
}
.txt {
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 16px;
}
.linkBlock {
  @extend .txt;
  margin-top: 16px;
}
</style>
