<template>
  <BaseModal
    name="support-success"
    :icon="['far', 'envelope-open']"
    :title="$t('supportPopupTitle')"
  >
    {{ $t('supportPopupText') }}
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'

export default {
  name: 'support-success-modal',
  components: {
    BaseModal
  }
}
</script>
