import api from '@/api'
import Vue from 'vue'

const state = {
  byId: {},
  allIds: []
}

const getters = {
  find: state => id => state.byId[id],
  list: state => state.allIds
    .map(id => state.byId[id])
}

const mutations = {
  ADD (state, data) {
    Vue.set(state.byId, data.id, data)
    if (state.allIds.includes(data.id)) return
    state.allIds.push(data.id)
  }
}

const actions = {
  async getById ({ commit }, id) {
    const { data } = await api.giphy.gifByID(id)
    if (data) {
      commit('ADD', data)
    }
  },
  async getTrending (_, { type, params }) {
    // const { data } = await api.giphy.trending(type, params)
    // this.trending = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
